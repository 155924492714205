import React from 'react';
import Button from '../../common/Buttons/Button';
import PropTypes from 'prop-types';

const ModalAgreement = ({ openModal, setOpenModal, setAllowPay, type, loadBtn }) => {

  const closeModal = () => {
    if(type === 'accounts') {
      setOpenModal({
        'open': false,
        'id': null,
        'url': null,
        'name': null,
      });
    }

    if(type === 'pay') {
      setOpenModal({
        'open': false,
        'url': null,
        'name': null,
        'method': null,
        'appointment': null
      });
    }

    document.body.classList.remove('open');
  };

  const setModalData = (data) => {
    setAllowPay(true);
    setOpenModal(data);
  };
  
  return (
    <div className={`modal-info ${openModal.open ? 'open' : ''}`}>
      <div className="overlay" onClick={closeModal}></div>
      <div className="modal-info__container">
        <div className="modal-info__content modal-info__content--gap">
          <div>
            <p className="modal-info__text">
              Ви ознайомилися з умовами Договору публічної-оферти
              <button className='link link--violet link--lg' onClick={() => window.open(openModal.url, '_target')}>{openModal.name}</button>
              і погоджуєтеся з його умовами? 
            </p>
            <p className="modal-info__text">
              Оплата Вами рахунку означає погодження з умовами Договору.
            </p>
          </div>
          <div className='modal-info-btns modal-info-btns--row'>
            <Button 
              buttonBg='green' 
              onClick={() => setModalData(openModal)} 
              disabled={loadBtn ? true : false} 
              loading={loadBtn ? 'loading' : ''} 
            >
              Так
            </Button>
            <Button buttonBg='white' onClick={closeModal}>Ні</Button>
          </div>
        </div>
      </div>
    </div>
  );
};

ModalAgreement.propTypes = {
    openModal: PropTypes.object.isRequired,
    setOpenModal: PropTypes.func.isRequired,
    setAllowPay: PropTypes.func.isRequired,
    type: PropTypes.string.isRequired,
    loadBtn: PropTypes.bool,
};

export default ModalAgreement;