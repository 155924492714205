import React from 'react';
import PropTypes from 'prop-types';
import './error.scss';

const ErrorMessage = ({ errorText }) => {
  return (
    <span className="error-message">{errorText}</span>
  );
};

ErrorMessage.propTypes = {
    errorText: PropTypes.any,
};

export default ErrorMessage;