import auth from './auth';
import finances from './finances';
import profile from './profile';
import schedule from './schedule';
import register from './register';
import code from './code';
import reset from './reset';
import general from './general';
import reviews from './reviews';
import programs from './programs';

export default {
  auth,
  schedule,
  finances,
  profile,
  register,
  code,
  reset,
  general,
  reviews,
  programs
};