import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';

import actions from '../../../store/actions';
import { getLoginThunk } from '../../../store/thunks/auth';
import { authSelectors } from '../../../store/selectors/auth';

import LoadingBtn from '../../LoadingBtn/LoadingBtn';
import FieldPhone from '../../../common/Fields/FieldPhone';
import ErrorField from '../../../common/Errors/ErrorField';
import ErrorMessage from '../../../common/Errors/ErrorMessage';
import FieldPassword from '../../../common/Fields/FieldPassword';

import '../auth.scss';

const defaultFormFields = {
  phone: '',
  password: '',
};

const LoginForm = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const auth = useSelector(authSelectors);
  const token = auth.token;
  const user = auth.user;
  const errors = auth.errors;
  const error = auth.error;
  const status = auth.status;  
  const load = auth.load;

  const [formFields, setFormFields] = useState(defaultFormFields);
  const [phone, setPhone] = useState();

  const { password } = formFields;
  const from = location.state?.from?.pathname || '/';

  useEffect(() => {
    if (token && status === 1) {
      logIn(user);
      resetFormFields();
      navigate(from, { replace: true }); 
      resetErrors();
    } else {
      navigate('/login', { replace: true });
      resetErrors();
    }
  }, [token, user]);

  const resetFormFields = () => {
    setFormFields(defaultFormFields);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormFields({ ...formFields, [name]: value });
  };

  const logIn = data => {
    const now = new Date();
    const item = {
      name: 'user_token',
      value: data.token,
      expiry: now.getTime() + 1000 * 60 * 60 * 2, // 2 hrs
    };
    localStorage.setItem('user_token', JSON.stringify(item));
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const dataAuth = {
      phone: `+${phone}`,
      password: password,
    };
    resetErrors();
    dispatch(getLoginThunk(dataAuth));
  };

  const resetErrors = () => {
    dispatch(actions.auth.setLoginError(''));
    dispatch(actions.auth.setLoginErrors({}));
  };

  return (
    <form onSubmit={handleSubmit} className="auth__form form">
      <>
        <FieldPhone
          name="phone" 
          type="tel"
          valuePhone={phone} 
          onChange={setPhone} 
          labelText="Телефон"
          labelMessage=""
          formClass="required"
          required
        /> 
        {errors?.phone && <ErrorField errorText={errors.phone} />}
      </>

      <>
        <FieldPassword
          name="password"
          value={password} 
          onChange={handleChange} 
          labelText="Пароль"
          formClass="form__field required"
          required
        />
        {errors?.password && <ErrorField errorText={errors.password} />}
      </>

      {error && <ErrorMessage errorText={error} />}

      <div className="auth__btns">
        <LoadingBtn btnText='Увійти' load={load}/>
        <div className="auth__text">
          Не маєте профіля?
          <NavLink to="/registration" className="link">Зареєструватись</NavLink>
        </div>
      </div>
    </form>
  );
};

export default LoginForm;