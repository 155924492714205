import React, { useEffect } from 'react';
import ResetConfirm from '../../components/ResetPassword/ResetConfirm/ResetConfirm';
import HeaderAuth from '../../components/HeaderAuth/HeaderAuth';

const ResetConfirmPage = () =>  {
	useEffect(() => {
		document.body.removeAttribute('class');
		document.title = 'Відновлення пароля | NewBrain';
	}, []);
	return (
		<div>
			<HeaderAuth />
			<section className='container container--login'>
				<ResetConfirm />
			</section>
		</div>
	);
};

export default ResetConfirmPage;