import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import Info from '../../../common/Info/Info';
import UserInfoCard from '../../UserInfoCard/UserInfoCard';

import { cards } from '../constants';

import './card-profile.style.scss';

const ProfileCards = ({ user, confirmInfo }) => {
  const navigate = useNavigate();

  const [birthday, setBirthday] = useState('');

  useEffect(() => {
    if(user?.birth_date) {
      setBirthday(new Date(user.birth_date).toLocaleDateString());
    }
  }, [user]);

  const goEdit = (link) => {
    navigate(link);
  };

  const confirmed = (field) => {
    const confirm = confirmInfo.find(confirm => confirm.field === field);
    return confirm ? confirm : {};
  };

  const items = cards(user, birthday);

  return (
    <>
      {user.first_name ? <></> : <Info 
        data={{text: 'Дані знаходяться на модерації. У вас зараз немає можливості їх змінювати!'}} 
        condition='moderation' 
      />}
      {items.map(item => <UserInfoCard 
        key={item.title}
        title={item.title}
        userData={item.userData}
        name={item.userName} 
        confirm={confirmed(item.confirm)}
        goEdit={() => goEdit(item.link)}
      />)}
    </>
  );
};

ProfileCards.propTypes = {
  user: PropTypes.object.isRequired,
  confirmInfo: PropTypes.array.isRequired,
};

export default ProfileCards;