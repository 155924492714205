import React from 'react';
import TitleAuth from '../../common/Titles/TitleAuth';
import LoginForm from './LoginForm/LoginForm';
import './auth.scss';

const Auth = () => {
  return (
    <div className="auth">
      <TitleAuth titleText="Авторизація" />
      <LoginForm />
    </div>
  );
};

export default Auth;