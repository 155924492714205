import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LayoutContext } from '../../contexts/layout.context';

import { authSelectors } from '../../store/selectors/auth';
import { getDay, getScheduleType } from '../../store/thunks/schedule';
import { scheduleSelectors } from '../../store/selectors/schedule';
import { getUserInfoThunk } from '../../store/thunks/profile';

import { getToken } from '../../utils/token';
import { getDate } from '../../utils/date';

import EventModal from '../../components/schedule/event-modal.component';
import ScheduleMonth from '../../components/schedule/schedule-month.component';
import Alert from '../../common/Alert/Alert';

const Schedule = () => {
  const dispatch = useDispatch();
  const dateNow = getDate();
  
  const type = { value: 'month', label: 'По місяцях' };

  const { setLayout } = useContext(LayoutContext);

  const [dates, setDates] = useState([]);
  const [modalOpen, setModalOpen] = useState('');
  const [events, setEvents] = useState([]);
  const [clickedDate, setClickedDate] = useState('');
  const [showMonth, setShowMonth] = useState(dateNow);
  const [message, setMessage] = useState(false);

  const auth = useSelector(authSelectors);
  const token = auth.token;

  getToken(token);

  const schedule = useSelector(scheduleSelectors);
  const scheduleType = schedule.scheduleType;
  const scheduleDay = schedule.day;

  useEffect(() => {
    document.title = 'Розклад | NewBrain';
    setLayout({ page: 'Розклад' });
  }, [setLayout]);

  useEffect(() => {
    if (token) {
      setDates([]); 
      fetchDates();
    }
  }, [token, showMonth]);

  useEffect(() => {
    if (scheduleType) {
      setDates(scheduleType);
    }
  }, [scheduleType]);

  useEffect(() => {
    if(token) {
      dispatch(getUserInfoThunk({token}));
    }
  }, [token]);

  useEffect(() => {
    if (scheduleDay.length > 0) {
      setMessage(false);
      setEvents(scheduleDay);
      setModalOpen('open');
      setClickedDate(scheduleDay[0].date);
    }
  }, [scheduleDay]);

  const fetchDates = () => {
    const url = showMonth ? `?show_month=${showMonth}` : '';
    if (token) {
      dispatch(getScheduleType({ type: type.value, url, token }));
    }
  };
 
  const handleClickMonth = (value, locale) => {
    const clickedDate = value.toLocaleDateString(locale, { year: 'numeric', month: '2-digit', day: '2-digit' });
    fetch(clickedDate);
    setMessage(true);
  };

  const fetch = (date) => {
    if (token) {
      dispatch(getDay({ date, token }));
    }
  };

  return (
    <div className='schedule'>
      {message && <Alert text='По цій даті подій не заплановано!' setMessage={setMessage} />}
        <EventModal
          events={events}
          clickedDate={clickedDate}
          setModalOpen={setModalOpen}
          toggleClass={modalOpen}
          setEvents={setEvents}
        />

        {type.value === 'month' && <div className="schedule__calendar schedule-month">
          <ScheduleMonth
            dates={dates}
            setShowMonth={setShowMonth}
            handleClick={handleClickMonth} 
          />
        </div>}
    </div>
  );
};

export default Schedule;