import types from '../types/index';

const initialState = {
  token: '',
  isAuth: false,
  status: null,
  load: false,
  errors: {},
  error: '',
  errorCrm: false,
  user: {}
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case types.auth.LOGIN_REQUEST:
      return {
        ...state, token: payload, isAuth: true
      };
    case types.auth.LOGOUT_REQUEST:
      return {
        ...state, token: '', isAuth: false, user: {}
      };
    case types.auth.GET_USER_REQUEST:
      return {
        ...state, token: payload?.token, isAuth: true, user: payload
      };
    case types.auth.START_FETCHING_LOGIN:
      return {
        ...state, load: true,
      };
    case types.auth.STOP_FETCHING_LOGIN:
      return {
        ...state, load: false
      };
    case types.auth.SET_ERRORS_LOGIN:
      return {
        ...state, errors: payload, isAuth: false
      };
    case types.auth.SET_ERROR_LOGIN:
      return {
        ...state, error: payload, isAuth: false
      };
    case types.auth.SET_ERROR_LOGIN_CRM:
      return {
        ...state, errorCrm: payload, isAuth: false
      };
    case types.auth.GET_USER_STATUS: 
      return {
        ...state, status: payload
      };
    default: {
      return state;
    }
  }
};

export default reducer;