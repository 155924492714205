import React from 'react';
import PropTypes from 'prop-types';

import Button from '../../../common/Buttons/Button';
import CourseName from '../../CourseName/CourseName';
import CopyPayment from '../../CopyPayment/CopyPayment';

import { colors, statuses } from '../data';

import sprite from '../../../img/sprites.svg';

const FinanceAccountsTableRow = ({ count, setOpenModal, getPdf }) => {
  const actual = count.show_button_pay === true && !count.total_price;
  const payed = count.show_button_pay == false && count.total_price;
  const notActual = count.show_button_pay === false && !count.total_price;

  const setLink = (document_url) => {
    window.open(document_url, '_blank');
  };

  const setModalData = (id, url, name) => {
    setOpenModal({'open': true, id, url, name});
    document.body.classList.add('open');
  };

  return (
    <tr className='table-body__row'>
      <td className='table-body__cell table-body__cell--center'>{count.created_at}</td>
      <td className='table-body__cell table-body__cell--center'>
        <div className='table-box'>
          <button className="table__link link" onClick={() => setLink(count.document_url)}>{count.document_name}</button>
          {count.show_document_url 
            ? <button className='btn-download' onClick={() => getPdf(count.id)}>
                <svg className="icon"><use href={`${sprite}#download`}></use></svg>
              </button>
            : ''}
        </div>
      </td>
      <td className='table-body__cell table-body__cell--center'>
        {count.checkbox_url 
        ? <button className="check-icon" onClick={() => window.open(count.checkbox_url, '_blank')}>
            <svg className="icon"><use href={`${sprite}#check_list`}></use></svg>
          </button>
        : '-'}
      </td>
      <td className='table-body__cell table-body__cell--center'>
        <CourseName img={count.image} name={count.nomenclature_name} isCenter={true} />
      </td>
      <td className='table-body__cell table-body__cell--center'>{count.qty ? count.qty : '-'}</td>
      <td className='table-body__cell table-body__cell--center'>{count.price ? count.price : '-'}</td>
      <td className='table-body__cell table-body__cell--center'>{count.total_price ? count.total_price : '-'}</td>
      <td className={`table-body__cell table-body__cell--center ${colors(actual, payed, notActual)}`}>{statuses(actual, payed, notActual)}</td>
      <td className='table-body__cell table-body__cell--center'>
        {count.show_button_pay ?
          <CopyPayment
            validTime={count.valid_seconds} 
            url={count.payment_url} 
            id={count.id} 
          />
          : '-'
        }
      </td>
      <td className='table-body__cell table-body__cell--center'>
        {count.show_button_pay 
        ? 
        <Button 
            buttonBg="green" 
            loading="" 
            onClick={() => setModalData(count.id, count.document_url, count.document_name)}
          >
            Оплатити
          </Button> 
        : '-'}
      </td>
    </tr> 
  );
};

FinanceAccountsTableRow.propTypes = {
  count: PropTypes.object.isRequired,
  setOpenModal: PropTypes.func.isRequired,
  getPdf: PropTypes.func.isRequired
};

export default FinanceAccountsTableRow;