import React from 'react';
import PropTypes from 'prop-types';

import ProgramsTableRow from './ProgramsTableRow/ProgramsTableRow';

const ProgramsTable = ({ courses }) => {
  return (
    <table className='table-block'>
    <thead className='table-head'>
      <tr className='table-head__row'>
        <td className='table-head__cell'>Назва курсу</td>
        <td className='table-head__cell table-head__cell--center'>Пройдено</td>
        <td className='table-head__cell table-head__cell--center'>Всього</td>
        <td className='table-head__cell table-head__cell--center'>Прогрес</td>
      </tr>
    </thead>
    <tbody className='table-body'>
      {courses.map(course => <ProgramsTableRow key={course.program_id} course={course} />)}
    </tbody>
  </table>
  );
};

ProgramsTable.propTypes = {
  courses: PropTypes.array.isRequired,
};

export default ProgramsTable;