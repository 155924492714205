import { images } from '../../utils/importImg';

export const image = {
  'Ko': images['korean.png'],
  'Ja': images['japan.png'],
  'Ch': images['china.png'],
  'En': images['english.png'],
  'Ge': images['germany.png'],
  'Sp': images['spain.png'],
  'Fr': images['france.png'],
  'Pl': images['poland.png'],
  'Ar': '',
};