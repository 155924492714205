import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { useDispatch, useSelector } from 'react-redux';
import actions from '../../../store/actions';
import { getPayContractThunk } from '../../../store/thunks/finances';
import { authSelectors } from '../../../store/selectors/auth';
import { financesSelectors } from '../../../store/selectors/finances';

import Head from '../../Head/Head';
import FinancesModalCard from './FinancesModalCard/FinancesModalCard';
import FinancesModalTable from './FinancesModalTable/FinancesModalTable';
import Plug from '../../plug/plug.component';

const FinancesModal = ({
  setModalOpen,
  documents,
  toggleClass,
  course
}) => {
  const dispatch = useDispatch();

  const auth = useSelector(authSelectors);
  const token = auth.token;

  const finances = useSelector(financesSelectors);
  const pdf = finances.pdf;

  useEffect(() => {
    if(pdf) {
      window.open(pdf, '_target');
      dispatch(actions.finances.getPayPdf(''));
    }
  }, [pdf]);

  const goBack = () => {
    dispatch(actions.finances.getFinancesUsersDocuments([]));
    setModalOpen('');
  };

  const getPdf = (invoiceId) => {
    dispatch(getPayContractThunk({invoice_id: invoiceId, token}));
  };

  return (
    <div className={`modal ${toggleClass}`}>
      <div className="container">
        <Head textHead={course} goBack={goBack} />
      </div>

      <div className="modal__content container">
        <div className="modal__cards cards">
          {documents.length > 0 ? documents.map((document, idx) => <FinancesModalCard key={idx} document={document} getPdf={getPdf} />) 
          : <Plug plug="Даних немає" icon='file-closed' />}
        </div>

        <div className="modal__table table">
          <div className="modal__table-wrapper">
            <FinancesModalTable documents={documents} getPdf={getPdf} />
          </div>
        </div>
      </div>
    </div>
  );
};

FinancesModal.propTypes = {
  toggleClass: PropTypes.string.isRequired,
  documents: PropTypes.array.isRequired,
  setModalOpen: PropTypes.func.isRequired,
  course: PropTypes.string.isRequired,
};

export default FinancesModal;