import React from 'react';
import PropTypes from 'prop-types';
import { getDocumentData, setStatusBg } from '../data';
import Button from '../../../../common/Buttons/Button';

import sprite from '../../../../img/sprites.svg';

const FinancesModalTableRow = ({ document, getPdf }) => {
  const { common, signs, sumClasses } = getDocumentData(document);

  const setLink = (number, url, icon) => {
    if (number) {
      return <Button
        className={`${icon ? 'check-icon' : 'table__link link'}`}
        onClick={() => window.open(url, '_target')}
      >
        {icon
          ? <svg className="icon"><use href={`${sprite}#check_list`}></use></svg>
          : number}
      </Button>;
    } else {
      return '-';
    }
  };

  return (
    <tr className='table-body__row'>
      <td className='table-body__cell'>
        <div className={`table-status ${document.attendance_status ? setStatusBg(document.attendance_status) : ''}`}>
          {document.date}
        </div>
      </td>
      <td className='table-body__cell table-body__cell--center'>
        <div className='table-box'>
          {document.type === 'debt-correction' ? (
            'Корегування заборгованості'
          ) : (
            <>
              {setLink(document.document_number, document.document_url, false)}
              {document.show_document_url && (
                <button className='btn-download' onClick={() => getPdf(document.invoice_id)}>
                  <svg className="icon"><use href={`${sprite}#download`}></use></svg>
                </button>
              )}
            </>
          )}
        </div>
      </td>
      <td className='table-body__cell table-body__cell--center'>
        {setLink(document.check_id, document.checkbox_url, true)}
      </td>
      <td className='table-body__cell table-body__cell--center'>{(document.type === 'debt-correction' && document.income_expense === 'expense') ? `-${document.qty}` : (common ? `+${document.qty}` : '-1')}</td>
      <td className={`table-body__cell table-body__cell--bold table-body__cell--center ${sumClasses}`}>{signs}{document.total_price}</td>
    </tr>
  );
};

FinancesModalTableRow.propTypes = {
  document: PropTypes.object.isRequired,
  getPdf: PropTypes.func.isRequired,
};

export default FinancesModalTableRow;