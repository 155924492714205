import React, { useContext, useEffect } from 'react';
import { LayoutContext } from '../../contexts/layout.context';
import { useDispatch, useSelector } from 'react-redux';

// import { profileSelectors } from '../../store/selectors/profile';
import { authSelectors } from '../../store/selectors/auth';
import { getProfileThunk } from '../../store/thunks/profile';

import Head from '../../components/Head/Head';
import CardSettings from '../../components/Settings/CardSettings/CardSettings';

import { items } from './constants';

const Settings = () => {
  const { setLayout } = useContext(LayoutContext);
  const dispatch = useDispatch();

  const auth = useSelector(authSelectors);
  const token = auth.token;

  useEffect(() => {
    document.title = 'Налаштування | NewBrain';
    setLayout({ page: 'Налаштування' });
  }, [setLayout]);

  useEffect(() => {
    if(token) {
      dispatch(getProfileThunk({token}));
    }
  }, [token]);
  
  return (
    <div className='settings container'>
      <Head textHead='Налаштування' goBack={''} />

      <div className="settings__content">
        <div className="settings-block">
          <h3 className="settings-block__title">Налаштування нагадуваннь</h3>
          
          <div className="cards">
            {items.map(item => <CardSettings 
              key={item.el} 
              title={item.title}
              el={item.el}
            />)}
          </div>
        </div>

      </div>
      
    </div>
  );
};

export default Settings;