import api, { setToken } from '../index';

export function login(data) {
	return api.post('/auth/login', data);
}

export function loginCrm(data) {
	return api.post('/auth/login/crm', data);
}

export function logout(data) {
	return api.post('/auth/logout', setToken(data));
}