import api, { setToken } from '../index';

export function user(data) {
  return api.get('/user/info', setToken(data.token));
}

export function profile(data) {
  return api.get('/user/profile', setToken(data.token));
}

export function edit(data) {
  return api.post('/user/update', {value: data.value, value_confirmation: data.value_confirmation, field: data.field}, setToken(data.token));
}

export function phoneAgain(data) {
  return api.post('/user/update/phone/again', {phone: data.phone}, setToken(data.token));
}

export function code(data) {
  return api.post('/user/update/code', {phone: data.phone, code: data.code}, setToken(data.token));
}

export function checkChanges(data) {
  return api.post('/notification/processed-records', setToken(data.token));
}

export function changesVerified(data) {
  return api.post('/notification/view-records', setToken(data.token));
}

export function telegram(data) {
  return api.post('/notification/register-telegram', setToken(data.token));
}

export function birthDate(data) {
  return api.post('/notification/write-birth-date', setToken(data.token));
}