import React from 'react';
import TitleAuth from '../../../common/Titles/TitleAuth';
import RegisterCommunicationForm from './RegisterCommunicationForm';
import '../../Login/auth.scss';

const RegisterCommunication = () => {
  return (
    <div className="auth">
      <TitleAuth titleText="Альтернативний звʼязок"/>
      <RegisterCommunicationForm />
    </div>
  );
};

export default RegisterCommunication;