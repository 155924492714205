import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import actions from '../../../store/actions';
import { resetSelectors } from '../../../store/selectors/reset';
import { getNewPasswordThunk } from '../../../store/thunks/reset';

import { resetErrors } from '../data';

import ErrorField from '../../../common/Errors/ErrorField';
import ErrorMessage from '../../../common/Errors/ErrorMessage';
import FieldPassword from '../../../common/Fields/FieldPassword';

import LoadingBtn from '../../LoadingBtn/LoadingBtn';

const NewPasswordForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [phoneData] = useState(JSON.parse(localStorage.getItem('phone')));

  const reset = useSelector(resetSelectors);
  const passwordSuccess = reset.passwordSuccess;
  const errors = reset.errors;
  const error = reset.error;
  const load = reset.load;
  const phone = reset.phone;

  useEffect(() => {
    if(passwordSuccess) {
      navigate('/', { replace: true });
    }

    () => {
      resetErrors(dispatch, actions.reset);
      dispatch(actions.reset.setNewPasswordChangesuccess(false));
    };
  }, [passwordSuccess]);

  useEffect(() => {
    if(!phone && phoneData) {
      dispatch(actions.reset.setResetRequest(phoneData));
    }
  }, [phoneData]);

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = {
      password,
      password_confirmation: passwordConfirmation,
      phone,
    };
    resetErrors(dispatch, actions.reset);
    dispatch(getNewPasswordThunk(data));
  };

  return (
    <form onSubmit={handleSubmit} className="auth__form form">

      <>
        <FieldPassword
          name="password"
          value={password} 
          onChange={(e) => setPassword(e.target.value.trim())} 
          labelText="Новий пароль"
          formClass="required"
          required
        />
        {errors?.password && <ErrorField errorText={errors.password} />}
      </>

      <>
        <FieldPassword
          name="password_confirmation"
          value={passwordConfirmation} 
          onChange={(e) => setPasswordConfirmation(e.target.value.trim())} 
          labelText="Підтвердіть новий пароль"
          formClass="form__field required"
          required
        />
        {errors?.password_confirmation && <ErrorField errorText={errors.password_confirmation} />}
      </>

      {error && <ErrorMessage errorText={error} />}

      <div className="auth__btns">
        <LoadingBtn load={load} btnText='Змінити пароль' />
      </div>
    </form>
  );
};

export default NewPasswordForm;
