import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

import { getResetConfirmAgainThunk } from '../../store/thunks/reset';
import { getRegisterConfirmAgainThunk } from '../../store/thunks/register';
import { getCodeAgainThunk } from '../../store/thunks/profile';

import { timer } from '../../utils/timer';

import './field.scss';
import './code.scss';

const FieldTimer = ({name, value, labelText, formClass, setMessage, ...props}) => {
  const formFieldClass = formClass ? formClass : '';
  const classes = [formFieldClass].filter(Boolean).join(' ');

  const dispatch =  useDispatch();
  const location = useLocation();

  const [phone] = useState(JSON.parse(localStorage.getItem('phone')));
  const [token] = useState(JSON.parse(localStorage.getItem('token')));

  const sendCode = () => {
    timerFunc();
    setMessage(true);

    if(location.pathname === '/profile/edit/phone/confirm') {
      dispatch(getCodeAgainThunk({phone, token}));
    }

    if(location.pathname === '/registration/code') {
      dispatch(getRegisterConfirmAgainThunk({phone}));
    }

    if(location.pathname === '/reset/code') {
      dispatch(getResetConfirmAgainThunk({phone}));
    }
  };

  useEffect(() => {
    timerFunc();
  }, []);

  const timerFunc = () => {
    const btn = document.querySelector('[data-btn="code"]');
    const timerBlock = document.querySelector('[data-code="timer"]');
    const btnAgain = document.querySelector('[data-code="send"]');
    timer(btn, btnAgain, timerBlock);
  };

  return (
    <div className={`field ${classes}`}>
      <label htmlFor={name || ''} className="field__title">{labelText}</label>

      <div className="code">
        <input 
          id={name || ''} 
          type="number" 
          className="code__input" 
          name={name || ''}
          value={value || ''}
          {...props} 
        />

          <div className="code__info" data-btn="code">
            <button className="code__text" data-code="send" type="button" onClick={sendCode}>Відправити ще раз</button>
            <div className="code__text" data-code="timer"></div>
          </div>
      </div>
    </div>
  );
};

FieldTimer.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  labelText: PropTypes.string.isRequired,
  formClass: PropTypes.string.isRequired,
  setMessage: PropTypes.func,
};

export default FieldTimer;