import React from 'react';
import PropTypes from 'prop-types';
import LoadingBtn from '../LoadingBtn/LoadingBtn';
import Button from '../../common/Buttons/Button';

const SendCodeBtn = ({ load, value }) => {
	if(value) {
		return <LoadingBtn load={load} btnText="Підтвердити код" />;
	} else {
		return <Button type="submit" buttonBg="violet" loading="" disabled>Продовжити</Button>;
	}
};

SendCodeBtn.propTypes = {
	load: PropTypes.bool.isRequired,
	value: PropTypes.string.isRequired
};

export default SendCodeBtn;