import React from 'react';
import PropTypes from 'prop-types';
import FinancesTableRow from './FinancesTableRow';

const FinancesTable = ({ courses, getAccounts, getDocuments, type }) => {
  return (
    <table className='table-block'>
      <thead className='table-head'>
        <tr className='table-head__row'>
          <td className='table-head__cell'>Назва курсу</td>
          {type !== 'trial_courses' && <>
          <td className='table-head__cell table-head__cell--center'>Залишок занять</td>
          <td className='table-head__cell table-head__cell--center'>Залишок на балансі</td>
          </>}
					<td className='table-head__cell table-head__cell--center'>Дії</td>
          {type !== 'trial_courses' && <td className='table-head__cell table-head__cell--center'>Звіт</td>}
        </tr>
      </thead>
      <tbody className='table-body'>
        {courses.map(course => <FinancesTableRow 
          key={course.product_id} 
          course={course} 
          getAccounts={getAccounts}
          getDocuments={getDocuments}
          type={type}
        />)}
      </tbody>
    </table>
  );
};

FinancesTable.propTypes = {
	courses: PropTypes.array.isRequired,
  getAccounts: PropTypes.func.isRequired,
  getDocuments: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
};

export default FinancesTable;