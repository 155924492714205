export const fields = {
  first_name: 'Ім\'я',
  last_name: 'Прізвище',
  mid_name: 'По батькові',
  phone: 'Номер телефону',
  birth_date: 'Дату народження',
  email: 'Електронна пошта',
  instagram: 'Instagram',
};

export const fieldsRejected = {
  first_name: 'імені',
  last_name: 'прізвища',
  mid_name: 'по батькові',
  phone: 'номера телефону',
  birth_date: 'дати народження',
  email: 'електронної пошти',
  instagram: 'Instagram',
};

export const cards = (user, birthday) => {
  const items = [
    {
      'title':  'Ім\'я',
      'userData': user.first_name || '-',
      'userName': user.first_name,
      'confirm': 'first_name',
      'link': '/profile/edit/name/first',
    },
    {
      'title': 'Прізвище',
      'userData': user.last_name || '-',
      'userName': user.first_name,
      'confirm': 'last_name',
      'link': '/profile/edit/name/last',
    },
    {
      'title': 'По батькові',
      'userData': user.birthday || '-',
      'userName': user.first_name,
      'confirm': 'mid_name',
      'link': '/profile/edit/name/middle',
    },
    {
      'title': 'Номер телефону',
      'userData': user.phone || '-',
      'userName': user.first_name,
      'confirm': 'phone',
      'link': '/profile/edit/phone',
    },
    {
      'title': 'Дата народження',
      'userData': birthday || '-',
      'userName': user.first_name,
      'confirm': 'birth_date',
      'link': '/profile/edit/birthday',
    },
    {
      'title': 'Електронна пошта',
      'userData': user.email || '-',
      'userName': user.first_name,
      'confirm': 'email',
      'link': '/profile/edit/email',
    },
    {
      'title': 'Нікнейм Instagram',
      'userData': user.nickname || '-',
      'userName': user.first_name,
      'confirm': 'nickname',
      'link': '/profile/edit/instagram',
    },
    {
      'title': 'Пароль',
      'userData': '',
      'userName': user.first_name,
      'confirm': 'password',
      'link': '/profile/edit/password',
    },
  ];

  return items;
};