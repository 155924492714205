import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

import sprite from '../../../../img/sprites.svg';

const HeaderMobileItem = ({ item, ...props }) => {
  return (
    <li {...props}>
      <NavLink to={item.link}>
        <svg className="icon"><use href={`${sprite}#${item.icon}`}></use></svg>
        {item.title}
      </NavLink>
    </li>
  );
};

HeaderMobileItem.propTypes = {
  item: PropTypes.object.isRequired,
};

export default HeaderMobileItem;