import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import actions from '../../../store/actions';
import Button from '../../../common/Buttons/Button';
import { useNavigate } from 'react-router-dom';

function Modal({openModal, message, setOpenModalMessage, token }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const closeModal = () => {
    dispatch(actions.finances.getFinancesMessage(''));
    dispatch(actions.finances.setParamInvoice(null));
    dispatch(actions.finances.setParamPaymentUuid(null));
    setOpenModalMessage(false);
    if(token) {
      navigate('/');
    } else {
      navigate('/login');
    }
  };

  return (
    <div className={`modal-info ${openModal ? 'open' : ''}`}>
    <div className="overlay" data-el="overlay" onClick={closeModal}></div>
    <div className="modal-info__container">
      <div className="modal-info__content modal-info__content--gap">
        <div className="modal-info__text" dangerouslySetInnerHTML={{__html: message}}></div>

        <div className='modal-info-btns'>
          <Button 
            buttonBg="green" 
            loading="" 
            onClick={closeModal}
          >
            Закрити
          </Button>
        </div>
      </div>
    </div>
  </div>
  );
}

Modal.propTypes = {
  openModal: PropTypes.bool,
  message: PropTypes.any,
  setOpenModalMessage: PropTypes.func,
  token: PropTypes.string,
};

export default Modal;
