import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useNavigate } from 'react-router-dom';

import actions from '../../../store/actions';
import { registerSelectors } from '../../../store/selectors/register';
import { getRegisterCoursesThunk } from '../../../store/thunks/register';

import { resetErrors } from '../data';

import Tag from '../../../common/Tag/Tag';
import LoadingBtn from '../../LoadingBtn/LoadingBtn';
import CourseCheck from '../../CoursesBlocks/CourseCheck/CourseCheck';
import ErrorMessage from '../../../common/Errors/ErrorMessage';

const RegisterCourseForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  
  const [categoryValue, setCategoryValue] = useState('');
  const [courseValue, setCourseValue] = useState([]);
  const [phoneData] = useState(JSON.parse(localStorage.getItem('phone')));
  const [coursesData] = useState(JSON.parse(localStorage.getItem('courses')));

  const register = useSelector(registerSelectors);
  const courses = register.courses;
  const categories = register.categories;
  const errors = register.errors;
  const phone = register.phone;
  const user = register.user;
  const load = register.load;

  useEffect(() => {
    if(coursesData) {
      const coursesStorage = coursesData.courses;
      const categoriesStorage = coursesData.categories;
  
      if(courses.length === 0 && coursesStorage) {
        dispatch(actions.register.registerCourses(coursesStorage));
      }
  
      if(categories.length === 0 && categoriesStorage) {
        dispatch(actions.register.registerCategories(categoriesStorage));
      }
    } else {
      if(courses.length === 0 && categories.length === 0) {
        return navigate('/login');
      }
      return navigate('/login');
    }

    if(!phone && phoneData) {
      dispatch(actions.register.registerRequest(phoneData));
    } 
  }, []);

  useEffect(() => {
    if(Object.keys(user).length) {
      navigate('/registration/communication');
      resetErrors(dispatch, actions.register);
    }
  }, [user]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = {
      phone, 
      course: courseValue, 
      category: categoryValue
    };
    resetErrors(dispatch, actions.register);
    dispatch(getRegisterCoursesThunk(data));
  };

  const setCourses = (e) => {
    if(e.target.checked) {
      setCourseValue((prev) => [...prev, e.target.value]);
    } else {
      setCourseValue(courseValue.filter(item => item !== e.target.value));
    } 
  };

  return (
    <form className="auth__form auth__form--full form" onSubmit={handleSubmit}>

      <div className="auth-group">
        <h3 className='auth-group__title'>Оберіть для кого курс</h3>
        <div className="tags">
          {categories.map(category => <Tag
            key={category}
            item={category}
            name='tag_age'
            type='radio'
            active={categoryValue === category}
            onClick={(e) => setCategoryValue(e.target.value)} 
          />)}
        </div>
        {errors.category && <ErrorMessage errorText={errors.category} />}
      </div>

      <div className="auth-group">
        <h3 className='auth-group__title'>Оберіть курс</h3>
        <div className="courses-block">
          {courses.map((course, idx) => <CourseCheck
              item={course}
              key={course.id}
              name={`course${idx + 1}`}
              type='checkbox'
              value={course.id}
              onClick={(e) => setCourses(e)} 
            />)}
        </div>
        {errors.course && <ErrorMessage errorText={errors.course} />}
      </div>

      {errors?.phone && <ErrorMessage errorText={errors.phone} />}

      <div className="auth__btns">
          <LoadingBtn load={load} btnText='Зареєструватись' />
          <div className="auth__text">
            Вже маєте профіль? 
            <NavLink to="/login" className="link">Увійти</NavLink>
          </div>
      </div>

    </form>
  );
};

export default RegisterCourseForm;
