import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { profileSelectors } from '../../store/selectors/profile';
import { setBirthDateThunk, setTelegramThunk } from '../../store/thunks/profile';
import { authSelectors } from '../../store/selectors/auth';

import ModalInfo from './ModalInfo';

const Modals = () => {
  const dispatch = useDispatch();

  const [openModalTelegram, setOpenModalTelegram] = useState(false);
  const [openModalBirth, setOpenModalBirth] = useState(false);

  const profile = useSelector(profileSelectors); 
  const auth = useSelector(authSelectors);
  const token = auth.token;
  const user = profile.user;
  const telegramMessage = profile.telegram;
  const birthDateMessage = profile.birthDate;
  const telegram = user.available_link_message_register_telegram;
  const birthDate = user.available_notification_filling_birth_date;

  useEffect(() => {
    if(Object.keys(telegramMessage).length > 0) {
      setOpenModalTelegram(true);
      document.body.classList.add('open');
    }

    if(Object.keys(birthDateMessage).length > 0) {
      setOpenModalBirth(true);
      document.body.classList.add('open');
    }
  }, [telegramMessage, birthDateMessage]);

  useEffect(() => {
    if(telegram && token) {
      dispatch(setTelegramThunk({token}));
    }

    if(birthDate && token) {
      dispatch(setBirthDateThunk({token}));
    }
  }, [telegram, birthDate]);


  return (
    <>
      <ModalInfo notification={birthDateMessage} setOpenModal={setOpenModalBirth} openModal={openModalBirth} />
      <ModalInfo notification={telegramMessage} setOpenModal={setOpenModalTelegram} openModal={openModalTelegram} />
    </>
  );
};

export default Modals;