import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { LayoutContext } from '../../contexts/layout.context';
import { authSelectors } from '../../store/selectors/auth';
import { getPayActualCountTariffThunk, getPayActualCountThunk } from '../../store/thunks/finances';
import { financesSelectors } from '../../store/selectors/finances';
import { getPayThunk } from '../../store/thunks/finances';
import actions from '../../store/actions';
import { copyTextToClipboard } from '../../utils/copy';
import Spinner from '../../common/Spinner/Spinner';
import Head from '../../components/Head/Head';
import FinancePayTable from '../../components/FinancePay/FinancePayTable/FinancePayTable';
import FinancePayCard from '../../components/FinancePay/FinancePayCard/FinancePayCard';
import SelectEl from '../../components/SelectEl/SelectEl';
import FinancesPayBtns from '../../components/FinancePay/FinancesPayBtns/FinancesPayBtns';
import ModalAgreement from '../../components/Modals/ModalAgreement';
import Modal from '../../components/FinancePay/ModalFinances/Modal';
import Plug from '../../components/plug/plug.component';
import sprite from '../../img/sprites.svg';

const FinancePay = () => {
  const { setLayout } = useContext(LayoutContext);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [selectedTariff, setSelectedTariff] = useState(0);
  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [openModal, setOpenModal] = useState({
    'open': false,
    'url': null,
    'name': null,
    'method': null,
    'appointment': null
  });
  const [allowPay, setAllowPay] = useState(false);
  const [openModalMessage, setOpenModalMessage] = useState(false);
  const [invoiceArr, setInvoiceArr] = useState(null);
  const [loadingBtn, setLoadingBtn] = useState(false);

  const finances = useSelector(financesSelectors);
  const auth = useSelector(authSelectors);
  const token = auth.token;
  const load = finances.load;
  const invoice = finances.invoice;
  const tariffs = finances.tariffs;
  const methods = finances.methods;
  const notFound = finances.notFound;
  const paramInvoiceStore = finances.paramInvoice;
  const paramPaymentUuidStore = finances.paramPaymentUuid;
  const error = finances.error;
  const loadBtn = finances.loadBtn;
  const message = finances.message;

  const productId = JSON.parse(localStorage.getItem('product_id'));
  const invoiceId = JSON.parse(localStorage.getItem('invoice_id'));
  const user = JSON.parse(localStorage.getItem('user'));

  const params = new URLSearchParams(location.search);
  const paramInvoice = params.get('invoice');
  const paramPaymentUuid = params.get('payment_uuid');

  // another user
  useEffect(() => {
    if (paramInvoice) {
      dispatch(actions.finances.setParamInvoice(paramInvoice));
    }

    if (paramPaymentUuid) {
      dispatch(actions.finances.setParamPaymentUuid(paramPaymentUuid));
    }
  }, [paramInvoice, paramPaymentUuid]);

  useEffect(() => {
    if (paramInvoiceStore && paramPaymentUuidStore) {
      const data = { invoice_id: paramInvoiceStore, data: { payment_uuid: paramPaymentUuidStore } };
      dispatch(getPayActualCountThunk(data));
    }

    if (paramInvoiceStore && !paramPaymentUuidStore && user?.id) {
      const data = { invoice_id: paramInvoiceStore, data: { user_id: user?.id } };
      dispatch(getPayActualCountThunk(data));
    }
  }, [paramInvoiceStore, paramPaymentUuidStore]);

  useEffect(() => {
    if (notFound) {
      navigate('/');
    }

    if (notFound && !token) {
      navigate('/login');
    }
  }, [notFound]);

  useEffect(() => {
    document.title = 'Рахунки | NewBrain';
    setLayout({ page: 'Рахунки' });
  }, [setLayout]);

  useEffect(() => {
    if (message?.length > 0) {
      setOpenModalMessage(true);
    }
  }, [message]);

  useEffect(() => {
    if (tariffs) {
      setOptions([]);
      tariffs.map(tariff => setOptions((prev) => [...prev, {
        value: tariff.id,
        label: `${tariff.name} (${tariff.hours}год, ${tariff.price}грн за заняття, ${tariff.total_price}грн загалом)`
      }
      ]));
    }
  }, [tariffs]);

  useEffect(() => {
    setSelectedOption(null);

    if (options.length > 0 && invoice) {
      if (invoice.tariff_id && invoice.tariff_preset !== 0) {
        setSelectedOption(options.find(option => option.value === invoice.tariff_id));
      }

      if (invoice.tariff_preset === 0 && invoice) {
        setSelectedOption(options[0]);
      }
    }

    return () => setSelectedOption(null);
  }, [options, invoice]);

  useEffect(() => {
    if (!invoiceId && !paramInvoiceStore) {
      navigate('/finance/accounts');
    }
    dispatch(actions.finances.getFinancesError(''));
  }, []);

  useEffect(() => {
    if (invoiceId && user?.id && !paramInvoice && !paramInvoiceStore) {
      const data = { invoice_id: invoiceId, data: { user_id: user?.id } };
      dispatch(getPayActualCountThunk(data));
    }
  }, [invoiceId]);

  useEffect(() => {
    if ((productId && invoiceId && token && selectedTariff > 0) && !paramInvoiceStore) {
      const data = {
        invoice_id: invoiceId,
        tariff_id: selectedTariff,
        token
      };
      dispatch(getPayActualCountTariffThunk(data));
      setSelectedTariff(0);
    }

    if (paramInvoiceStore && selectedTariff > 0) {
      const data = {
        invoice_id: paramInvoiceStore,
        tariff_id: selectedTariff,
      };
      dispatch(getPayActualCountTariffThunk(data));
      setSelectedTariff(0);
    }
  }, [selectedTariff]);

  useEffect(() => {
    if (invoice) {
      setInvoiceArr(Object.keys(invoice));
    }
  }, [invoice]);

  const setLink = (document_url) => {
    window.open(document_url, '');
  };

  useEffect(() => {
    if(allowPay) {
      setAllowPay(false);
      handleMethodPay(openModal.method, openModal.appointment);
    }
  }, [allowPay]);

  const handleMethodPay = (method, appointment) =>  {
    document.body.classList.remove('open');

    if(method.type === 'details') {
      localStorage.setItem('url', JSON.stringify(method.pay_link));
      return navigate('/finance/accounts/pay/scores');
    }

    if(method.type === 'privat24') {
      copyTextToClipboard(appointment);
      localStorage.setItem('url', JSON.stringify(method.pay_link));
      return navigate('/finance/accounts/pay/message');
    }

    return setPayment(method.pay_link);
  };

  const setPayment = (url) => {
    if(url && !loadingBtn) {
      dispatch(getPayThunk(url));
      setLoadingBtn(true);
    }
  };

  const goBack = () => {
    localStorage.removeItem('invoice_id');
    navigate('/finance/accounts');
  };

  return (
    <div className='finances-pay container'>
      <ModalAgreement openModal={openModal} setAllowPay={setAllowPay} setOpenModal={setOpenModal} type="pay" loadBtn={loadBtn} />
      <Modal openModal={openModalMessage} setOpenModalMessage={setOpenModalMessage} message={message} token={token} />

      <Head textHead="Оплата" subtitle={invoice?.counteragent_full_name && `за ${invoice?.customer_counteragent_full_name}`} goBack={goBack} blockBtn={token ? false : true} />
      {load ? <Spinner /> : <>
        {(invoiceArr?.length > 0 && tariffs?.length > 0) ? <>
          <div className="finances-pay__cards cards">
            <FinancePayCard setLink={setLink} invoice={invoice} />
          </div>

          <div className="finances-pay__table table">
            <div className="finances-pay__table-wrapper">
              <FinancePayTable setLink={setLink} invoice={invoice} />
            </div>
          </div>
          <div className="finances-pay__select">
            <SelectEl
              options={options}
              setSelected={setSelectedTariff}
              selectedOption={selectedOption}
              disabled={invoice.tariff_preset === 0}
              placeholder="Оберіть тариф"
            />
            {error.tariff_id && <span className='error-message'>{error.tariff_id}</span>}
          </div>
          {invoice.counteragent_full_name && <div className='finances-pay-box'>
            <div className='finances-pay-box__title'>Замовник:</div>
            <div className='finances-pay-box__desc'>{invoice.counteragent_full_name}</div>
          </div>}
          {invoice.customer_counteragent_full_name && <div className='finances-pay-box'>
            <div className='finances-pay-box__title'>Отримувач послуг:</div>
            <div className='finances-pay-box__desc'>{invoice.customer_counteragent_full_name}</div>
          </div>}
          {invoice.document_url && <div className='finances-pay-box'>
            <div className="finances-pay-box__title">Переглянути:</div>
            <div className="finances-pay-box__desc">
              <svg className="icon"><use href={`${sprite}#doc`}></use></svg>
              <button onClick={() => setLink(invoice.document_url)} className="table__link link link--violet link--lg finances-pay__link">
                Рахунок-договір
              </button>
            </div>
          </div>}

          {(invoice.tariff_id || invoice.tariff_preset === 0) && <div className='finances-pay__btns pay-btns'>
            {methods.map(method => <FinancesPayBtns
              key={method.type}
              method={method}
              setOpenModal={setOpenModal}
              loadingBtn={loadingBtn} 
              setLoadingBtn={setLoadingBtn}
            />)}
          </div>}
        </> : <Plug plug='Рахунок на оплату недоступний!' icon='file-closed' />}
      </>}
    </div>
  );
};

export default FinancePay;