import React from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';

import Burger from '../../Burger/Burger';
import HeaderProfile from './HeaderProfile/HeaderProfile';

import Logo from '../../../img/logo.png';

const HeaderActions = ({ setOpenMenu, token }) => {
  return (
    <div className="header-top">
      <div className="container">
        <NavLink to='/' className="logo">
          <img src={Logo} alt="logo New Brain" width='1' height='1' decoding='async' />
        </NavLink>
        {
          token &&
          <div className='header-actions'>
            <NavLink to='/directions' className='header-actions__link btn btn-violet'>
              <span>БЕЗКОШТОВНІ</span>ПРОБНІ
            </NavLink>

            <HeaderProfile />

            <div className="header-burger">
              <Burger setOpenMenu={setOpenMenu} />
            </div>
          </div>
        }
      </div>
    </div>
  );
};

HeaderActions.propTypes = {
  setOpenMenu: PropTypes.func.isRequired,
  token: PropTypes.string
};

export default HeaderActions;