import types from '../types/index';

const initialState = {
  courses: [],
  trialCourses: [],
  documents: [],
  studentBtn: false,
  course: '',
  error: '',
  accounts: [],
  invoice: {},
  tariffs: [],
  methods: [],
  details: {},
  paramInvoice: null,
  paramPaymentUuid: null,
  link: '',
  pdf: '',
  load: false,
  message: '',
  notFound: false,
  copyPayment: null,
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case types.finances.GET_FINANCES_USER_COURSES:
      return {
        ...state, courses: payload
      };
    case types.finances.GET_FINANCES_USER_TRIAL_COURSES:
      return {
        ...state, trialCourses: payload
      };
    case types.finances.GET_FINANCES_ERROR:
      return {
        ...state, error: payload
      };
    case types.finances.GET_FINANCES_ERROR_404:
        return {
          ...state, notFound: payload
        };
    case types.finances.GET_FINANCES_USER_DOCUMENTS:
      return {
        ...state, documents: payload
      };
    case types.finances.GET_FINANCES_STUDENT_BUTTON_ACCOUNT:
      return {
        ...state, studentBtn: payload
      };
    case types.finances.GET_FINANCES_DOCUMENTS_NAME:
      return {
        ...state, course: payload
      };
    case types.finances.GET_FINANCES_ACCOUNTS: 
      return {
        ...state, accounts: payload
      };
    case types.finances.GET_PAY_ACTUAL_INVOICE: 
      return {
        ...state, invoice: payload
      };
    case types.finances.GET_PAY_ACTUAL_TARIFFS: 
      return {
        ...state, tariffs: payload
      };
    case types.finances.GET_FINANCES_MESSAGE:
      return {
        ...state, message: payload,
      };
    case types.finances.GET_PAYMENTS_METHODS:
      return {
        ...state, methods: payload,
      };
    case types.finances.GET_PAYMENT_LINK:
      return {
        ...state, link: payload,
      };
    case types.finances.GET_PAY_DETAILS: 
      return {
        ...state, details: payload
      };
    case types.finances.GET_PAY_PDF: 
      return {
        ...state, pdf: payload
      };
    case types.finances.SET_PARAM_INVOICE: 
      return {
        ...state, paramInvoice: payload
      };
    case types.finances.SET_PARAM_PAYMENT_UUID: 
      return {
        ...state, paramPaymentUuid: payload
      };
    case types.finances.CREATE_COPY_PAYMENT:
      return {
        ...state, copyPayment: payload,
      };
    case types.finances.START_FETCHING_FINANCES:
      return {
        ...state, load: true,
      };
    case types.finances.STOP_FETCHING_FINANCES:
      return {
        ...state, load: false
      };
    case types.finances.START_FETCHING_GENERAL_BTN:
      return {
        ...state, loadBtn: true,
      };
    case types.finances.STOP_FETCHING_GENERAL_BTN:
      return {
        ...state, loadBTn: false
      };
    default: {
      return state;
    }
  }
};

export default reducer;