import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import actions from '../../store/actions';

import './Alert.scss';

const Alert = ({ text, setMessage }) => {
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
      const alert = document.querySelector('.alert');
      let time = 5;

      const timer = setInterval(() => {
        if(time < 0) {
          clearInterval(timer);
          alert.classList.remove('open');

          if(location.pathname === '/finance/accounts') {
            dispatch(actions.finances.getFinancesMessage(''));
          }

          if(location.pathname === '/profile') {
            dispatch(actions.profile.editUserInfo(false));
          }

          if(location.pathname === '/') {
            setMessage(false);
          }

        } else {
          alert.classList.add('open');
        }

        --time;
      }, 1000);
  }, []);

  return (
    <div className='alert'>{text}</div>
  );
};

Alert.propTypes = {
  text: PropTypes.string.isRequired,
  setMessage: PropTypes.func.isRequired,
};

export default Alert;