import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { reviewsThunk } from '../../../store/thunks/reviews';
import { authSelectors } from '../../../store/selectors/auth';
import { reviewsSelectors } from '../../../store/selectors/reviews';

import ErrorMessage from '../../../common/Errors/ErrorMessage';
import Button from '../../../common/Buttons/Button';
import FieldTextarea from '../../../common/Fields/FieldTextarea';
import actions from '../../../store/actions';

const ReviewsForm = ({ otherClass, type }) => {
  const dispatch = useDispatch();

  const auth = useSelector(authSelectors);
  const reviews = useSelector(reviewsSelectors);
  const token = auth.token;
  const response = reviews.response;
  const load = reviews.load;
  const errors = reviews.errors;

  const otherClasses = otherClass && otherClass;
  const classes = [otherClasses].filter(Boolean).join(' ');

  const [valueOffer, setValueOffer] = useState('');
  const [valueComplaint, setValueComplaint] = useState('');
  const [counter, setCounter] = useState({
    symbols: 0,
    allSymbols: 2500,
  });

  useEffect(() => {
    if(response) {
      dispatch(actions.reviews.reviewsSuccess(false));
      dispatch(actions.reviews.setReviewsError({}));
      type === 'offer' ? setValueOffer('') : setValueComplaint('');
    } 
  }, [response]);

  useEffect(() => {
    if(type === 'offer') {
      setCounter((prev) => ({...prev, symbols: valueOffer.length}));
    }
    if(type === 'complaint') {
      setCounter((prev) => ({...prev, symbols: valueComplaint.length}));
    }
  }, [valueOffer, valueComplaint, type]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = {type, comment: type === 'offer' ? valueOffer : valueComplaint};
    dispatch(reviewsThunk({data, token}));
    dispatch(actions.reviews.setReviewsError({}));
  };

  return (
    <form className={`reviews-form ${classes}`} onSubmit={handleSubmit}>
      <FieldTextarea 
        id="reviews"
        name="reviews"
        value={type === 'offer' ? valueOffer : valueComplaint}
        onChange={(e) => type === 'offer' ? setValueOffer(e.target.value) : setValueComplaint(e.target.value)}
        placeholder={type === 'offer' ? 'Пропозиція' : 'Скарга'}
        counter={counter}
      />

      {errors?.comment && errors.comment.map((err, idx) => <ErrorMessage key={idx} errorText={err} />)}

      <div className="reviews-form__btn">
        <Button buttonBg="green" loading={load ? 'loading' : ''} disabled={load ? true : false}>Відправити</Button>
      </div>
    </form>
  );
};

ReviewsForm.propTypes = {
  otherClass: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

export default ReviewsForm;