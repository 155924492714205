import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import { getFinancesDocumentsThunk } from '../../../store/thunks/finances';

import FinancesCard from '../FinancesCard/FinancesCard';
import FinancesTable from '../FinancesTable/FinancesTable';

import sprite from '../../../img/sprites.svg';

import { openToggle, toggle } from '../../../utils/accordion';

const FinancesBlock = ({ courses, title, token, type, setModalOpen }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if(courses.length > 0) {
      const block = document.querySelectorAll('[data-block="toggle"]');
      block.forEach(block => {
        openToggle(block);
      });
    }
  }, [courses]);

  const getDocuments = (product_id) => {
    setModalOpen('open');
    dispatch(getFinancesDocumentsThunk({ product_id, token }));
  };

  const getAccounts = (product_id, typeCur) => {
    navigate('/finance/accounts');
    localStorage.setItem('product_id', JSON.stringify(product_id));
    localStorage.setItem('type', JSON.stringify(typeCur));
  };

  const handleToggle = (target) => {
    const btn = target.closest('[data-top="toggle"]');
    const block = target.closest('[data-block="toggle"]');

    if(btn) {
      toggle(block);
    }
  };

  return (
    <div className='page-block open' data-block="toggle">

      <button className="page-block-head" data-top="toggle" onClick={(e) => handleToggle(e.target)} >
        <h2 className='page-block-head__title'>{title}</h2>   
        <svg className="icon"><use href={`${sprite}#arrow`}></use></svg>
      </button>

      <div className="page-block__content" data-content="toggle">

        <div className="page__cards cards">
          {courses.map((course, index) =>
            <FinancesCard
              key={index}
              course={course}
              getDocuments={getDocuments}
              getAccounts={getAccounts}
              type={type}
            />
          )}
        </div>

        <div className="page__table table">
          <div className="finances__table-wrapper">
            <FinancesTable 
              courses={courses} 
              getDocuments={getDocuments} 
              getAccounts={getAccounts}
              type={type}
            />
          </div>
        </div>
        
      </div>

    </div>
  );
};

FinancesBlock.propTypes = {
  courses: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  token: PropTypes.string,
  type: PropTypes.string.isRequired,
  setModalOpen: PropTypes.func
};

export default FinancesBlock;