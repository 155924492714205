import React from 'react';
import PropTypes from 'prop-types';
import './burger.scss';

const Burger = ({ setOpenMenu }) => {
  const openMenu = (event) => {
    event.preventDefault();
    const header = event.target.closest('.header');
    
    if(header) {
      if(header.classList.contains('open')) {
        setOpenMenu(false);
        document.body.classList.remove('open');
      } else {
        setOpenMenu(true);
        document.body.classList.add('open');
      }
    }
  };
  return (
    <button className='burger' aria-label='burger menu' onClick={openMenu}>
        <span className="burger__line burger__line--first"></span>
        <span className="burger__line burger__line--second"></span>
    </button>
  );
};

Burger.propTypes = {
  setOpenMenu: PropTypes.func.isRequired,
};

export default Burger;