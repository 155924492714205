import types from '../types/index';

const initialState = {
  courses: [],
  load: false,
  loadBtn: false,
  success: false,
  message: '',
  error: '',
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case types.general.GENERAL_COURSES:
      return {
        ...state, courses: payload
      };
    case types.general.SET_CHOOSEN_DIRECTION_SUCCES:
      return {
        ...state, success: payload
      };
    case types.general.SET_MESSAGE: 
      return {
        ...state, message: payload
      };
    case types.general.START_FETCHING_GENERAL:
      return {
        ...state, load: true,
      };
    case types.general.STOP_FETCHING_GENERAL:
      return {
        ...state, load: false
      };
    case types.general.SET_ERROR_GENERAL:
      return {
        ...state, error: payload
      };
    default: {
      return state;
    }
  }
};

export default reducer;