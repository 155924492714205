import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import actions from '../../../store/actions';
import { resetSelectors } from '../../../store/selectors/reset';

import { phoneReplaceFunc } from '../../../utils/phoneReplace';

import ErrorField from '../../../common/Errors/ErrorField';
import ErrorMessage from '../../../common/Errors/ErrorMessage';
import FieldTimer from '../../../common/Fields/FieldTimer';

import SendCodeBtn from '../../SendCodeBtn/SendCodeBtn';
import { useNavigate } from 'react-router-dom';
import { getResetCodeThunk } from '../../../store/thunks/reset';
import { resetErrors } from '../data';

const ResetCodeForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const reset = useSelector(resetSelectors);
  const codeSuccess = reset.codeSuccess;
  const load = reset.load;
  const error = reset.error;
  const errors = reset.errors;
  const phone = reset.phone;

  const [codeValue, setCodeValue] = useState('');
  const [phoneReplace, setPhoneReplace] = useState(phone);
  const [message, setMessage] = useState(false);
  const [phoneData] = useState(JSON.parse(localStorage.getItem('phone')));

  useEffect(() => {
    if(codeSuccess) {
      navigate('/reset/password');
    }

    () => {
      resetErrors(dispatch, actions.reset);
      dispatch(actions.reset.setCodeSuccessrRequest(false));
    };
  }, [codeSuccess]);

  useEffect(() => {
    if(!phone && phoneData) {
      dispatch(actions.reset.setResetRequest(phoneData));
    }
  }, [phoneData]);  

  useEffect(() => {
    if(phone) {
      const phoneStr = phoneReplaceFunc(phone);
      setPhoneReplace(phoneStr);
    }
  }, [phone]);

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = {
      code: codeValue,
      phone
    };
    resetErrors(dispatch, actions.reset);
    dispatch(getResetCodeThunk(data));
  };

  const handlePage = () => {
    navigate('/login');
    localStorage.clear();
    dispatch(actions.reset.setResetRequest(''));
  };
  
  return (
    <form className="auth__form form" onSubmit={handleSubmit}>

      <>
        <FieldTimer 
          name="code" 
          type="number"
          value={codeValue} 
          labelText="Код" 
          onChange={(e) => setCodeValue(e.target.value.trim())} 
          formClass="required"
          setMessage={setMessage}
          required
        />
        {(errors?.code && !error) && <ErrorField errorText={errors.code} />}
      </>

      <div className="form__message">
        Введіть код, отриманий на ваш номер телефону <span>{phoneReplace}</span>
      </div>

      {message && <div className="form__message form__message--green">
        Якщо у Вас НЕукраїнський номер телефону, і Ви не отримали SMS з кодом, можливо у Вашу країну неможливо відправити повідомлення, зверніться до нашого Адміністратора
      </div>}

      {error && <ErrorMessage errorText={error} />}
      {(errors?.phone && !error) && <ErrorMessage errorText={errors.phone} />}

      <div className="auth__btns">
        <SendCodeBtn load={load} value={codeValue}/>
        <div className="auth__text">
          Згадали пароль? 
          <button className="link" onClick={handlePage}>Увійти</button>
        </div>
      </div>

    </form>
  );
};

export default ResetCodeForm;