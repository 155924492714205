import React from 'react';
import PropTypes from 'prop-types';
import './button.scss';
import './spinner.scss';

const Button = ({ children, buttonBg, tableClass, loading, icon, iconAlt, ...props }) => {

  const buttonTypeClass = buttonBg ? `btn-${[buttonBg]}` : '';
  const tableClasses = tableClass && tableClass;
  const loadingClass = loading && 'loading';
  const classes = [buttonTypeClass, loadingClass, tableClasses].filter(Boolean).join(' ');

  return (
    <button className={`btn ${classes}`} {...props}>
      {icon && <img src={icon} alt={iconAlt} />}
      <span className='btn__text'>{children}</span>
      <span className='btn__spinner'>
        <svg className="spinner" viewBox="0 0 50 50">
          <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="5"></circle>
        </svg>
      </span>
    </button> 
  );
};

Button.propTypes = {
  children: PropTypes.any.isRequired,
  buttonBg: PropTypes.string,
  loading: PropTypes.string,
  icon: PropTypes.string,
  iconAlt: PropTypes.string,
  tableClass: PropTypes.string,
};

export default Button;