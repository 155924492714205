import React from 'react';
import PropTypes from 'prop-types';

import Info from '../../common/Info/Info';

import { fieldsRejected } from '../../components/Profile/constants';

import './card-user.style.scss';

const UserInfoCard = ({ title, confirm, userData, goEdit, name }) => {
  const fields = fieldsRejected[confirm.field];
  const from = confirm.value_from;
  const to = confirm.value_to;
  const data = {fields, from, to};

  return (
    <div className='card-user'>

      <div className="card-user-inner">
        <div className="card-user-content">
          <div className="card-user__title">{title}</div>
          <div className="card-user__text">{userData}</div>
        </div>
        {((confirm.status === 0 && confirm.field === 'phone') || !name) 
        ? <button className='card-user__link link' disabled>Змінити</button>
        : <button className='card-user__link link' onClick={() => goEdit()}>Змінити</button>}
      </div>

      {confirm.status === 0 && <Info data={data} condition='confirmed' />}
    </div>
  );
};

UserInfoCard.propTypes = {
  title: PropTypes.string.isRequired,
  userData: PropTypes.string.isRequired,
  goEdit: PropTypes.func.isRequired,
  confirm: PropTypes.object,
  name: PropTypes.string
};

export default UserInfoCard;