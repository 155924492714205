import types from '../types/index';

const initialState = {
  user: {},
  errors: {},
  edit: false,
  changes: [],
  confirmInfo: [],
  confirmed: false,
  times: [],
  phone: '',
  telegram: {},
  birthDate: {},
  contacts: [],
  load: false,
  loadForm: false
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case types.profile.SET_USER_INFO:
      return {
        ...state, user: payload
      };
    case types.profile.SET_USER_EDIT_INFO: 
      return {
        ...state, edit: payload
      };
    case types.profile.SET_USER_EDIT_PHONE: 
      return {
        ...state, phone: payload
      };
    case types.profile.SET_USER_CONFIRM_INFO: 
      return {
        ...state, confirmInfo: payload
    };
    case types.profile.CHECK_CHANGED_DATA: 
      return {
        ...state, changes: payload
      };
    case types.profile.SET_USER_TIME_TO_LESSON: 
      return {
        ...state, times: payload,
      };
    case types.profile.CHANGES_VERIFIED:
      return {
        ...state, confirmed: payload
      };
    case types.profile.SET_NOTIFICATION_TELEGRAM:
      return {
        ...state, telegram: payload
      };
    case types.profile.SET_NOTIFICATION_BIRTH_DATE:
      return {
        ...state, birthDate: payload
      };
    case types.profile.SET_USER_CONTACTS:
      return {
        ...state, contacts: payload
      };
    case types.profile.SET_PROFILE_ERROR:
      return {
        ...state, errors: payload
      };
    case types.profile.START_FETCHING_PROFILE:
      return {
        ...state, load: true
      };
    case types.profile.STOP_FETCHING_PROFILE:
      return {
        ...state, load: false
      };
    default: {
      return state;
    }
  }
};

export default reducer;