import React from 'react';
import PropTypes from 'prop-types';

import Button from '../../../common/Buttons/Button';
import CourseName from '../../CourseName/CourseName';

const FinancesTableRow = ({
  course,
  getAccounts,
  getDocuments,
  type
}) => {
  return (
    <tr className='table-body__row'>

      <td className='table-body__cell'>
        <CourseName img={course.image} name={course.name} label={course.type} />
      </td>

      {type !== 'trial_courses' && <>
        <td className='table-body__cell table-body__cell--center'>{course.qty || '-'}</td>
        <td className='table-body__cell table-body__cell--center'>{course.total || '-'}</td>
      </>}

      <td className='table-body__cell table-body__cell--center'>
        {type === 'course'
          ? ((course.status != 3)
            ? <Button
              buttonBg='green'
              loading=''
              onClick={() => getAccounts(course.product_id, type)}
            >
              Оплатити
            </Button>
            : '-')
          : (
            <Button
              buttonBg='green'
              loading=''
              onClick={() => getAccounts(course.product_id, type)}
            >
              Оплатити
            </Button>
          )}
      </td>

      {type !== 'trial_courses' &&
        <td className='table-body__cell table-body__cell--center'>
          <Button buttonBg="transparent link" tableClass="table__link" loading="" onClick={() => getDocuments(course.product_id)}>Переглянути</Button>
        </td>}

    </tr>
  );
};

FinancesTableRow.propTypes = {
  course: PropTypes.object.isRequired,
  getDocuments: PropTypes.func.isRequired,
  getAccounts: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
};

export default FinancesTableRow;