import React from 'react';
import PropTypes from 'prop-types';

import './tabs-round.style.scss';

const TabsRound = ({ items, otherClass, setType, ...props }) => {
  const otherClasses = otherClass && otherClass;
  const classes = [otherClasses].filter(Boolean).join(' ');

  const handleActive = (type, target) => {
    const tabs = document.querySelectorAll('.tabs-round>li>button');

    if(tabs) {
      tabs.forEach(tab => tab.classList.remove('active'));
      setType(type);
    }

    if(type === target.value) {
      target.classList.add('active');
    }
  };

  return (
    <ul className={`tabs-round ${classes}`}>
      {items.map(item => <li key={item.type}>
        <button 
          value={item.type}
          className={`${item.id === 1 ? 'active' : ''}`} 
          onClick={(e) => handleActive(item.type, e.target)} 
          {...props}
        >
          {item.title}
        </button>
        </li>)}
    </ul>
  );
};

TabsRound.propTypes = {
  items: PropTypes.array.isRequired,
  otherClass: PropTypes.string.isRequired,
  setType: PropTypes.func.isRequired,
};

export default TabsRound;