import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import actions from '../../store/actions';
import { createCopyPaymentThunk } from '../../store/thunks/finances';
import { financesSelectors } from '../../store/selectors/finances';
import { copyTextToClipboard } from '../../utils/copy';
import { setTimeFromSeconds } from '../../utils/time';
import ButtonIcon from '../../common/ButtonIcon/ButtonIcon';
import Tooltip from '../Tooltip/Tooltip';
import './copy-timer.style.scss';

const CopyPayment = ({validTime, url, id}) => {
  const dispatch = useDispatch();

  const copyUrl = useRef();

  const finances = useSelector(financesSelectors);
  const copyPayment = finances.copyPayment;

  const [copied, setCopied] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const [hoverTooltip, setHoverTooltip] = useState(true);
  const [hours, setHours] = useState(false);
  const [minutes, setMinutes] = useState(false);
  const [seconds, setSeconds] = useState(false);
  let [validDuration, setValidDuration] = useState(false);

  useEffect(() => {
    if(validTime) {
      setValidDuration(validTime);
    }
  }, [validTime]);

  useEffect(() => {
    if(copyPayment?.valid_seconds) {
      setValidDuration(copyPayment.valid_seconds);
    }
  }, [copyPayment]);

  useEffect(() => {
    let timer;

    if (validDuration && validDuration > 0) {
      timer = setInterval(() => {
        setValidDuration(prevDuration => prevDuration - 1);
      }, 1000);
    }

    return () => clearInterval(timer);
  }, [validDuration]);

  useEffect(() => {
    if(validDuration) {
      const { hours, minutes, seconds } = setTimeFromSeconds(validDuration);
      setHours(hours);
      setMinutes(minutes);
      setSeconds(seconds);
    }
  }, [validDuration]);

  useEffect(() => {
    if(copied) {
      let timer = setTimeout(() => {
        let timerInside = setTimeout(() => {
          setHoverTooltip(true);
          setCopied(false);
          clearInterval(timerInside);
        }, 1000);

        setShowTooltip(false);
        clearTimeout(timer);
      }, 2000);
    }
  }, [copied]);

  const handleCreatePayment = (invoiceId) => {
    dispatch(actions.finances.createCopyPayment(null));
    dispatch(createCopyPaymentThunk({invoice_id: invoiceId}));

    if(copyUrl.current) {
      const text = copyUrl.current.innerText;

      copyTextToClipboard(text).then(() => {
        setHoverTooltip(false);

        let timer = setTimeout(() => {
          setShowTooltip(true);
          setCopied(true);
          clearTimeout(timer);
        }, 1000);
      });
    }
  };

  return (
    <div className='copy-btn'>
      <Tooltip copyText={copied ? 'Скопійовано!' : 'Копіювати посилання!'} showTooltip={showTooltip} hoverTooltip={hoverTooltip}>
        <ButtonIcon icon='copy' classes="copy-btn__btn" onClick={() => handleCreatePayment(id)}/>
        {url && <div ref={copyUrl} className="hide">{url}</div>}
      </Tooltip>
      {validDuration &&
      <div className="copy-btn__timer">
          {hours && <><span>{hours < 10 ? `0${hours}` : hours}</span> : </>}
          {minutes && <><span>{minutes < 10 ? `0${minutes}` : minutes}</span> : </>}
          {seconds && <span>{seconds < 10 ? `0${seconds}` : seconds}</span>}
      </div>}
    </div>
  );
};

export default CopyPayment;

CopyPayment.propTypes = {
  validTime: PropTypes.any,
  url: PropTypes.string,
  id: PropTypes.number,
};