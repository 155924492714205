import { visit } from './constants';

const constants = (event) => {
  const dateNow = new Date();
  const dateLesson = new Date(event.date);

  const dateLessonStart = new Date(dateLesson.setHours(event.hour));
  const dateLessonEnd = new Date(dateLesson.setHours(event.hour + 1));

  const lessonFuture = dateLessonStart > dateNow;
  const lessonLeft = dateLessonEnd < dateNow;
  const lesssonNow =  dateLessonStart <= dateNow && dateLessonEnd >= dateNow;

  return {lessonFuture, lessonLeft, lesssonNow};
};

export const colorStatuses = (event) => {
  const {lessonFuture,  lessonLeft, lesssonNow} = constants(event);

  if(lesssonNow) {
    return 'event-yellow';
  }

  if(lessonLeft) {
    return 'event-green';
  }

  if(lessonFuture) {
    return 'event-blue';
  }
};

 export const setStatusName = (event) => {
  const {lessonFuture, lessonLeft, lesssonNow} = constants(event);

  if(lesssonNow) {
    return 'Заняття розпочато';
  }

  if((lessonFuture)) {
    return 'Заняття заплановано';
  }

  if((lessonLeft)) {
    if(event.status === 4 || event.status === 3) {
      return visit[event.attendance_status];
    } else {
      return 'Заняття завершене';
    }
  }
};