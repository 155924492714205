import { login, loginCrm, logout } from '../../api/auth/index';
import actions from '../actions/index';
import Cookies from 'js-cookie';

export function getLoginThunk(data) {
  return (dispatch) => {
    dispatch(actions.auth.startFetching());
    login(data)
      .then(res => {
        if (res.data.info.status) {
          dispatch(actions.auth.loginRequest(res.data.data.token));
          dispatch(actions.auth.getUserStatus(res.data.data.status));
          dispatch(actions.profile.userInfo(res.data.data));
          localStorage.setItem('token', JSON.stringify(res.data.data.token));
          localStorage.setItem('user', JSON.stringify(res.data.data));
        }
      })
      .catch(error => {
        if(error.response.status >= 500) {
          dispatch(actions.auth.setLoginError('Наразі функціонал в розробці!'));
        } else {
          dispatch(actions.auth.setLoginErrors(error.response.data.data));
          dispatch(actions.auth.setLoginError(error.response.data.info.message));
        }
      })
      .finally(() => dispatch(actions.auth.stopFetching()));
  };
}

export function getLoginCrmThunk(data) {
  return (dispatch) => {
    dispatch(actions.auth.startFetching());
    loginCrm(data)
      .then(res => {
        if (res.data.info.status) {
          dispatch(actions.auth.loginRequest(res.data.data.token));
          dispatch(actions.auth.getUserStatus(res.data.data.status));
          dispatch(actions.profile.userInfo(res.data.data));
          localStorage.setItem('token', JSON.stringify(res.data.data.token));
          localStorage.setItem('user', JSON.stringify(res.data.data));
        }
      })
      .catch(error => {
        if(error.response.status === 404) {
          dispatch(actions.auth.setLoginCrmError(true));
        }
      })
      .finally(() => dispatch(actions.auth.stopFetching()));
  };
}

export function getLogoutThunk(data) {
  return (dispatch) => {
    dispatch(actions.auth.startFetching());
    logout(data)
      .then(res => {
        if (res.data.info.status) {
          dispatch(actions.auth.logoutRequest(res.data.data));
          dispatch(actions.profile.userInfo({}));
          dispatch(actions.schedule.getScheduleType([]));
          dispatch(actions.schedule.getDayEvent([]));
          localStorage.clear();
          Cookies.remove('user_token', { expires: 86400, sameSite: 'lax' });
        }
      })
      .catch(error => {
        dispatch(actions.auth.setLoginError(error.response.data.info.message));
      })
      .finally(() => dispatch(actions.auth.stopFetching()));
  };
}