export default {
  SET_USER_INFO: 'SET_USER_INFO',

  SET_USER_EDIT_INFO: 'SET_USER_EDIT_INFO', 
  SET_USER_EDIT_PHONE: 'SET_USER_EDIT_PHONE',

  SET_USER_CONFIRM_INFO: 'SET_USER_CONFIRM_INFO',
  SET_USER_TIME_TO_LESSON: 'SET_USER_TIME_TO_LESSON',
  SET_USER_CONTACTS: 'SET_USER_CONTACTS',

  CHECK_CHANGED_DATA: 'CHECK_CHANGED_DATA',
  CHANGES_VERIFIED: 'CHANGES_VERIFIED', 

  SET_NOTIFICATION_TELEGRAM: 'SET_NOTIFICATION_TELEGRAM',
  SET_NOTIFICATION_BIRTH_DATE: 'SET_NOTIFICATION_BIRTH_DATE',
  
  SET_PROFILE_ERROR: 'SET_PROFILE_ERROR',

  START_FETCHING_PROFILE: 'START_FETCHING_PROFILE',
  STOP_FETCHING_PROFILE: 'STOP_FETCHING_PROFILE',
};