import { courses, direction } from '../../api/general';
import actions from '../actions';

export function getCoursesThunk(data) {
  return (dispatch) => {
    dispatch(actions.general.startFetching());
    courses(data)
      .then(res => {
        if (res.data.info.status) {
            dispatch(actions.general.setGeneralCourses(res.data.data.directions));
        }
      })
      .catch(error => {
        dispatch(actions.general.setLoginError(error.response.data.data));
      })
      .finally(() => dispatch(actions.general.stopFetching()));
  };
}

export function getChoosenDirectionThunk(data) {
  return (dispatch) => {
    direction(data)
      .then(res => {
        if (res.data.info.status) {
          dispatch(actions.general.setChoosenDirectionSuccess(res.data.data));
          dispatch(actions.general.setMessage(res.data.info.message));
        }
      })
      .catch(error => {
        dispatch(actions.general.setLoginError(error.response.data.data));
      });
  };
}