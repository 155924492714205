export const colors = (actual, payed, notActual) => {
  if(actual) return 'green';

  if(payed) return 'violet';

  if(notActual) return 'gray';
};

export const statuses = (actual, payed, notActual) => {
  if(actual) return 'Актуальний';

  if(payed)  return 'Оплачено';

  if(notActual) return 'Неактуальний';
};