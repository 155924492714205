import axios from 'axios';

const api = axios.create({
  baseURL: `${process.env.REACT_APP_URL}/api`,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache, no-store, must-revalidate',
    'Pragma': 'no-cache',
    'Expires': '0',
  }
});

export const setToken = (token) => {
  const auth = `Bearer ${token}`;
  api.defaults.headers.common['Authorization'] = auth;
};

api.interceptors.response.use(
  response => response,
  error => {
    if (error?.response?.status === 401) {
      window.location = '/login';
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      localStorage.removeItem('user_token');
      return Promise.reject();
    }
  return Promise.reject(error);
});

export default api;