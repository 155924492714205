import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { authSelectors } from '../../../../store/selectors/auth';
import { editUserInfoThunk } from '../../../../store/thunks/profile';
import { profileSelectors } from '../../../../store/selectors/profile';

import SelectEl from '../../../SelectEl/SelectEl';

import { setTimeFromSeconds } from '../../../../utils/time';

const SettingsSelect = () => {
  const dispatch = useDispatch();

  const [options, setOptions] = useState([]);
  const [selectedTime, setSelectedTime] = useState(0);
  const [selectedOption, setSelectedOption] = useState(null);

  const auth = useSelector(authSelectors);
  const profile = useSelector(profileSelectors);
  const token = auth.token;
  const times = profile.times;
  const user = profile.user;

  useEffect(() => {
    if(Object.keys(user).length > 0) {
      setSelectedTime(user.time_to_lesson);
    }
  }, [user]);

  useEffect(() => {
    if(times.length > 0) {
      setOptions([]);
      times.map(time => {
        const {hours, minutes} = setTimeFromSeconds(time * 60);  
        setOptions((prev) => [...prev, {
          value: time,
          label: `Нагадувати за ${hours > 0 ? hours + ' год.' : minutes + ' хв.'}`
        }]);
      });
    }
  }, [times]);

  useEffect(() => {
    if(selectedTime && options.length > 0) {
      setSelectedOption(null);
      setSelectedOption(options.find(option => Number(option.value) == selectedTime));
    }
  }, [selectedTime, options]);

  const handleSelect = (val) => {
    if(selectedTime && token) {
      setSelectedTime(val);
      dispatch(editUserInfoThunk({value: val, field: 'time_to_lesson', token}));
    }
  };

  return (
    <SelectEl
      options={options} 
      setSelected={handleSelect}
      selectedOption={selectedOption}
      disabled={false}
      placeholder="Оберіть час"
    />
  );
};

export default SettingsSelect;