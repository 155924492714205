import React, { useContext, useEffect, useState } from 'react';
import { LayoutContext } from '../../contexts/layout.context';
import { useDispatch, useSelector } from 'react-redux';

import { getCoursesThunk } from '../../store/thunks/general';
import { generalSelectors } from '../../store/selectors/general';
import { authSelectors } from '../../store/selectors/auth';
import { getUserInfoThunk } from '../../store/thunks/profile';

import Spinner from '../../common/Spinner/Spinner';
import ErrorMessage from '../../common/Errors/ErrorMessage';
import CourseBlock from '../../components/CoursesBlocks/CourseBlock';

import GeneralModal from '../../components/General/GeneralModal';
import GeneralModalInfo from '../../components/General/GeneralModalInfo';

const GeneralPage = () => {
  const { setLayout } = useContext(LayoutContext);
  const dispatch = useDispatch();

  const [openModal, setOpenModal] = useState(false);
  const [openModalInfo, setOpenModalInfo] = useState(false);
  const [course, setCourse] = useState({});

  useEffect(() => {
    document.title = 'Головна | NewBrain';
    setLayout({ page: 'Головна' });
  }, [setLayout]);

  const general = useSelector(generalSelectors);
  const auth = useSelector(authSelectors);
  const token = auth.token;
  const courses = general.courses;
  const error = general.error;
  const load = general.load;

  useEffect(() => {
    if(token) {
      dispatch(getCoursesThunk({token}));
      dispatch(getUserInfoThunk({token}));
    }
  }, [token]);

  return (
    <div className="general container">
      <GeneralModal setOpenModal={setOpenModal} openModal={openModal} course={course} setOpenModalInfo={setOpenModalInfo} />
      <GeneralModalInfo openModalInfo={openModalInfo} setOpenModalInfo={setOpenModalInfo} />
      <ErrorMessage errorText={error} />
      {load ? <Spinner /> : <div className="courses-block">
        {courses.map(course => <CourseBlock 
          key={course.id} 
          item={course} 
          btnText='Записатися на пробний'
          setOpenModal={setOpenModal} 
          setCourse={setCourse}
        />)}
      </div>}
    </div>
  );
};

export default GeneralPage;