import React from 'react';
import PropTypes from 'prop-types';
import sprite from '../../img/sprites.svg';

import './button-icon.scss';

const ButtonIcon = ({ icon, classes, ...props }) => {
  return (
    <button className={`btn-icon ${classes}`} {...props}>
      <svg className="icon"><use href={`${sprite}#${icon}`}></use></svg>
    </button> 
  );
};

ButtonIcon.propTypes = {
  icon: PropTypes.string.isRequired,
  classes: PropTypes.string
};

export default ButtonIcon;