import React from 'react';
import PropTypes from 'prop-types';

import sprite from '../../img/sprites.svg';

import { images } from '../../utils/importImg';

import './socials.style.scss';

const Socials = ({items}) => {
  return (
    <ul className="socials">
      {items.map(item => <li key={item.key}>
        <a href={item.value} target='_blank' rel="noreferrer noopener" className='socials__link'>
          {item.key === 'instagram' 
          ? <img src={images['instagram.svg']} alt='instagram' width="1" height="1" /> 
          : <svg><use href={`${sprite}#${item.key}`}></use></svg>}
        </a>
      </li>)}
    </ul>
  );
};

Socials.propTypes = {
  items: PropTypes.array.isRequired,
};

export default Socials;