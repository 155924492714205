import React, { useEffect } from 'react';
import RegisterCourse from '../../components/Registration/RegisterCourse/RegisterCourse';
import HeaderAuth from '../../components/HeaderAuth/HeaderAuth';

const RegisterCoursePage = () => {
  useEffect(() => {
    document.body.removeAttribute('class');
    document.title = 'Оберіть курс | NewBrain';
  }, []);
    
  return (
    <>
      <HeaderAuth />
      <section className='container container--login'>
        <RegisterCourse />
      </section>
    </>
  );
};

export default RegisterCoursePage;