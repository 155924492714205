
import { combineReducers } from 'redux';
import finances from './finances';
import auth from './auth';
import schedule from './schedule';
import profile from './profile';
import register from './register';
import code from './code';
import reset from './reset';
import general from './general';
import reviews from './reviews';
import programs from './programs';

const rootReducer = combineReducers({
  auth,
  schedule,
  finances,
  profile,
  register,
  code,
  reset,
  general,
  reviews,
  programs,
});

export default rootReducer;
