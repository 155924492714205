import React from 'react';
import PropTypes from 'prop-types';
import './error.scss';

const ErrorField = ({ errorText }) => {
  return (
    <span className="error-field">{errorText}</span>
  );
};

ErrorField.propTypes = {
    errorText: PropTypes.array.isRequired,
};

export default ErrorField;