import React from 'react';
import TitleAuth from '../../../common/Titles/TitleAuth';
import RegisterCodeForm from './RegisterCodeForm';
import '../../Login/auth.scss';

const RegisterCode = () => {
  return (
    <div className="auth">
      <TitleAuth titleText="Підтвердження номера"/>
      <RegisterCodeForm />
    </div>
  );
};

export default RegisterCode;