import React from 'react';
import PropTypes from 'prop-types';

import './switcher.style.scss';

const Switcher = ({ active, ...props }) => {
  return (
    <div className='switcher'>
      <input type="checkbox" id='switcher' {...props} checked={active === 'on' ? true : false} />
      <label htmlFor="switcher">Перемкнути</label>
    </div>
  );
};

Switcher.propTypes = {
  active: PropTypes.string.isRequired,
};

export default Switcher;