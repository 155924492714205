import React, { useContext, useEffect } from 'react';
import { LayoutContext } from '../../contexts/layout.context';
import { useNavigate } from 'react-router-dom';
import Button from '../../common/Buttons/Button';

const PaymentSuccess = () => {
  const { setLayout } = useContext(LayoutContext);
  const navigate = useNavigate();

  useEffect(() => {
    document.title = 'Реквізити | NewBrain';
    setLayout({ page: 'Реквізити' });
  }, [setLayout]);

  useEffect(() => {
    let time = 2;
    const timer = setInterval(() => {
      if(time === 0) {
        navigate('/');
      }
      --time;
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const handlePage = () => {
    navigate('/');
  };

  return (
    <div className='finances-message container'>
      <div className="finances__message finances__message--green">
        Оплата успішна! <br></br>
        Дякуємо, Козаче! <br></br>
        Чи Ви Козачка?)))
      </div>
      <div className="finances__btn">
        <Button buttonBg="green" onClick={handlePage}>Далі</Button>
      </div>
    </div>
  );
};

export default PaymentSuccess;