import api, { setToken } from '../index';

export function getFinances(data) {
  return api.get('/finance/user/courses', setToken(data.token));
}

export function getFinancesDocuments(data) {
  return api.get(`/finance/user/documents/${data.product_id}`, setToken(data.token));
}

export function financesAccounts(data) {
  return api.get(`/finance/user/documents/${data.product_id}/pay`, setToken(data.token));
}

export function financesLeadAccounts(data) {
  return api.get(`/finance/user/documents/${data.product_id}/lead/pay`, setToken(data.token));
}

export function createCount(data) {
  return api.post(`/finance/user/documents/${data.product_id}/pay/add-invoice`, setToken(data.token));
}

export function payActualCount(data) {
  return api.post(`/finance/user/pay/${data.invoice_id}/invoice`, data.data);
}

export function payActualTariff(data) {
  if(data.token) {
    return api.post(`/finance/user/pay/${data.invoice_id}/invoice/update`, {tariff_id: data.tariff_id}, setToken(data.token));
  } else {
    return api.post(`/finance/user/pay/${data.invoice_id}/invoice/update`, {tariff_id: data.tariff_id});
  }
}

export function getFinancesPdf(data) {
  if(data.token) {
    return api.get(`/finance/user/pay/${data.invoice_id}/invoice/doc-pdf`, setToken(data.token));
  } else {
    return api.get(`/finance/user/pay/${data.invoice_id}/invoice/doc-pdf`);
  }
}

export function getFinancesContract(data) {
  if(data.token) {
    return api.get(`/finance/user/pay/${data.invoice_id}/invoice/doc-pdf/contract`, setToken(data.token));
  } else {
    return api.get(`/finance/user/pay/${data.invoice_id}/invoice/doc-pdf/contract`);
  }
}

export function createCopyPayment(data) {
  return api.post(`/finance/user/pay/${data.invoice_id}/url-generate`);
}

export function payDetails(data) {
  return api.post(data.url);
}

export function getPay(data) {
  return api.post(data); 
}