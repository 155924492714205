import React from 'react';
import PropTypes from 'prop-types';

import './contract.style.scss';

const Contract = ({ text, title }) => {
  const toggleContract = () => {
    const btn = document.querySelector('.toggle-btns');
    const title = document.querySelector('.contract__title');
    const content = document.querySelector('.contract__content');

    if(title || btn) {
      if(content.hasAttribute('style')) {
        btn.classList.remove('toggle');
        content.removeAttribute('style');
      } else {
        btn.classList.add('toggle');
        content.style.height = content.scrollHeight + 'px';
      }
    }
  };
  return (
    <div className='contract'>
      <button className="contract__title" onClick={toggleContract}>{title}</button>

      <div className="contract__content">
        <div className='contract__text' dangerouslySetInnerHTML={{__html: text}}></div>
      </div>
      
      <button className="contract__btns toggle-btns" onClick={toggleContract}>
        <span className='link toggle-btns__show'>Показати повністю</span>
        <span className='link toggle-btns__hide'>Сховати</span>
      </button>
  </div>
  );
};

Contract.propTypes = {
  text: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default Contract;