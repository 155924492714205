import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useNavigate } from 'react-router-dom';

import { resetSelectors } from '../../../store/selectors/reset';

import ErrorField from '../../../common/Errors/ErrorField';
import FieldPhone from '../../../common/Fields/FieldPhone';

import LoadingBtn from '../../LoadingBtn/LoadingBtn';
import { getResetConfirmThunk } from '../../../store/thunks/reset';
import { resetErrors } from '../data';
import actions from '../../../store/actions';

const ResetConfirmForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [phoneValue, setPhoneValue] = useState('');

  const reset = useSelector(resetSelectors);
  const phone = reset.phone;
  const errors = reset.errors;
  const load = reset.load;

  useEffect(() => {
    if(phone) {
      navigate('/reset/code');
      resetErrors(dispatch, actions.reset);
    }

    () => actions.reset.setResetRequest('');
  }, [phone]);

  const handleSubmit = (e) => {
    e.preventDefault();
    resetErrors(dispatch, actions.reset);
    dispatch(getResetConfirmThunk({phone: `+${phoneValue}`}));
  };

  return (
    <form className="auth__form form" onSubmit={handleSubmit}>

      <>
        <FieldPhone
          name="phone" 
          type="tel" 
          valuePhone={phoneValue} 
          onChange={setPhoneValue} 
          labelText="Телефон"
          formClass="required"
          labelMessage=""
          required
        /> 
        {errors?.phone && <ErrorField errorText={errors.phone} />}
      </>

      <div className="auth__btns">
        <LoadingBtn load={load} btnText='Надіслати код' />
        <div className="auth__text">
          Вже маєте профіль? 
          <NavLink to="/login" className="link">Увійти</NavLink>
        </div>
      </div>

    </form>
  );
};

export default ResetConfirmForm;