import React, { useContext, useEffect, useState } from 'react';
import { LayoutContext } from '../../contexts/layout.context';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { authSelectors } from '../../store/selectors/auth';
import { createCountThunk, getFinancesAccountsThunk, getFinancesLeadAccountsThunk, getPayContractThunk } from '../../store/thunks/finances';
import { financesSelectors } from '../../store/selectors/finances';
import actions from '../../store/actions';

import FinanceAccountsCard from '../../components/FinanceAccounts/FinanceAccountsCard/FinanceAccountsCard';
import FinanceAccountsTable from '../../components/FinanceAccounts/FinanceAccountsTable/FinanceAccountsTable';
import Button from '../../common/Buttons/Button';
import Head from '../../components/Head/Head';
import Spinner from '../../common/Spinner/Spinner';
import Plug from '../../components/plug/plug.component';
import ModalAgreement from '../../components/Modals/ModalAgreement';

const FinanceAccounts = () => {
  const { setLayout } = useContext(LayoutContext);

  const [actual, setActual] = useState({});
  const [openModal, setOpenModal] = useState({
    'open': false,
    'id': null,
    'url': null,
    'name': null
  });
  const [allowPay, setAllowPay] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const finances = useSelector(financesSelectors);
  const auth = useSelector(authSelectors);
  const token = auth.token;
  const load = finances.load;
  const pdf = finances.pdf;
  const studentBtn = finances.studentBtn;
  const accounts = finances.accounts;
  const message = finances.message;
  const loadBtn = finances.loadBtn;

  const productId = JSON.parse(localStorage.getItem('product_id'));
  const type = JSON.parse(localStorage.getItem('type'));

  useEffect(() => {
    document.title = 'Оплата | NewBrain';
    setLayout({ page: 'Оплата' });
  }, [setLayout]);

  useEffect(() => {
    if(!productId) {
      navigate('/');
    }
    
    localStorage.removeItem('invoice_id');
    dispatch(actions.finances.setParamInvoice(null));
    dispatch(actions.finances.setParamPaymentUuid(null));
  }, []);

  useEffect(() => {
    if(accounts.length > 0) {
      setActual(accounts.find(count => count.show_button_pay === true && !count.total_price));
    }
  }, [accounts]);

  useEffect(() => {
    if(productId && token) {
      if(type === 'trial_courses') {
        dispatch(getFinancesLeadAccountsThunk({product_id: productId, token}));
      } else {
        dispatch(getFinancesAccountsThunk({product_id: productId, token}));
      }
    }
  }, [productId, token]);

  useEffect(() => {
    if(allowPay) {
      handlePay(openModal.id);
    }
  }, [allowPay]);

  useEffect(() => {
    if(pdf) {
      window.open(pdf, '_target');
      dispatch(actions.finances.getPayPdf(''));
    }
  }, [pdf]);

  const handleCreate = () => {
    dispatch(createCountThunk({product_id: productId, token}));
  };

  const handlePay = (invoice_id) => {
    localStorage.setItem('invoice_id', JSON.stringify(invoice_id));
    navigate('/finance/accounts/pay');
    setOpenModal({
      'open': false,
      'id': null,
      'url': null,
      'name': null
    });
    setAllowPay(false);
    document.body.classList.remove('open');
  };

  const goBack = () => {
    navigate('/');
    localStorage.removeItem('product_id');
    localStorage.removeItem('type');
  };

  const getPdf = (invoiceId) => {
    dispatch(getPayContractThunk({invoice_id: invoiceId, token}));
  };

  return (
    <div className='finances-pay container'>
      <ModalAgreement openModal={openModal} setOpenModal={setOpenModal} setAllowPay={setAllowPay} type='accounts' loadBtn={loadBtn} />

      <Head textHead='Рахунки' goBack={goBack} />

      {((!actual || accounts.length === 0) && studentBtn && !message)
       && <div className="finances-pay__btn">
          <Button buttonBg="white" loading="" onClick={handleCreate}>Створити рахунок</Button>
        </div>}

      {load ? <Spinner /> : 
        (accounts.length > 0 ? <>
          <div className="finances-pay__cards cards">
            {accounts.map(count => <FinanceAccountsCard 
              key={count.id} 
              count={count} 
              setOpenModal={setOpenModal} 
              getPdf={getPdf}
            />)}
          </div>
          <div className="finances-pay__table table">
            <div className="finances-pay__table-wrapper finances-pay__table-wrapper--sm">
              <FinanceAccountsTable 
                accounts={accounts} 
                setOpenModal={setOpenModal} 
                getPdf={getPdf}
              />
            </div>
          </div>
        </> : <Plug plug={
          message 
          ? message 
          : !studentBtn 
            ? 'Рахунків ще немає! Очікуйте створення рахунку адміністратором.' 
            : 'Рахунків ще немає! Створіть, будь ласка, рахунок.'} 
          icon='file-closed' 
          />) 
      }
    </div>
  );
};

export default FinanceAccounts;