import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Layout from './components/layout/layout.component';
import GeneralPage from './routes/GeneralPage/GeneralPage';
import Unauthorized from './routes/unauthorized/unauthorized.component';
import Missing from './routes/missing/missing.component';
import Schedule from './routes/Schedule/Schedule';
import Profile from './routes/Profile/ProfilePage/profile.component';
import Programs from './routes/Programs/Programs/Programs';
import CourseProgram from './routes/Programs/CourseProgram/CourseProgram';
import Finance from './routes/finance/finance.component';
import ProtectedRoute from './routes/protectedRoute/protectedRoute';
import LoginPage from './routes/LoginPage/LoginPage';
import RegisterPage from './routes/RegisterPage/RegisterPage';
import RegisterCoursePage from './routes/RegisterCourse/RegisterCoursePage';
import RegisterConfirmPage from './routes/RegisterConfirmPage/RegisterConfirmPage';
import RegisterCodePage from './routes/RegisterCodePage/RegisterCodePage';
import RegisterCommunicationPage from './routes/RegisterCommunicationPage/RegisterCommunicationPage';
import RegisterWaitPage from './routes/RegisterWaitPage/RegisterWaitPage';
import RegisterSimilarPage from './routes/RegisterSimilarPage/RegisterSimilarPage';
import RegisterFeedbackPage from './routes/RegisterFeedbackPage/RegisterFeedbackPage';
import ResetConfirmPage from './routes/ResetConfirmPage/ResetConfirmPage';
import ResetPasswordPage from './routes/ResetPasswordPage/ResetPasswordPage';
import ResetCodePage from './routes/ResetCodePage/ResetCodePage';
import FinanceAccounts from './routes/FinanceAccounts/FinanceAccounts';
import FinancePay from './routes/FinancePay/FinancePay';
import FinanceScore from './routes/FinanceScore/FinanceScore';
import EditPhone from './routes/Profile/EditPhone/EditPhone';
import EditBirthday from './routes/Profile/EditBirthday/EditBirthday';
import EditEmail from './routes/Profile/EditEmail/EditEmail';
import EditPassword from './routes/Profile/EditPassword/EditPassword';
import EditName from './routes/Profile/EditName/EditName';
import EditLastName from './routes/Profile/EditLastName/EditLastName';
import EditMiddleName from './routes/Profile/EditMiddleName/EditMiddleName';
import EditInstgram from './routes/Profile/EditInstgram/EditInstgram';
import ConfirmEditPhone from './routes/Profile/ConfirmEditPhone/ConfirmEditPhone';
import PaymentFailed from './routes/Payment/PaymentFailed';
import PaymentSuccess from './routes/Payment/PaymentSuccess';
import FinancePayMessage from './routes/FinancePayMessage/FinancePayMessage';
import Contacts from './routes/Contacts/Contacts';
import Reviews from './routes/Reviews/Reviews';
import Settings from './routes/Settings/Settings';
import LoginCrm from './routes/LoginCrm/LoginCrm';

import './scss/app.scss';

const App = () => {
  return (
    <Routes>
      <Route path='/login' element={
        <ProtectedRoute isAuth='no' isRegister="">
          <LoginPage />
        </ProtectedRoute>
      } />

      <Route path='/login/crm' element={
        <ProtectedRoute isAuth='no' isRegister="">
          <LoginCrm />
        </ProtectedRoute>
      } />

      <Route path='/registration' element={
        <ProtectedRoute isAuth='no' isRegister="">
          <RegisterPage />
        </ProtectedRoute>
      } />

      <Route path='/registration/confirm' element={
        <ProtectedRoute isAuth='no' isRegister="no">
          <RegisterConfirmPage />
        </ProtectedRoute>
      } />

      <Route path='/registration/code' element={
        <ProtectedRoute isAuth='no' isRegister="no">
        <RegisterCodePage />
        </ProtectedRoute>
      } />

      <Route path='/registration/course' element={
        <ProtectedRoute isAuth='no' isRegister="no">
          <RegisterCoursePage />
        </ProtectedRoute>
      } />

      <Route path='/registration/communication' element={
        <ProtectedRoute isAuth='no' isRegister="no">
          <RegisterCommunicationPage />
        </ProtectedRoute>
      } />

      <Route path='/registration/wait' element={
        <ProtectedRoute isAuth='no' isRegister="">
          <RegisterWaitPage />
        </ProtectedRoute>
      } />

      <Route path='/registration/similar' element={
        <ProtectedRoute isAuth='no' isRegister="no">
          <RegisterSimilarPage />
        </ProtectedRoute>
      } />

      <Route path='/registration/feedback' element={
        <ProtectedRoute isAuth='no' isRegister="no">
          <RegisterFeedbackPage />
        </ProtectedRoute>
      } />

      <Route path='/reset/confirm' element={
        <ProtectedRoute isAuth='no' isRegister="">
          <ResetConfirmPage />
        </ProtectedRoute>
      } />

      <Route path='/reset/code' element={
        <ProtectedRoute isAuth='no' isRegister="">
          <ResetCodePage />
        </ProtectedRoute>
      } />  

      <Route path='/reset/password' element={
        <ProtectedRoute isAuth='no' isRegister="">
          <ResetPasswordPage />
        </ProtectedRoute>
      } />

      <Route path='/' element={
        <ProtectedRoute isAuth='yes'isRegister="">
          <Layout />
        </ProtectedRoute>
      }>
        <Route index='/' element={
          <ProtectedRoute isAuth='yes' isRegister="">
            <Finance />
          </ProtectedRoute>
        } />
        <Route path='/schedule' element={
          <ProtectedRoute isAuth='yes' isRegister="">
            <Schedule />
          </ProtectedRoute>
        } />
        <Route path='/directions' element={
          <ProtectedRoute isAuth='yes' isRegister=""> 
            <GeneralPage />
          </ProtectedRoute>
        } />
        <Route path='/finance/accounts' element={
          <ProtectedRoute isAuth='yes' isRegister="">
            <FinanceAccounts />
          </ProtectedRoute>
        } />
        <Route path='/finance/accounts/pay' element={
          <ProtectedRoute isAuth='yes' isRegister="">
            <FinancePay />
          </ProtectedRoute>
        } />
        <Route path='/finance/accounts/pay/scores' element={
          <ProtectedRoute isAuth='yes' isRegister="">
            <FinanceScore />
          </ProtectedRoute>
        } />
        <Route path='/finance/accounts/pay/message' element={
          <ProtectedRoute isAuth='yes' isRegister="">
            <FinancePayMessage />
          </ProtectedRoute>
        } />
        <Route path='/payment/failed' element={
          <ProtectedRoute isAuth='yes' isRegister="">
            <PaymentFailed />
          </ProtectedRoute>
        } />
        <Route path='/payment/success' element={
          <ProtectedRoute isAuth='yes' isRegister="">
            <PaymentSuccess />
          </ProtectedRoute>
        } />
        <Route path='/courses' element={
          <ProtectedRoute isAuth='yes' isRegister="">
            <Programs />
          </ProtectedRoute>
        } />
        <Route path='/course/program' element={
          <ProtectedRoute isAuth='yes' isRegister="">
            <CourseProgram />
          </ProtectedRoute>
        } />
        <Route path='/profile' element={
          <ProtectedRoute isAuth='yes' isRegister="">
            <Profile />
          </ProtectedRoute>
        } />
        <Route path='/profile/edit/name/first' element={
          <ProtectedRoute isAuth='yes' isRegister="">
            <EditName />
          </ProtectedRoute>
        } />
        <Route path='/profile/edit/name/last' element={
          <ProtectedRoute isAuth='yes' isRegister="">
            <EditLastName />
          </ProtectedRoute>
        } />
        <Route path='/profile/edit/name/middle' element={
          <ProtectedRoute isAuth='yes' isRegister="">
            <EditMiddleName />
          </ProtectedRoute>
        } />
        <Route path='/profile/edit/phone' element={
          <ProtectedRoute isAuth='yes' isRegister="">
            <EditPhone />
          </ProtectedRoute>
        } />
        <Route path='/profile/edit/phone/confirm' element={
          <ProtectedRoute isAuth='yes' isRegister="">
            <ConfirmEditPhone />
          </ProtectedRoute>
        } />
        <Route path='/profile/edit/birthday' element={
          <ProtectedRoute isAuth='yes' isRegister="">
            <EditBirthday />
          </ProtectedRoute>
        } />
        <Route path='/profile/edit/email' element={
          <ProtectedRoute isAuth='yes' isRegister="">
            <EditEmail />
          </ProtectedRoute>
        } />
        <Route path='/profile/edit/instagram' element={
          <ProtectedRoute isAuth='yes' isRegister="">
            <EditInstgram />
          </ProtectedRoute>
        } />
        <Route path='/profile/edit/password' element={
          <ProtectedRoute isAuth='yes' isRegister="">
            <EditPassword />
          </ProtectedRoute>
        } />

        <Route path='/contacts' element={
          <ProtectedRoute isAuth='yes' isRegister="">
            <Contacts />
          </ProtectedRoute>
        } />

        <Route path='/reviews' element={
          <ProtectedRoute isAuth='yes' isRegister="">
            <Reviews />
          </ProtectedRoute>
        } />

        <Route path='/settings' element={
          <ProtectedRoute isAuth='yes' isRegister="">
            <Settings />
          </ProtectedRoute>
        } />
      </Route>

      <Route path='/unauthorized' element={
        <ProtectedRoute isAuth='yes' isRegister="">
          <Unauthorized />
        </ProtectedRoute>
      } />
      <Route path='*' element={
        <ProtectedRoute isAuth='yes' isRegister="">
          <Missing />
        </ProtectedRoute>
      } />
    </Routes>
  );
};

export default App;