import React from 'react';
import Logo from '../../img/logo.png';

const HeaderAuth = () => {
  return (
    <header className='header-auth'>
      <img src={Logo} alt="New brain logo" width="1" height="1" />
    </header>
  );
};

export default HeaderAuth;