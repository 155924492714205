import React from 'react';
import PropTypes from 'prop-types';
import { colorStatuses, setStatusName } from '../data';

const EventModalTableRow = ({ event }) => {
  return (
    <tr className={`table-body__row ${colorStatuses(event)}`}>
      <td className='table-body__cell'>
      <div className="course-name">
          <img src={event.image} alt={event.direction} width="1" height="1" decoding='async' />
          {event.direction} 
        </div>
      </td>
      <td className='table-body__cell table-body__cell--center table-time'>{event.hour}:00</td>
      <td className='table-body__cell table-body__cell--center'>{event.teacher_fname}</td>
      <td className='table-body__cell table-body__cell--center'>
        {setStatusName(event)}
      </td>
    </tr> 
  );
};

EventModalTableRow.propTypes = {
  event: PropTypes.object.isRequired,
};

export default EventModalTableRow;