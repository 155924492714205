import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getLoginCrmThunk } from '../../store/thunks/auth';
import { useLocation, useNavigate } from 'react-router-dom';
import { authSelectors } from '../../store/selectors/auth';
import actions from '../../store/actions';

const LoginCrm = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const auth = useSelector(authSelectors);

  const params = new URLSearchParams(location.search);
  const token = params.get('token');

  const errorCrm = auth.errorCrm;

  useEffect(() => {
    if(token) {
      dispatch(getLoginCrmThunk({token: token}));
      dispatch(actions.auth.setLoginCrmError(false));
    }
  }, [token]);

  useEffect(() => {
    if(errorCrm) {
      navigate('/login');
      dispatch(actions.auth.setLoginCrmError(false));
    }
  }, [errorCrm]);

  return <div></div>;
};

export default LoginCrm;