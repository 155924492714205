import api, { setToken } from '../index';

export function register(data) {
	return api.post('/auth/register', data);
}

export function registerConfirm(data) {
	return api.post('/auth/verify/send/phone', data);
}

export function registerConfirmAgain(data) {
	return api.post('/auth/verify/send/phone-again', data);
}

export function registerCode(data) {
	return api.post('/auth/verify/send/code', data);
}

export function registerCourses(data) {
	return api.post('/auth/register/courses', data);
}

export function registerCommunication(data) {
	return api.post('/auth/register/socials', data.socials, setToken(data.token));
}

export function registerMe(data) {
	return api.post('/auth/register/profile-found', data);
}

export function registerNotMe(data) {
	return api.get('/auth/register/not-me', {params: data});
}

export function registerFeedback(data) {
	return api.post('/auth/register/not-me/send-comment', data);
}