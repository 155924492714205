import types from '../types/index';

const initialState = {
  response: false,
  load: false,
  errors: {},
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case types.reviews.SET_REVIEWS_SUCCESS:
      return {
        ...state, response: payload,
      };
    case types.reviews.SET_ERRORS_REVIEWS:
      return {
        ...state, errors: payload,
      };
    case types.reviews.START_FETCHING_REVIEWS:
      return {
        ...state, load: true,
      };
    case types.reviews.STOP_FETCHING_REVIEWS:
      return {
        ...state, load: false
      };
    default: {
      return state;
    }
  }
};

export default reducer;