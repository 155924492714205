import React from 'react';
import TitleAuth from '../../../common/Titles/TitleAuth';
import RegisterConfirmForm from './RegisterConfirmForm';
import '../../Login/auth.scss';

const RegisterConfirm = () => {
  return (
    <div className="auth">
      <TitleAuth titleText="Підтвердження номера"/>
      <RegisterConfirmForm />
    </div>
  );
};

export default RegisterConfirm;