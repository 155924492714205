import types from '../types/index';

const setResetRequest = (payload) => ({
  type: types.reset.SET_RESET_REQUEST,
  payload
});

const startFetching = () => ({
  type: types.reset.START_FETCHING_RESET
});

const stopFetching = () => ({
  type: types.reset.STOP_FETCHING_RESET,
});

const setResetErrors = (payload) => ({
  type: types.reset.SET_ERRORS_RESET,
  payload,
});

const setResetError = (payload) => ({
  type: types.reset.SET_ERROR_RESET,
  payload,
});

const setCodeSuccessrRequest = (payload) => ({
  type: types.reset.SET_CODE_RESET_REQUEST,
  payload
});

const setNewPasswordChangesuccess = (payload) => ({
  type: types.reset.SET_NEW_PASSWORD_CHANGE_REQUEST,
  payload
});

// const setResetErrors = (payload) => ({
//     type: types.reset.SET_RESET_ERRORS,
//     payload
// });

// const setChangePasswordRequest = (payload) => ({
//     type: types.reset.SET_CHANGE_PASSWORD_REQUEST,
//     payload
// });

// const setChangePasswordErrors = (payload) => ({
//     type: types.reset.SET_CHANGE_PASSWORD_ERRORS,
//     payload
// });

export default {
  setResetRequest,
  startFetching,
  stopFetching,
  setResetErrors,
  setResetError,
  setCodeSuccessrRequest,
  setNewPasswordChangesuccess,
};