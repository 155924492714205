import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { resetErrors } from '../data';

import actions from '../../../store/actions';
import { authSelectors } from '../../../store/selectors/auth';
import { registerSelectors } from '../../../store/selectors/register';
import { getRegisterMeThunk, getRegisterNotMeThunk } from '../../../store/thunks/register';

import Button from '../../../common/Buttons/Button';
import ErrorMessage from '../../../common/Errors/ErrorMessage';

const RegisterSimilarForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [phoneData] = useState(JSON.parse(localStorage.getItem('phone')));
  const [userData] = useState(JSON.parse(localStorage.getItem('user')));

  const register = useSelector(registerSelectors);
  const auth = useSelector(authSelectors);
  const token = auth.token;
  const notSimilar = register.notSimilar;
  const error = register.error;
  const load = register.load;
  const phone = register.phone;
  const user = register.user;

  useEffect(() => {
    if(!phone && phoneData) {
      dispatch(actions.register.registerRequest(phoneData));
    }
  }, [phoneData]);

  useEffect(() => {
    if(!Object.keys(user).length && userData) {
      dispatch(actions.register.registerUser(userData));
    }
  }, [userData]);

  useEffect(() => {
    if(token) {
      resetErrors(dispatch, actions.register);
      navigate('/', { replace: true });
    }
  }, [token]);

  useEffect(() => {
    if(notSimilar) {
      resetErrors(dispatch, actions.register);
      navigate('/registration/feedback');
    }
  }, [notSimilar]);

  const setLogin = () => {
    resetErrors(dispatch, actions.register);
    dispatch(getRegisterMeThunk({phone}));
  };

  const setFeedback = () => {
    resetErrors(dispatch, actions.register);
    dispatch(getRegisterNotMeThunk({phone}));
  };

  return (
    <div className='auth__form form form--center'>
      <div className="form__title form__title--center">
        За Вашим номером телефону знайдено профіль: 
        <br></br> <span>{user?.last_name} {user?.first_name} {user?.mid_name ? user.mid_name : ''}</span> 
        <br></br> Це ви? Обʼєднати дані?
      </div>

      {error  && <ErrorMessage errorText={error} />}

      <div className="auth__btns">
        {load 
        ? <Button type="submit" buttonBg="violet" loading="loading" disabled>Так, обʼєднати</Button>
        : <Button type="submit" buttonBg="violet" onClick={setLogin} loading="">Так, обʼєднати</Button>}
  
        <div className="auth__text auth__text--sm">
          <Button type="submit" className="link" loading="" onClick={setFeedback}>Ні, це не я:(</Button>
        </div>
      </div>
    </div>
  );
};

export default RegisterSimilarForm;