import types from '../types/index';

const initialState = {
  phone: '',
  errors: {},
  error: '',
  load: false,
  codeSuccess: false,
  passwordSuccess: false,
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case types.reset.SET_RESET_REQUEST:
      return {
        ...state, phone: payload
      };
    case types.reset.SET_ERRORS_RESET:
      return {
        ...state, errors: payload
      };
    case types.reset.SET_RESET_ERROR:
      return {
        ...state, error: payload
      };
    case types.reset.START_FETCHING_RESET:
      return {
        ...state, load: true,
      };
    case types.reset.STOP_FETCHING_RESET:
      return {
        ...state, load: false
      };
    case types.reset.SET_CODE_RESET_REQUEST: 
      return {
        ...state, codeSuccess: payload
      };
    case types.reset.SET_NEW_PASSWORD_CHANGE_REQUEST:
      return {
        ...state, passwordSuccess: payload
      };
    default: {
      return state;
    }
  }
};

export default reducer;