import React from 'react';
import PropTypes from 'prop-types';
import './title.scss';

const TitleAuth = ({ titleText, centering }) => {
  return (
    <div className={`title-auth ${centering ? 'title-auth--center' : ''}`}>
      <h1>Кабінет Студента</h1>
      <h2>{titleText}</h2>
    </div>
  );
};

TitleAuth.propTypes = {
  titleText: PropTypes.string.isRequired,
  centering: PropTypes.string
};

export default TitleAuth;