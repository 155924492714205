import React from 'react';
import { useSelector } from 'react-redux';
import { profileSelectors } from '../../../store/selectors/profile';

import Socials from '../../Socials/Socials';

import '../../Contacts/contacts-card.style.scss';

const SocialsCard = () => {
  const profile = useSelector(profileSelectors);
  const contacts = profile.contacts;
  const keys = ['instagram', 'tik_tok', 'facebook', 'twitter', 'youtube'];
  const socials = contacts.filter(item => keys.includes(item.key));

  return (
    <div className='contacts-card'>
      <h3 className="contacts-card-title">Соцмережі</h3>
      <Socials items={socials} />
    </div>
  );
};

export default SocialsCard;