import React, { useEffect } from 'react';
import Login from '../../components/Login/Login';
import HeaderAuth from '../../components/HeaderAuth/HeaderAuth';

const LoginPage = () => {
  useEffect(() => {
    document.body.removeAttribute('class');
    document.title = 'Авторизація | NewBrain';
  }, []);

  return (
    <div> 
      <HeaderAuth />
      <section className="container container--login">
        <Login />
      </section>
    </div>
  );
};

export default LoginPage;