import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { reviewsSelectors } from '../../../store/selectors/reviews';

import Button from '../../../common/Buttons/Button';

const ReviewsModal = () => {
  const [openModal, setOpenModal] = useState(false);

  const reviews = useSelector(reviewsSelectors);
  const response = reviews.response;

  useEffect(() => {
    if(response) {
      setOpenModal(true);
    }
  }, [response]);

  const closeModal = () => {
    setOpenModal(false);
  };
  
  return (
    <div className={`modal-info modal-reviews ${openModal ? 'open' : ''}`}>
      <div className="overlay" onClick={closeModal}></div>
      
      <div className="modal-info__container">
        <div className="modal-info__content"> 
          <h3 className="modal-info__title">Дякуємо за відгук!</h3>
          <p className="modal-info__subtitle">Дані відправлено на обробку</p>
          <div className="modal-info__btn">
            <Button buttonBg="green" onClick={closeModal}>Повернутись</Button>
          </div>
        </div>
      </div>

    </div>
  );
};

ReviewsModal.propTypes = {
  type: PropTypes.string.isRequired,
};

export default ReviewsModal;