import React from 'react';
import TitleAuth from '../../../common/Titles/TitleAuth';
import RegisterSimilarForm from './RegisterSimilarForm';
import '../../Login/auth.scss';

const RegisterSimilar = () => {
  return (
    <div className="auth">
        <TitleAuth titleText="Знайдено схожий профіль"/>
        <RegisterSimilarForm />
    </div>
  );
};

export default RegisterSimilar;