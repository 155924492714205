import { 
  register, 
  registerCode, 
  registerCommunication, 
  registerConfirm, 
  registerConfirmAgain, 
  registerCourses, 
  registerFeedback, 
  registerMe, 
  registerNotMe 
} from '../../api/register/index';
import action from '../actions/index';

export function getRegisterThunk(data) {
  return (dispatch) => {
    dispatch(action.register.startFetching());
    register(data)
      .then(res => {
        if (res.data.info.status) {
          dispatch(action.register.registerRequest(res.data.data.phone));
          dispatch(action.register.registeredUser(res.data.data.not_me));
          localStorage.setItem('phone', JSON.stringify(res.data.data.phone));
        }
      })
      .catch(error => {
        if(error.response.status >= 500) {
          dispatch(action.register.setRegisterError('Наразі функціонал в розробці!'));
        } else {
          dispatch(action.register.setRegisterErrors(error.response.data.data));
          dispatch(action.register.setRegisterError(error.response.data.info.message));
        }
      })
      .finally(() => dispatch(action.register.stopFetching()));
  };
}

export function getRegisterConfirmThunk(data) {
  return (dispatch) => {
    dispatch(action.register.startFetching());
    registerConfirm(data)
      .then(res => {
        if (res.data.info.status) {
          dispatch(action.register.registerConfirmRequest(res.data.data.phone));
          localStorage.setItem('phone', JSON.stringify(res.data.data.phone));
        }
      })
      .catch(error => {
        if(error.response.status >= 500) {
          dispatch(action.register.setRegisterError('Наразі функціонал в розробці!'));
        } else {
          dispatch(action.register.setRegisterErrors(error.response.data.data));
          dispatch(action.register.setRegisterError(error.response.data.info.message));
        }
      })
      .finally(() => dispatch(action.register.stopFetching()));
  };
}

export function getRegisterConfirmAgainThunk(data) {
  return (dispatch) => {
    registerConfirmAgain(data)
      .then(res => {
        if (res.data.info.status) {
          dispatch(action.register.registerConfirmRequest(res.data.data.phone));
          localStorage.setItem('phone', JSON.stringify(res.data.data.phone));
        }
      })
      .catch(error => {
        if(error.response.status >= 500) {
          dispatch(action.register.setRegisterError('Наразі функціонал в розробці!'));
        } else {
          dispatch(action.register.setRegisterErrors(error.response.data.data));
          dispatch(action.register.setRegisterError(error.response.data.info.message));
        }
      });
  };
}

export function getRegisterCodeThunk(data) {
  return (dispatch) => {
    dispatch(action.register.startFetching());
    registerCode(data)
      .then(res => {
        if (res.data.info?.status) {
          if(res.data.data.register) {
            dispatch(action.register.registerCodeNotSimilarRequest(res.data.data.register));
            dispatch(action.register.registerCourses(res.data.data.courses));
            dispatch(action.register.registerCategories(res.data.data.categories)); 
            localStorage.setItem('courses', JSON.stringify(res.data.data));
          }
          
          if(res.data.data.similar) {
            dispatch(action.register.registerCodeSimilarRequest(res.data.data.similar));
            dispatch(action.register.registerUser(res.data.data));
            localStorage.setItem('user', JSON.stringify(res.data.data));
          }

          if(res.data.data.lead) {
            dispatch(action.register.registerLead(res.data.data.lead));
            dispatch(action.register.registerUser(res.data.data.user));
            localStorage.setItem('registerToken', JSON.stringify(res.data.data.user.token));
            localStorage.setItem('user', JSON.stringify(res.data.data.user));
          }
        }
      })
      .catch(error => {
        if(error?.response?.status >= 500) {
          dispatch(action.register.setRegisterError('Наразі функціонал в розробці!'));
        } else {
          dispatch(action.register.setRegisterErrors(error.response.data.data));
          dispatch(action.register.setRegisterError(error.response.data.info.message));
        }
      })
      .finally(() => dispatch(action.register.stopFetching()));
  };
}

export function getRegisterCoursesThunk(data) {
  return (dispatch) => {
    dispatch(action.register.startFetching());
    registerCourses(data)
      .then(res => {
        if (res.data.info.status) {
          dispatch(action.register.registerUser(res.data.data));
          dispatch(action.register.registerSuccess(res.data.data.token));
          
          localStorage.setItem('registerToken', JSON.stringify(res.data.data.token));
          localStorage.setItem('user', JSON.stringify(res.data.data));
        }

        if(res.status >= 500) {
          dispatch(action.register.setRegisterError('Наразі функціонал в розробці!'));
        }
      })
      .catch(error => {
        if(error.response.status >= 500) {
          dispatch(action.register.setRegisterError('Наразі функціонал в розробці!'));
        } else {
          dispatch(action.register.setRegisterErrors(error.response.data.data));
          dispatch(action.register.setRegisterError(error.response.data.info.message));
        }
      })
      .finally(() => dispatch(action.register.stopFetching()));
  };
}

export function getRegisterCommunicationThunk(data) {
  return (dispatch) => {
    dispatch(action.register.startFetching());
    registerCommunication(data)
      .then(res => {
        if (res.data.info.status) {
          dispatch(action.register.registerCommunicationRequest(res.data.info.status));
        }
      })
      .catch(error => {
        if(error.response.status >= 500) {
          dispatch(action.register.setRegisterError('Наразі функціонал в розробці!'));
        } else {
          dispatch(action.register.setRegisterErrors(error.response.data.data));
          dispatch(action.register.setRegisterError(error.response.data.info.message));
        }
      })
      .finally(() => dispatch(action.register.stopFetching()));
  };
}

export function getRegisterMeThunk(data) {
  return (dispatch) => {
    dispatch(action.register.startFetching());
    registerMe(data)
      .then(res => {
        if (res.data.info.status) {
          dispatch(action.auth.loginRequest(res.data.data.token));
          dispatch(action.auth.getUserRequest(res.data.data));
          dispatch(action.auth.getUserStatus(res.data.data.status));

          dispatch(action.register.registerRequest(''));
          dispatch(action.register.registerConfirmRequest(''));
          dispatch(action.register.registerUser({}));

          localStorage.setItem('token', JSON.stringify(res.data.data.token));
          localStorage.setItem('user', JSON.stringify(res.data.data));

          localStorage.removeItem('phone');
          localStorage.removeItem('courses');
        }
      })
      .catch(error => {
        if(error.response.status >= 500) {
          dispatch(action.register.setRegisterError('Наразі функціонал в розробці!'));
        } else {
          dispatch(action.register.setRegisterErrors(error.response.data.data));
          dispatch(action.register.setRegisterError(error.response.data.info.message));
        }
      })
      .finally(() => dispatch(action.register.stopFetching()));
  };
}

export function getRegisterNotMeThunk(data) {
  return (dispatch) => {
    registerNotMe(data)
      .then(res => {
        if (res.data.info.status) {
          dispatch(action.register.registerNotMeRequest(res.data.data));
        }
      })
      .catch(error => {
        if(error.response.status >= 500) {
          dispatch(action.register.setRegisterError('Наразі функціонал в розробці!'));
        } else {
          dispatch(action.register.setRegisterErrors(error.response.data.data));
          dispatch(action.register.setRegisterError(error.response.data.info.message));
        }
      });
  };
}

export function getRegisterNotMeFeedbackThunk(data) {
  return (dispatch) => {
    dispatch(action.register.startFetching());
    registerFeedback(data)
      .then(res => {
        if (res.data.info.status) {
          dispatch(action.register.registerNotMeFeedbackRequest(true));
          dispatch(action.register.registerRequest(''));
          dispatch(action.register.registerConfirmRequest(''));
          dispatch(action.register.registerUser({}));
          localStorage.removeItem('phone');
          localStorage.removeItem('user');
          localStorage.removeItem('courses');
        }
      })
      .catch(error => {
        if(error.response.status >= 500) {
          dispatch(action.register.setRegisterError('Наразі функціонал в розробці!'));
        } else {
          dispatch(action.register.setRegisterErrors(error.response.data.data));
          dispatch(action.register.setRegisterError(error.response.data.info.message));
        }
      });
  };
}