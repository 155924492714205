import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { profileSelectors } from '../../../store/selectors/profile';

import SettingSwitcher from './SettingSwitcher/SettingSwitcher';
import ErrorMessage from '../../../common/Errors/ErrorMessage';
import SettingsSelect from './SettingsSelect/SettingsSelect';

import { messages } from './constants';

import './card-settings.style.scss';

const CardSettings = ({ title, el }) => {
  const profile = useSelector(profileSelectors);
  const errors = profile.errors;

  const [active, setActive] = useState('');

  return (
    <>
      <div className='card-settings'>
        <div className={`card-settings__content ${el === 'select' ? 'card-settings__content--col' : ''}`}>
          <h4 className="card-settings__title">{title}</h4>
          {el === 'switcher' &&  <SettingSwitcher active={active} setActive={setActive} />}

          {el === 'select' && <div className="card-settings__select">
            <SettingsSelect />
          </div>}
        </div>

        {active === 'off' && el === 'switcher' 
        ? <div className='card-settings__message'>{messages[active]}</div> : ''}
        {errors?.value &&  errors.value.map(err => <ErrorMessage key={err} errorText={err} />)}
      </div>
    </>

  );
};

CardSettings.propTypes = {
  title: PropTypes.string.isRequired,
  el: PropTypes.string.isRequired
};

export default CardSettings;