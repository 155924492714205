import React, { useContext, useEffect, useState } from 'react';
import { LayoutContext } from '../../contexts/layout.context';
import { useNavigate } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import { financesSelectors } from '../../store/selectors/finances';
import { getPayThunk } from '../../store/thunks/finances';
import actions from '../../store/actions';

import Head from '../../components/Head/Head';

const FinancePayMessage = () => {
  const { setLayout } = useContext(LayoutContext);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [loadingBtn, setLoadingBtn] = useState(false);

  const url = JSON.parse(localStorage.getItem('url'));

  const finances = useSelector(financesSelectors);
  const link = finances.link;
  const loadBtn = finances.loadBtn;

  useEffect(() => {
    document.title = 'Оплата | NewBrain';
    setLayout({ page: 'Оплата' });
  }, [setLayout]);

  useEffect(() => {
    if(link) {
      window.location.replace(link);
      dispatch(actions.finances.getPaymentLink(''));
      localStorage.removeItem('url');
      setLoadingBtn(false);
    }
  }, [link]);

  const goBack = () => {
    navigate('/finance/accounts/pay');
    localStorage.removeItem('url');
    dispatch(actions.finances.getPaymentLink(''));
  };

  const handlePay = (url) => {
    if(url) {
      dispatch(getPayThunk(url));
      setLoadingBtn(true);
    }
  };

  return (
    <div className='finances finance-message container'>
      <Head textHead='Оплата Приват24' goBack={goBack} />
      <div className="finance-message__content">
        <div className="finance-message__text">
          Для Вашої зручності, ми автоматично скопіювали в буфер призначення платежу.
          На наступному кроці вставте його в примітку платежу.
          Це допоможе якнайшвидше ідентифікувати Ваш платіж.
          <br/>
          Дякуємо!
        </div>

        <div className="finance-message__btns">
          <button
            onClick={() => handlePay(url)}
            loading={loadingBtn ? 'loading' : ''} 
            disabled={loadBtn ? true : false}
            className="btn btn-green"
          >
            Далі
          </button>
        </div>
      </div>
    </div>
  );
};

export default FinancePayMessage;