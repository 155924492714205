import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { resetErrors } from '../data';

import actions from '../../../store/actions';
import { registerSelectors } from '../../../store/selectors/register';
import { getRegisterConfirmThunk } from '../../../store/thunks/register';

import LoadingBtn from '../../LoadingBtn/LoadingBtn';
import FieldPhone from '../../../common/Fields/FieldPhone';
import ErrorField from '../../../common/Errors/ErrorField';
import ErrorMessage from '../../../common/Errors/ErrorMessage';

const RegisterConfirmForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [phone, setPhone] = useState(JSON.parse(localStorage.getItem('phone')));

  const register = useSelector(registerSelectors);
  const phoneConfirm = register.phoneConfirm;
  const error = register.error;
  const errors = register.errors;
  const load = register.load;

  useEffect(() => {
    if(phoneConfirm) {
      navigate('/registration/code');
      resetErrors(dispatch, actions.register);
    }
  }, [phoneConfirm]);

  const handleSubmit = (event) => {
    event.preventDefault();
    const phoneValue = phone.replace('+', '');
    const data = {phone: `+${phoneValue}`};
    resetErrors(dispatch, actions.register);
    dispatch(getRegisterConfirmThunk(data));
  };
  return (
    <form className="auth__form form" onSubmit={handleSubmit}>

      <>
        <FieldPhone 
          name="phone" 
          type="tel" 
          valuePhone={phone} 
          onChange={setPhone} 
          labelText="Номер телефону"
          labelMessage=" "
          formClass="required"
          required
        />
        {(errors?.phone && !error) && <ErrorField errorText={errors.phone} />}
      </>

      {error && <ErrorMessage errorText={error} />}

      <div className="auth__btns">
        <LoadingBtn load={load} btnText='Надіслати код' />
      </div>

    </form>
  );
};

export default RegisterConfirmForm;