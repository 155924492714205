import React from 'react';
import PropTypes from 'prop-types';
import FinancesModalTableRow from './FinancesModalTableRow';
import Plug from '../../../plug/plug.component';

import './finances-modal-table.style.scss';

const FinancesModalTable = ({ documents, getPdf }) => {
  return (
    <table className='table-block'>
      <thead className='table-head'>
        <tr className='table-head__row'>
          <td className='table-head__cell'>Дата</td>
          <td className='table-head__cell table-head__cell--center'>Рахунок-договір</td>
          <td className='table-head__cell table-head__cell--center'>Чек</td>
          <td className='table-head__cell table-head__cell--center'>Заняття</td>
          <td className='table-head__cell table-head__cell--center'>Сума</td>
        </tr>
      </thead>
      <tbody className='table-body'>
        {documents.length > 0 ? documents.map((document, idx) => <FinancesModalTableRow key={idx} document={document} getPdf={getPdf} />)
        : <tr className='table-body__row'>
          <td className='table-body__cell' colSpan="5">
            <Plug plug="Даних немає" icon='file-closed' />
          </td>
        </tr>}
      </tbody>
    </table>
  );
};

FinancesModalTable.propTypes = {
  documents: PropTypes.array.isRequired,
  getPdf: PropTypes.func.isRequired,
};

export default FinancesModalTable;