import React from 'react';
import PropTypes from 'prop-types';
import { NavLink, useLocation } from 'react-router-dom';
import Eye from '../../img/icons/eye.svg';
import EyeOff from '../../img/icons/eye-off.svg';
import './field.scss';
import './password.scss';

const FieldPassword = ({name, value, labelText, formClass, ...props}) => {

  const formFieldClass = formClass ? formClass : '';
  const classes = [formFieldClass].filter(Boolean).join(' ');

	const location = useLocation();

  const switchPassword = (e) => {
    const btn = e.target.closest('button');
    const input = btn.previousElementSibling;
    
    if(input.type === 'password') {
        input.type = 'text';
    } else {
        input.type = 'password';
    }

    btn.classList.toggle('show');
  };

  return (
    <div className={`field ${classes}`}>
			<div className='field__top'>
				<label htmlFor={name || ''} className="field__title required">{labelText}</label>
				{location.pathname === '/login' && <NavLink to="/reset/confirm" className="link">Забули пароль?</NavLink>}
			</div>

			<div className="password">
					<input
						id={name || ''} 
						className="field__input password__input" 
						type="password" 
						name={name || ''} 
						value={value || ''}
						{...props} 
					/>
				
					<button className='password__btn' type="button" onClick={switchPassword}>
						<img src={Eye} className="password__icon password__icon--close" alt="eye" width="1" height="1" decoding='async' />
						<img src={EyeOff} className="password__icon password__icon--open" alt="eye" width="1" height="1" decoding='async' />
					</button>
			</div>
    </div>
  );
};

FieldPassword.propTypes = {
	name: PropTypes.string.isRequired,
	value: PropTypes.string.isRequired,
	labelText: PropTypes.string.isRequired,
	formClass: PropTypes.string.isRequired,
};

export default FieldPassword;