import React, { useContext, useEffect, useState } from 'react';
import { LayoutContext } from '../../contexts/layout.context';
import { useNavigate } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import { getPayContractThunk, getPayDetailsThunk, getPayPdfThunk } from '../../store/thunks/finances';
import { authSelectors } from '../../store/selectors/auth';
import { financesSelectors } from '../../store/selectors/finances';
import actions from '../../store/actions';

import Download from '../../img/icons/download.svg';

import Button from '../../common/Buttons/Button';
import Head from '../../components/Head/Head';
import Spinner from '../../common/Spinner/Spinner';
import Alert from '../../common/Alert/Alert';
import Contract from '../../components/FinanceScore/Contract/Contract';
import FinanceScoreCard from '../../components/FinanceScore/FinanceScoreCard/FinanceScoreCard';

const FinanceScore = () => {
  const { setLayout } = useContext(LayoutContext);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [message, setMessage] = useState(false);

  const url = JSON.parse(localStorage.getItem('url'));
  const invoiceId = JSON.parse(localStorage.getItem('invoice_id'));

  const auth = useSelector(authSelectors);
  const finances = useSelector(financesSelectors);
  const token = auth.token;
  const pdf = finances.pdf;
  const details = finances.details;
  const load = finances.load;
  const error = finances.error;
  const paramInvoiceStore = finances.paramInvoice;
  const paramPaymentUuidStore = finances.paramPaymentUuid;

  const textCopied = 'Успішно скопійовано';

  useEffect(() => {
    document.title = 'Реквізити | NewBrain';
    setLayout({ page: 'Реквізити' });
  }, [setLayout]);

  useEffect(() => {
    if((!invoiceId && !paramInvoiceStore) || error) {
      dispatch(actions.finances.getFinancesError(''));
      navigate('/');
    }
  }, []);

  useEffect(() => {
    if(url) {
      dispatch(getPayDetailsThunk({url}));
    }
  }, [url]); 

  useEffect(() => {
    if(pdf) {
      window.open(pdf, '_target');
      dispatch(actions.finances.getPayPdf(''));
    }
  }, [pdf]);

  const goBack = () => {
    navigate('/finance/accounts/pay');
    localStorage.removeItem('url');
  };

  const getPdf = () => {
    let data = '';

    if(paramInvoiceStore && paramPaymentUuidStore) {
      data = {invoice_id: paramInvoiceStore};
    } else {
      data = {invoice_id: invoiceId, token};
    }

    dispatch(getPayPdfThunk(data));
  };

  const getContract = () => {
    let data = '';

    if(paramInvoiceStore && paramPaymentUuidStore) {
      data = {invoice_id: paramInvoiceStore};
    } else {
      data = {invoice_id: invoiceId, token};
    }

    dispatch(getPayContractThunk(data));
  };

  return (
    <div className='finances-pay container'>
      {message && <Alert text={textCopied} message={message} setMessage={setMessage} />}
      <Head textHead='Реквізити' goBack={goBack} />

      {load ? <Spinner /> : <>
        <div className="finances-pay__content">
          <FinanceScoreCard details={details} setMessage={setMessage} />
        </div>

        <div className="finances-pay__btns finances-pay__btns--center">
          <Button buttonBg='green' loading="" icon={Download} iconAlt="download" onClick={getPdf}>
            Завантажити PDF рахунку
          </Button>
          <Button buttonBg='green' loading="" icon={Download} iconAlt="download" onClick={getContract}>
            Завантажити Договір + рахунок
          </Button>
        </div>

        <div className='finances-pay__contract'>
          <Contract text={`${details.invoice_text}`} title={`${details.contract_title}`} />
        </div>
      </>}
    </div>
  );
};

export default FinanceScore;