import types from '../types/index';

const registerRequest = (payload) => ({
  type: types.register.REGISTER_REQUEST,
  payload,
});

const registerSuccess= (payload) => ({
  type: types.register.REGISTER_SUCCESS,
  payload,
});

const registerUser = (payload) => ({
  type: types.register.REGISTER_USER,
  payload,
});

const startFetching = () => ({
  type: types.register.START_FETCHING_REGISTER
});

const stopFetching = () => ({
  type: types.register.STOP_FETCHING_REGISTER,
});

const setRegisterErrors = (payload) => ({
  type: types.register.SET_ERRORS_REGISTER,
  payload,
});

const setRegisterError = (payload) => ({
  type: types.register.SET_ERROR_REGISTER,
  payload,
});

const registerConfirmRequest = (payload) => ({
  type: types.register.REGISTER_REQUEST_CONFIRM,
  payload,
});

const registerCodeSimilarRequest = (payload) => ({
  type: types.register.REGISTER_REQUEST_CODE_SIMILAR,
  payload,
});

const registerCodeNotSimilarRequest = (payload) => ({
  type: types.register.REGISTER_REQUEST_CODE_NOT_SIMILAR,
  payload,
});

const registerCourses = (payload) => ({
  type: types.register.REGISTER_COURSES,
  payload,
});

const registerCategories = (payload) => ({
  type: types.register.REGISTER_CATEGORIES,
  payload,
});

const registerLead = (payload) => ({
  type: types.register.REGISTER_LEAD,
  payload,
});

const registerCommunicationRequest = (payload) => ({
  type: types.register.REGISTER_REQUEST_COMMUNICATION,
  payload,
});
const registerNotMeRequest = (payload) => ({
  type: types.register.REGISTER_REQUEST_NOT_ME,
  payload,
});

const registerNotMeFeedbackRequest = (payload) => ({
  type: types.register.REGISTER_REQUEST_NOT_ME_FEEDBACK,
  payload,
});

const registeredUser = (payload) => ({
  type: types.register.SET_REGISTERED,
  payload,
});

export default {
  registerRequest,
  startFetching,
  stopFetching,
  setRegisterErrors,
  setRegisterError,
  registerConfirmRequest,
  registerCodeSimilarRequest,
  registerCourses,
  registerCategories,
  registerLead,
  registeredUser,
  registerCodeNotSimilarRequest,
  registerCommunicationRequest,
  registerSuccess,
  registerUser,
  registerNotMeRequest,
  registerNotMeFeedbackRequest,
};