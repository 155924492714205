import React, { useEffect } from 'react';
import HeaderAuth from '../../components/HeaderAuth/HeaderAuth';
import RegisterCommunication from '../../components/Registration/RegisterCommunication/RegisterCommunication';

const RegisterCommunicationPage = () =>  {
  useEffect(() => {
    document.body.removeAttribute('class');
    document.title = 'Альтернативний звʼязок | NewBrain';
  }, []);

  return (
    <div>
      <HeaderAuth />
      <section className='container container--login'>
        <RegisterCommunication />
      </section>
    </div>
  );
};

export default RegisterCommunicationPage;