import React, { useEffect, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { resetErrors } from '../data';

import actions from '../../../store/actions';
import { registerSelectors } from '../../../store/selectors/register';
import { getRegisterCommunicationThunk } from '../../../store/thunks/register';

import ErrorMessage from '../../../common/Errors/ErrorMessage';
import ErrorField from '../../../common/Errors/ErrorField';
import LoadingBtn from '../../LoadingBtn/LoadingBtn';
import Field from '../../../common/Fields/Field';

const RegisterCommunicationForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [nickname, setNickname] = useState('');
  const [facebook, setFacebook] = useState('');
  const [userData] = useState(JSON.parse(localStorage.getItem('user')));
  const [phoneData] = useState(JSON.parse(localStorage.getItem('phone')));

  const register = useSelector(registerSelectors);
  const errors = register.errors;
  const error = register.error;
  const socials = register.socials;
  const token = register.token;
  const user = register.user;
  const load = register.load;
  const phone = register.phone;

  useEffect(() => {
    if(!user && userData) {
      dispatch(actions.register.registerUser(userData));
    }

    if(!phone && phoneData) {
      dispatch(actions.register.registerRequest(phoneData));
    }

    if(!token && user) {
      dispatch(actions.register.registerSuccess(user.token));
    }
  }, []);

  useEffect(() => {
    if(socials) {
      navigate('/registration/wait', {replace: true});
      resetErrors(dispatch, actions.register);
      dispatch(actions.register.registerCommunicationRequest(false));
    }
  }, [socials]);

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = {
      token,
      socials: {
        nickname, facebook, phone,
      }
    };
    resetErrors(dispatch, actions.register);
    dispatch(getRegisterCommunicationThunk(data));
  };

  return (
    <form className="auth__form form" onSubmit={handleSubmit}>
      <>
        <Field 
          name="nickname" 
          type="text"
          value={nickname} 
          labelText="Нікнейм Instagram" 
          onChange={(e) => setNickname(e.target.value.trim())} 
          formClass=""
        />
        {errors?.nickname && <ErrorField errorText={errors.nickname} />}
      </>

      <>
        <Field 
          name="facebook" 
          type="text"
          value={facebook} 
          labelText="Посилання на проофіль Facebook" 
          onChange={(e) => setFacebook(e.target.value.trim())} 
          formClass="form__field"
        />
        {errors?.facebook && <ErrorField errorText={errors.facebook} />}
      </>

      {errors?.phone && <ErrorMessage errorText={errors.phone} />}
      {(error && !errors)  && <ErrorMessage errorText={error} />}

      <div className="auth__btns">
        <LoadingBtn load={load} btnText='Надіслати' />
        <div className="auth__text">
          <NavLink to="/registration/wait" className="link">Пропустити</NavLink>
        </div>
      </div>
    </form>
  );
};

export default RegisterCommunicationForm;
