import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import sprite from '../../../../img/sprites.svg';

import { openToggle, toggle } from '../../../../utils/accordion';

import ProgramsCard from '../ProgramsCard/ProgramsCard';
import ProgramsTable from '../ProgramsTable/ProgramsTable';

const ProgramsBlock = ({
  title,
  courses
}) => {

  useEffect(() => {
    const block = document.querySelectorAll('[data-block="toggle"]');
    block.forEach(block => {
      openToggle(block);
    });
  }, []);

  const handleToggle = (target) => {
    const btn = target.closest('[data-top="toggle"]');
    const block = target.closest('[data-block="toggle"]');

    if(btn) {
      toggle(block);
    }
  };

  return (
    <div className="page-block open" data-block="toggle">  
      <button className="page-block-head" data-top="toggle" onClick={(e) => handleToggle(e.target)} >
        <h3 className='page-block-head__title'>{title}</h3>
        <svg className="icon"><use href={`${sprite}#arrow`}></use></svg>
      </button>

      <div className="page-block__content" data-content="toggle">

        <div className="page__cards cards">
          {courses.length > 0 && courses.map(course => <ProgramsCard key={course.program_id} course={course}/>)}
        </div>

        <div className="page__table table">
          {courses.length > 0 && <ProgramsTable courses={courses} />}
        </div>
      </div>
    </div>
  );
};

ProgramsBlock.propTypes = {
  courses: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
};

export default ProgramsBlock;