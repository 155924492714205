import types from '../types/index';

const initialState = {
  code: false,
  wait: true,
  errors: {},
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case types.code.REGISTER_CODE_REQUEST:
      return {
        ...state, code: payload
      };
    case types.code.REGISTER_CODE_ERRORS:
      return {
        ...state, errors: payload
      };
    case types.code.RESET_PASSWORD_CODE_REQUEST:
      return {
        ...state, code: payload
      };
    case types.code.RESET_PASSWORD_CODE_ERRORS:
      return {
        ...state, errors: payload
      };
    case types.code.SEND_CODE_AGAIN:
      return {
        ...state, wait: false
      };
    default: {
      return state;
    }
  }
};

export default reducer;