import React, { useEffect, useState } from 'react';
import LoadingBtn from '../../../LoadingBtn/LoadingBtn';
import FieldTimer from '../../../../common/Fields/FieldTimer';
import { useDispatch, useSelector } from 'react-redux';
import { profileSelectors } from '../../../../store/selectors/profile';
import ErrorMessage from '../../../../common/Errors/ErrorMessage';
import { useNavigate } from 'react-router-dom';
import { editPhoneCodeThunk } from '../../../../store/thunks/profile';
import { authSelectors } from '../../../../store/selectors/auth';
import actions from '../../../../store/actions';

const PhoneConfirm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const profile = useSelector(profileSelectors);
  const auth = useSelector(authSelectors);
  const token = auth.token;
  const errors = profile.errors;
  const load = profile.load;
  const edit = profile.edit;

  const [codeValue, setCodeValue] = useState('');

  const phoneData = JSON.parse(localStorage.getItem('phone'));

  useEffect(() => {
    if(edit) {
      navigate('/profile');
      dispatch(actions.profile.editUserPhone(''));
      dispatch(actions.profile.editUserInfo(false));
      dispatch(actions.profile.profileError({}));
    }
  }, [edit]);

  const handleEdit = (e) => {
    e.preventDefault();
    const data = {phone: phoneData, code: codeValue, token};
    dispatch(editPhoneCodeThunk(data));
  };
  
  return (
    <form className='form profile-form'>
      <FieldTimer 
        name="code" 
        type="text"
        value={codeValue} 
        labelText="Код" 
        onChange={(e) => setCodeValue(e.target.value.trim())} 
        formClass="required"
        required
      />
      {errors?.code &&  <ErrorMessage errorText={errors.code} />}

      <div className="profile__btns">
        <LoadingBtn load={load} btnText='Змінити' onClick={handleEdit} />
      </div>
    </form>
  );
};

export default PhoneConfirm;