import React from 'react';
import PropTypes from 'prop-types';
import { colors, statuses } from '../data';
import Button from '../../../common/Buttons/Button';
import CopyPayment from '../../CopyPayment/CopyPayment';
import sprite from '../../../img/sprites.svg';

const FinanceAccountsCard = ({ count, setOpenModal, getPdf }) => {


  const actual = count.show_button_pay === true && !count.total_price;
  const payed = count.show_button_pay == false && count.total_price;
  const notActual = count.show_button_pay === false && !count.total_price;

  const setLink = (document_url) => {
    window.open(document_url, '_blank');
  };

  const setModalData = (id, url, name) => {
    setOpenModal({'open': true, id, url, name});
    document.body.classList.add('open');
  };

  return (
    <div className='card-pay'>
      <div className="card-pay__row">
        <span className='card-pay__date'>{count.created_at}</span>
        {count.total_price && <span className='card-pay__sum green'>{count.total_price} грн</span>}
      </div>
      <div className="card-pay__row">
        <div className="course-name">
          <img src={count.image} alt={count.nomenclature_name} width="1" height="1" decoding='async' />
          {count.nomenclature_name}
        </div>
      </div>

      <div className="card-pay__row card-pay__row--top">
        <span className='card-pay-text card-pay-text--top'>Рахунок-договір:</span>
        <span className='card-pay-text card-pay-text--wrap'>
          <button className='link' onClick={() => setLink(count.document_url)}>{count.document_name}</button>
          {count.show_document_url 
          ? <button className='btn-download' onClick={() => getPdf(count.id)}>
              <svg className="icon"><use href={`${sprite}#download`}></use></svg>
            </button>
          : ''}
          {count.show_button_pay ?
            <CopyPayment
              validTime={count.valid_seconds}
              id={count.id}
              url={count.payment_url}
            />
          : ''}
        </span>
      </div>

      {count.total_price && <>
        <div className="card-pay__row">
          <span className='card-pay-text'>Заняття:</span>
          <span className='card-pay-text'>{count.qty}</span>
        </div>

        <div className="card-pay__row">
          <span className='card-pay-text'>Ціна одного заняття:</span>
          <span className='card-pay-text'>{count.price} грн</span>
        </div>
      </>}

      <div className="card-pay__row">
        <span className='card-pay-text'>Статус:</span>

        <span className={`card-pay-text ${colors(actual, payed, notActual)}`}>
          {statuses(actual, payed, notActual)}
        </span>
      </div>
      {(count.checkbox_url && payed ) &&
      <div className="card-pay__row card-pay__row--end">
        <button className='card-pay-text' onClick={() => window.open(count.checkbox_url, '_blank')}>
          <span className="check-icon">
            <svg className="icon"><use href={`${sprite}#check_list`}></use></svg>
          </span>
          <div className="link">Чек</div>
        </button>
      </div>}

      {count.show_button_pay &&
        <Button 
          buttonBg="green" 
          loading=""
          onClick={() => setModalData(count.id, count.document_url, count.document_name)}
      >Оплатити</Button>}
    </div>
  );
};

FinanceAccountsCard.propTypes = {
  count: PropTypes.object.isRequired,
  setOpenModal: PropTypes.func.isRequired,
  getPdf: PropTypes.func.isRequired,
};

export default FinanceAccountsCard;