import types from '../types/index';

const initialState = {
  token: '',
  phone: '',
  phoneConfirm: '',
  courses: [],
  categories: [],
  user: {},
  successCode: false,
  successCodeSimilar: false,
  socials: false,
  notSimilar: false,
  feedback: false,
  registered: false,
  load: false,
  errors: {},
  error: '',
  lead: false,
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case types.register.REGISTER_REQUEST:
      return {
        ...state, phone: payload,
      };
    case types.register.REGISTER_SUCCESS: 
      return {
        ...state, token: payload
      };
    case types.register.REGISTER_USER: 
      return {
        ...state, user: payload
      };
    case types.register.START_FETCHING_REGISTER:
      return {
        ...state, load: true,
      };
    case types.register.STOP_FETCHING_REGISTER:
      return {
        ...state, load: false
      };
    case types.register.SET_ERRORS_REGISTER:
      return {
        ...state, errors: payload
      };
    case types.register.SET_ERROR_REGISTER:
      return {
        ...state, error: payload
      };
    case types.register.REGISTER_REQUEST_CONFIRM:
      return {
        ...state, phoneConfirm: payload
      };
    case types.register.REGISTER_REQUEST_CODE_NOT_SIMILAR: 
      return {
        ...state, successCode: payload
      };
    case types.register.REGISTER_REQUEST_CODE_SIMILAR: 
      return {
        ...state, successCodeSimilar: payload
      };
    case types.register.REGISTER_COURSES:
      return {
        ...state, courses: payload
      };
    case types.register.REGISTER_CATEGORIES:
      return {
        ...state, categories: payload
      };
    case types.register.REGISTER_LEAD: 
      return {
        ...state, lead: payload
      };
    case types.register.REGISTER_REQUEST_COMMUNICATION: 
      return {
        ...state, socials: payload
      };
    case types.register.REGISTER_REQUEST_NOT_ME:
      return {
        ...state, notSimilar: payload
      };
    case types.register.REGISTER_REQUEST_NOT_ME_FEEDBACK: 
      return {
        ...state, feedback: payload
      };
    case types.register.SET_REGISTERED:
      return {
        ...state, registered: payload
      };
    default: {
      return state;
    }
  }
};

export default reducer;