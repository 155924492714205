import React, { useContext, useEffect } from 'react';
import { LayoutContext } from '../../contexts/layout.context';
import { useNavigate } from 'react-router-dom';
import Button from '../../common/Buttons/Button';

const PaymentFailed = () => {
  const { setLayout } = useContext(LayoutContext);
  const navigate = useNavigate();

  useEffect(() => {
    document.title = 'Оплата | NewBrain';
    setLayout({ page: 'Оплата' });
  }, [setLayout]);

  useEffect(() => {
    let time = 2;
    const timer = setInterval(() => {
      if(time === 0) {
        navigate('/');
      }
      --time;
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const handlePage = () => {
    navigate('/');
  };

  return (
    <div className='finances-message container'>
      <div className="finances__message finances__message--red">
        На жаль, оплата не успішна (( <br></br>
        Не здавайтеся, спробуйте ще раз або оберіть інший спосіб!
      </div>

      <div className="finances__btn">
        <Button buttonBg="violet" onClick={handlePage} >Далі</Button>
      </div>
    </div>
  );
};

export default PaymentFailed;