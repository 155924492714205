import types from '../types/index';

const completedPrograms = (payload) => ({
  type: types.programs.COMPLETED_PROGRAMS,
  payload,
});

const studyPrograms = (payload) => ({
  type: types.programs.STUDY_PROGRAMS,
  payload,
});

const courseProgramLessons = (payload) => ({
  type: types.programs.COURSE_PROGRAM_LESSONS,
  payload,
});

const courseProgramDetails = (payload) => ({
  type: types.programs.COURSE_PROGRAM_DETAILS,
  payload,
});

const startFetching = () => ({
  type: types.profile.START_FETCHING_PROFILE
});

const stopFetching = () => ({
  type: types.profile.STOP_FETCHING_PROFILE,
});

export default {
  completedPrograms,
  studyPrograms,
  courseProgramLessons,
  courseProgramDetails,
  startFetching,
  stopFetching,
};