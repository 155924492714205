import { setTimeFromSeconds } from './time';

export const timer = (btn, btnAgain, timerBlock) => {
    let time = 120;

    const timer = setInterval(() => {
        const {minutes, seconds} = setTimeFromSeconds(time);

        if(time > 0) {
            btn.classList.remove('wait');
            btnAgain.setAttribute('disabled', true);
            timerBlock.innerHTML = `${minutes}:${seconds}`;
        }

        if(time === 120) {
            timerBlock.innerHTML = `${minutes}:${seconds}0`;
        }

        if(time < 70 && time >= 60 ) {
            timerBlock.innerHTML = `${minutes}:0${seconds}`;
        }

        if(time < 10) {
            timerBlock.innerHTML = `0:0${seconds}`;
        }

        if(time < 1) {
            btn.classList.add('wait');
            btnAgain.removeAttribute('disabled');
            clearInterval(timer);
        }

        --time;
    }, 1000);
};