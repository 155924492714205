import React from 'react';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { authSelectors } from '../../store/selectors/auth';
import { financesSelectors } from '../../store/selectors/finances';
import { getToken } from '../../utils/token';
import actions from '../../store/actions';

function ProtectedRoute({ children, isAuth, isRegister }) {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const auth = useSelector(authSelectors);
  const finances = useSelector(financesSelectors);
  const status = auth.status;
  const token = auth.token;
  const paramInvoice = finances.paramInvoice;
  const paramPaymentUuid = finances.paramPaymentUuid;
  
  const tokenData = JSON.parse(localStorage.getItem('token'));
  const phone = JSON.parse(localStorage.getItem('phone'));
  const invoiceId = JSON.parse(localStorage.getItem('invoiceId'));

  const financesPages = ['/finance/accounts/pay', '/finance/accounts/pay/scores', '/finance/accounts/pay/message', '/payment/failed', '/payment/success'];

  getToken(tokenData);

  useEffect(() => {
    if (tokenData && isAuth === 'no') {
      navigate('/');
    }

    if(tokenData && isAuth === 'yes' && financesPages.includes(location.pathname) && (invoiceId || paramInvoice)) {
      navigate(location.pathname);
    }

    if (!tokenData && isAuth === 'yes') {
      if(financesPages.includes(location.pathname) && paramInvoice && paramPaymentUuid) {
        navigate(location.pathname);
      } else {
        navigate('/login');
        dispatch(actions.auth.loginRequest(null));
        localStorage.clear();
      }
    }

    if(tokenData !== token && token) {
      navigate('/login');
      dispatch(actions.auth.loginRequest(null));
      localStorage.clear();
    }
    
    if(!phone && isRegister === 'no' && isAuth === 'no') {
      if(location.pathname === '/login/crm') {
        navigate('/login/crm');
      } else {
        navigate('/registration');
      }
    }

  }, [isAuth, tokenData, token, isRegister, status, phone, paramInvoice, invoiceId, paramPaymentUuid]);

  return <>{children}</>;
}

ProtectedRoute.propTypes = {
  isAuth: PropTypes.string,
  isRegister: PropTypes.string,
  children: PropTypes.object
};

export default ProtectedRoute;