import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const Unauthorized = () => {
  /* Init Page */
  useEffect(() => {
    document.body.removeAttribute('class');
    document.body.classList.add('unauthorized');
    document.title = 'Unauthorized | NewBrain';
  }, []);

  const navigate = useNavigate();
  const goBack = () => navigate(-1);

  return (
    <div className='unauthorized-container'>
      <h1>Unauthorized</h1>
      <p>You do not have access to the requested page</p>
      <button onClick={goBack}>Go back</button>
    </div>
  );
};

export default Unauthorized;