import React from 'react';
import PropTypes from 'prop-types';
import sprite from '../../img/sprites.svg';
import './plug.style.scss';

const Plug = ({ plug, icon }) => {
  return (
    <div className='plug'>
      <svg className="plug__icon icon"><use href={`${sprite}#${icon}`}></use></svg>
      {plug}
    </div>
  );
};

Plug.propTypes = {
  plug: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired
};

export default Plug;