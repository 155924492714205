import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import actions from '../../../store/actions';
import { registerSelectors } from '../../../store/selectors/register';
import { authSelectors } from '../../../store/selectors/auth';

import Button from '../../../common/Buttons/Button';
import TitleAuth from '../../../common/Titles/TitleAuth';

import '../../Login/auth.scss';

const RegisterWait = ({ title, description, textBtn, btnLink, type }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const register = useSelector(registerSelectors);
  const auth = useSelector(authSelectors);
  const token = auth.token;
  const feedback = register.feedback;
  const registered = register.registered;

  useEffect(() => {
    localStorage.removeItem('courses');
    localStorage.removeItem('phone');
    dispatch(actions.register.registerRequest(''));
    dispatch(actions.register.registerConfirmRequest(''));
    dispatch(actions.register.registerCourses([]));
    dispatch(actions.register.registerCategories([]));
    dispatch(actions.register.registerUser({}));
    dispatch(actions.auth.loginRequest(''));
    dispatch(actions.register.registerSuccess(''));
  }, []);

  useEffect(() => {
    if(token) {
      localStorage.removeItem('registerToken');
      localStorage.removeItem('user');
      dispatch(actions.register.registerUser({}));
      dispatch(actions.register.registerSuccess(''));
      navigate(btnLink);
    }
  }, [token]);

  useEffect(() => {
    let time = 1;
    let timer = setInterval(() => {
      if(time === 0) {
        if(type === 'registration') {
          const registerToken = JSON.parse(localStorage.getItem('registerToken'));
          const userRegister = JSON.parse(localStorage.getItem('user'));

          if(registerToken && userRegister) {
            localStorage.setItem('token', JSON.stringify(registerToken));
            dispatch(actions.auth.loginRequest(registerToken));
            dispatch(actions.auth.getUserRequest(userRegister));
          } 
        }
        clearInterval(timer);
      }

      --time;
    }, 1000);

    () => clearInterval(timer);
  }, [type]);


  const setLoginPage = () => {
    if(feedback) {
      navigate(btnLink);
      dispatch(actions.register.registerNotMeFeedbackRequest(false));
      dispatch(actions.register.registerNotMeRequest(false));
    }

    if(registered) {
      navigate(btnLink);
      dispatch(actions.register.registeredUser(false));
    }
  };

  return (
    <div className="auth">
      <div className={`auth__wrapper ${(registered || feedback) ? '' : 'auth__wrapper--full'}`}> 
        <TitleAuth titleText={title} centering={(registered || feedback) ? '' : 'center'} />
        {(registered || feedback) && <>
          <div className="auth__subtitle">{description}</div>
          <div className="auth__btns">
            <Button buttonBg="violet" onClick={setLoginPage} loading="">{textBtn}</Button>
          </div>
        </>}
      </div>
    </div>
  );
};

RegisterWait.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  textBtn: PropTypes.string.isRequired,
  btnLink: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired
};

export default RegisterWait;
