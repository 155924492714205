import React from 'react';
import PropTypes from 'prop-types';

import { image } from '../constants';

import './course-check.style.scss';

const CourseCheck = ({ item, value, name, type, ...props }) => {
  return (
    <div className='course-check'>
      <input type={type} name={name} value={value} id={name} {...props} />
      <label className='course-check-content' htmlFor={name} >
        {image[item.index] && <img src={image[item.index]} alt={item.title} width='1' height='1' />}
        <span>{item.title}</span>
      </label>
    </div>
  );
};

CourseCheck.propTypes = {
  item: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired
};

export default CourseCheck;