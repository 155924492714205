import React from 'react';
import PropTypes from 'prop-types';

import EventModalTableRow from './EventModalTableRow';

const EventModalTable = ({ events }) => {
  return (
    <table className='table-block'>
      <thead className='table-head'>
        <tr className='table-head__row'>
          <td className='table-head__cell'>Назва курсу</td>
          <td className='table-head__cell table-head__cell--center'>Час</td>
          <td className='table-head__cell table-head__cell--center'>Виклачач</td>
          <td className='table-head__cell table-head__cell--center'>Статус</td>
        </tr>
      </thead>
      <tbody className='table-body'>
        {events.map(event => <EventModalTableRow key={event.id} event={event} />)}
      </tbody>
    </table>
  );
};

EventModalTable.propTypes = {
  events: PropTypes.array.isRequired,
};

export default EventModalTable;