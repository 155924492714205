import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { profileSelectors } from '../../../../store/selectors/profile';
import { editUserInfoThunk } from '../../../../store/thunks/profile';
import { authSelectors } from '../../../../store/selectors/auth';
import actions from '../../../../store/actions';

import LoadingBtn from '../../../LoadingBtn/LoadingBtn';
import Field from '../../../../common/Fields/Field';
import ErrorMessage from '../../../../common/Errors/ErrorMessage';

const NameForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const profile = useSelector(profileSelectors);
  const auth = useSelector(authSelectors);
  const token = auth.token;
  const user = profile.user;
  const errors = profile.errors;
  const edit = profile.edit;
  const load = profile.load;
  const confirmInfo = profile.confirmInfo;
  
  const [firstName, setFirstName] = useState('');
  const [fieldConfirmed, setFieldConfirmed] = useState('');

  useEffect(() => {
    if(edit) {
      navigate('/profile');
      dispatch(actions.profile.editUserInfo(false));
      dispatch(actions.profile.profileError({}));
    }
  }, [edit]);

  useEffect(() => {
    if(Object.keys(user).length > 0) {
      setFirstName(user.first_name);
    }
  }, [user]);

  useEffect(() => {
    if(confirmInfo.length > 0) {
      setFieldConfirmed(confirmInfo.find(field => field.field === 'mid_name'));
    }
  }, [confirmInfo]);

  useEffect(() => {
    if(fieldConfirmed) {
      setFirstName(fieldConfirmed.value_to);
    }
  }, [fieldConfirmed]);

  const handleEdit= (e) => {
    e.preventDefault();
    dispatch(editUserInfoThunk({value: firstName, field: 'first_name', token}));
  };

  return (
    <form className='form profile-form'>
      <>
        <Field 
          name="fisrt_name" 
          type="text"
          value={firstName} 
          labelText="Ім'я" 
          onChange={(e) => setFirstName(e.target.value.trim())} 
          formClass="required"
          required
        />
        {errors?.value &&  errors.value.map(err => <ErrorMessage key={err} errorText={err} />)}
      </>

      <div className="profile__btns">
        <LoadingBtn load={load} btnText='Змінити' onClick={handleEdit} />
      </div>
    </form>
  );
};

export default NameForm;