import React from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';

import sprite from '../../../../img/sprites.svg';

import './programs-link.style.scss';

const ProgramsLink = ({ count, link, ...props }) => {
  return (
    <NavLink to={link} className="programs-link" {...props}> 
      <svg className="icon"><use href={`${sprite}#doc`}></use></svg>
      <span className="link">{count}</span>
    </NavLink>
  );
};

ProgramsLink.propTypes = {
  count: PropTypes.number.isRequired,
  link: PropTypes.string.isRequired
};

export default ProgramsLink;