import React from 'react';
import PropTypes from 'prop-types';

import './course-name.style.scss';

const CourseName = ({ img, name, level, isViolet, isCenter, label }) => {

  const violetClass = isViolet ? 'course-name--bg-violet' : '';
  const centerClass = isCenter ? 'course-name--center' : '';

  const classes = [violetClass, centerClass].filter(Boolean).join(' ');

  return (
    <div className={`course-name ${classes}`}>
      <img src={img} alt={name} width="1" height="1" decoding="async" />
      <div>
        <h3 className='course-name__title'>{name}</h3>
        {level && <p className="course-name__subtitle">{level}</p>}
      </div>
      {label && <span className='label label--violet'>{label}</span>}
    </div>
  );
};

CourseName.propTypes = {
  img: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  level: PropTypes.string,
  isViolet: PropTypes.bool,
  isCenter: PropTypes.bool,
  label: PropTypes.any,
};

export default CourseName;