import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { profileSelectors } from '../../../../store/selectors/profile';
import { authSelectors } from '../../../../store/selectors/auth';
import { editUserInfoThunk } from '../../../../store/thunks/profile';
import actions from '../../../../store/actions';

import FieldPhone from '../../../../common/Fields/FieldPhone';
import LoadingBtn from '../../../LoadingBtn/LoadingBtn';
import ErrorMessage from '../../../../common/Errors/ErrorMessage';

const PhoneForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const profile = useSelector(profileSelectors);
  const auth = useSelector(authSelectors);
  const token = auth.token;
  const user = profile.user;
  const errors = profile.errors;
  const load = profile.load;
  const phoneRes = profile.phone;
  const edit = profile.edit;
  const confirmInfo = profile.confirmInfo;

  const [phone, setPhone] = useState('');
  const [fieldConfirmed, setFieldConfirmed] = useState('');

  useEffect(() => {
    if(Object.keys(user).length > 0) {
      setPhone(user.phone);
    }
  }, [user]);

  useEffect(() => {
    if(phoneRes && edit) {
      navigate('/profile/edit/phone/confirm');
      dispatch(actions.profile.editUserInfo(false));
      dispatch(actions.profile.profileError({}));
    }
  }, [phoneRes, edit]);

  useEffect(() => {
    if(confirmInfo.length > 0) {
      setFieldConfirmed(confirmInfo.find(field => field.field === 'mid_name'));
    }
  }, [confirmInfo]);

  useEffect(() => {
    if(fieldConfirmed) {
      setPhone(fieldConfirmed.value_to);
    }
  }, [fieldConfirmed]);

  const handleEdit = (e) => {
    e.preventDefault();
    dispatch(editUserInfoThunk({value: `+${phone}`, field: 'phone', token}));
  };

  return (
    <form className='form profile-form' onSubmit={handleEdit}>
      <>
        <FieldPhone 
          name="phone" 
          type="tel" 
          valuePhone={phone} 
          onChange={setPhone} 
          labelText="Номер телефону"
          labelMessage="(з приєднаним Telegram)"
          formClass="required"
          required
        />
        {errors?.value &&  errors.value.map(err => <ErrorMessage key={err} errorText={err} />)}
      </>

      <div className="profile__btns">
        <LoadingBtn load={load} btnText='Змінити' />
      </div>
    </form>
  );
};

export default PhoneForm;