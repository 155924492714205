import React from 'react';
import PropTypes from 'prop-types';

import sprite from '../../../../img/sprites.svg';

import './program-item.style.scss';

const CourseProgramItem = ({ item }) => {

  const borderColor = (status) => {
    if (status === 1) {
      return 'br-green';
    }

    if (status === 2) {
      return 'br-yellow';
    }

    if (status === 3) {
      return 'br-red';
    }

    return '';
  };

  return (
    <div className='program-item'>
      <div className="program-item-content">
        <div className={`program-item__count ${borderColor(item.attendance_status)}`}>{item.order + 1}</div>

        <div className="program-item__details">
          <h3 className="program-item__title">{item.topic || '-'}</h3>
          <h3 className="program-item__subtitle">{item.date || '-'}</h3>
        </div>

        <div className="program-item__buttons">
          {item.is_show_words && (
            <button
              className="program-item__btn"
              onClick={() => window.open(item.words_url, '_blank')}
            >
              <svg className="program-item__icon"><use href={`${sprite}#dictionary`}></use></svg>
              <svg className="icon program-item__arrow"><use href={`${sprite}#arrow`}></use></svg>
            </button>
          )}

          {item.presentation && <button
            className="program-item__btn"
            onClick={() => window.open(item.presentation, '_blank')}
          >
            <svg className="program-item__icon"><use href={`${sprite}#monitor`}></use></svg>
            <svg className="icon program-item__arrow"><use href={`${sprite}#arrow`}></use></svg>
          </button>}
        </div>
      </div>
    </div>
  );
};

CourseProgramItem.propTypes = {
  item: PropTypes.object.isRequired,
};

export default CourseProgramItem;
