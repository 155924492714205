import React, { useContext, useEffect } from 'react';
import { LayoutContext } from '../../../contexts/layout.context';

import { useDispatch, useSelector } from 'react-redux';
import { getProgramsInfo } from '../../../store/thunks/programs';
import { authSelectors } from '../../../store/selectors/auth';
import { programsSelectors } from '../../../store/selectors/programs';

import Plug from '../../../components/plug/plug.component';
import ProgramsBlock from '../../../components/Programs/Programs/ProgramsBlock/ProgramsBlock';
import Spinner from '../../../common/Spinner/Spinner';

const Programs = () => {
  const { setLayout } = useContext(LayoutContext);
  const dispatch = useDispatch();

  const auth = useSelector(authSelectors);
  const programs = useSelector(programsSelectors);
  const token = auth.token;
  const completedPrograms = programs.completedPrograms;
  const studyPrograms = programs.studyPrograms;
  const load = programs.load;

  useEffect(() => {
    document.title = 'Курси | NewBrain';
    setLayout({ page: 'Курси' });
  }, [setLayout]);

  useEffect(() => {
    if(token) {
      dispatch(getProgramsInfo(token));
    }
  }, [token]);

  return (
    <section className="page programs container">

    {load ? <Spinner /> : (      
      (studyPrograms.length > 0 || completedPrograms.length > 0 )
      ? (<div className="page-blocks">

          {studyPrograms.length > 0 && <ProgramsBlock
            title='В навчанні'
            courses={studyPrograms} 
          />}

          {completedPrograms.length > 0 && <ProgramsBlock 
            title='Завершені'
            courses={completedPrograms} 
          />}
          
          </div>)

        : <Plug plug="Дані про курси відсутні!" icon="icon-closed" />)
      }
    </section>
  );
};

export default Programs;