import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { getLogoutThunk } from '../../store/thunks/auth';
import { authSelectors } from '../../store/selectors/auth';

import { getToken } from '../../utils/token';

import sprite from '../../img/sprites.svg';

const Exit = ({ link }) => {
  const dispatch = useDispatch();
  
  const auth = useSelector(authSelectors);
  const token = auth.token;

  getToken(token);

  const setLogout = () => {
    if(token) {
      dispatch(getLogoutThunk(token));
    }
  };

  const linkClass = link ? link : '';
  const textClasses = [linkClass].filter(Boolean).join(' ');

  return (
    <button className="exit" onClick={setLogout}>
      <svg className="icon"><use href={`${sprite}#exit`}></use></svg>
      <span className={`exit__text ${textClasses}`}>Вийти</span>
    </button>
  );
};

Exit.propTypes = {
  link: PropTypes.string.isRequired,
};

export default Exit;