import React from 'react';
import PropTypes from 'prop-types';

import './progress.style.scss';

const Progress = ({ progress }) => {
  return (
    <div className="progress">
      <div className="progress-block">
        <span className="progress-block__line" style={{width: `${progress}%`}}></span>
      </div>
      <span className='progress__count'>{progress}%</span>
    </div>
  );
};

Progress.propTypes = {
  progress: PropTypes.number.isRequired,
};

export default Progress;