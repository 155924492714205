import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { generalSelectors } from '../../store/selectors/general';
import actions from '../../store/actions';

import Button from '../../common/Buttons/Button';

import './general-modal.style.scss';

const GeneralModalInfo = ({ setOpenModalInfo, openModalInfo }) => {
    const dispatch = useDispatch();

  const general = useSelector(generalSelectors);
  const message = general.message;

  const closeModal = () => {
    setOpenModalInfo(false);
    dispatch(actions.general.setMessage(''));
  };

  return (
    <div className={`modal-info modal-general ${openModalInfo ? 'open' : ''}`}>
      <div className="overlay" onClick={closeModal}></div>
      <div className="modal-info__container modal-general__container">
        <div className="modal-info__content modal-info__content--gap">
          <h2 className="modal-info__text">{message}</h2>

          <div className="modal-general__btns">
            <Button buttonBg='green' loading="" onClick={closeModal}>Закрити</Button>
          </div>
        </div>
      </div>
    </div>
  );
};

GeneralModalInfo.propTypes = {
  setOpenModalInfo: PropTypes.func.isRequired,
  openModalInfo: PropTypes.bool.isRequired,
};

export default GeneralModalInfo;