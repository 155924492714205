import React from 'react';
import PropTypes from 'prop-types';
import { colorStatuses, setStatusName } from '../data';

import './card-event.styles.scss';

const EventModalCard = ({ card }) => {
  return (
    <div className={`card-event ${colorStatuses(card)}`}>

      <div className="card-event-top">
        <div className="card-event-lesson">
          <img src={card.image} alt={card.direction} width="1" height="1" decoding='async' />
          <h3>{card.direction} мова {card.catch_up_event_id && '(відпрацювання)'}</h3>
        </div>

        <div className="card-event-time">{card.hour}:00</div>
      </div>

      <div className="card-event__middle">
        <span className='card-event-text'>Статус: {setStatusName(card)}</span>

        {/* {card.type === 1 && 
          <div className="card-event-test">
            <svg className="icon"><use href={`${sprite}#list-check`}></use></svg>
            <span>Тест</span>
        </div>} */}
      </div>

      <div className="card-event__bottom">
        <span className='card-event-text'>Викладач: {card.teacher_fname}</span>
      </div>
    </div>
  );
};

EventModalCard.propTypes = {
  card: PropTypes.object.isRequired,
};

export default EventModalCard;