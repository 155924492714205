import React, { useEffect } from 'react';
import RegisterCode from '../../components/Registration/RegisterCode/RegisterCode';
import HeaderAuth from '../../components/HeaderAuth/HeaderAuth';

const RegisterCodePage = () =>  {
    useEffect(() => {
        document.body.removeAttribute('class');
        document.title = 'Підтвердження номера | NewBrain';
    }, []);

  return (
    <div>
      <HeaderAuth />
      <section className='container container--login'>
        <RegisterCode />
      </section>
    </div>
  );
};

export default RegisterCodePage;