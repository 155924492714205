import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { getChoosenDirectionThunk } from '../../store/thunks/general';
import { authSelectors } from '../../store/selectors/auth';
import { generalSelectors } from '../../store/selectors/general';

import Button from '../../common/Buttons/Button';

import './general-modal.style.scss';
import actions from '../../store/actions';

const GeneralModal = ({ 
  setOpenModal, 
  openModal, 
  course, 
  setOpenModalInfo 
}) => {
  const dispatch = useDispatch();

  const auth = useSelector(authSelectors);
  const general = useSelector(generalSelectors);
  const token = auth.token;
  const success = general.success;

  useEffect(() => {
    if(success) {
      setOpenModal(false);
      dispatch(actions.general.setChoosenDirectionSuccess(false));
      setOpenModalInfo(true);
    }
  }, [success]);

  const closeModal = () => {
    setOpenModal(false);
  };

  const handleConfirm = (id) => {
    if(token) {
      dispatch(getChoosenDirectionThunk({direction_id: id, token}));
    }
  };
  return (
    <div className={`modal-info modal-general ${openModal ? 'open' : ''}`}>
      <div className="overlay" onClick={closeModal}></div>
      <div className="modal-info__container modal-general__container">
        <div className="modal-info__content modal-info__content--gap">
          <h2 className="modal-general__title">
            Ви дійсно хочете записатися на безкоштовне пробне заняття з напряму <span>{course.title}</span>?
          </h2>
          <div className="modal-general__btns">
            <Button buttonBg='violet' loading="" onClick={() => handleConfirm(course.id)}>Так</Button>
            <Button buttonBg='white' loading="" onClick={closeModal}>Ні</Button>
          </div>
        </div>
      </div>
    </div>
  );
};

GeneralModal.propTypes = {
  setOpenModal: PropTypes.func.isRequired,
  openModal: PropTypes.bool.isRequired,
  course: PropTypes.object.isRequired,
  setOpenModalInfo: PropTypes.func.isRequired,
};

export default GeneralModal;