export const items = [
  {
    title: 'Контакти',
    icon: 'user_square',
    link: '/contacts',
  },
  {
    title: 'Залишити відгук',
    icon: 'сhat',
    link: '/reviews',
  },
  {
    title: 'Налаштування',
    icon: 'settings',
    link: '/settings',
  },
];