import React from 'react';
import NewPasswordForm from './NewPasswordForm';
import TitleAuth from '../../../common/Titles/TitleAuth';
import '../../Login/auth.scss';

const NewPassword = () => {
	return (
		<div className="auth">
			<TitleAuth titleText="Зміна пароля" />
			<NewPasswordForm />
		</div>
	);
};

export default NewPassword;