import React from 'react';
import PropTypes from 'prop-types';
import './field.scss';

const Field = ({name, type, value, labelText, formClass, ...props}) => {
    
    const formFieldClass = formClass ? formClass : '';
    const classes = [formFieldClass].filter(Boolean).join(' ');

    return (
        <div className={`field ${classes}`}>
            <label htmlFor={name || ''} className='field__title'>{labelText}</label>
            <input
                id={name || ''}
                className="field__input"
                type={type || 'text'}
                name={name || ''}
                value={value || ''}
                {...props}
                />
        </div>
    );
};

Field.propTypes = {
    name: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    labelText: PropTypes.string.isRequired,
    formClass: PropTypes.string.isRequired,
};

export default Field;