import React from 'react';
import PropTypes from 'prop-types';

import Button from '../../../common/Buttons/Button';
import CourseName from '../../CourseName/CourseName';

import './finances-card.styles.scss';

const FinancesCard = ({
  course,
  getAccounts,
  getDocuments,
  type,
}) => {
  return (
    <>
      <div className="card-finances">
        <CourseName img={course.image} name={course.name} label={course.type} />

        {type !== 'trial_courses'
          && <div className='card-finances__content'>
            <div className="card-finances-text">
              <span>Залишок занять:</span>
              <span>{course.qty}</span>
            </div>
            <div className="card-finances-text">
              <span>Залишок на балансі:</span>
              <span>{course.total} грн</span>
            </div>
          </div>}

        <div className="card-finances__btns">
          {type === 'course'
            ? (
              (course.status != 3)
              && <Button
                buttonBg='green'
                loading=""
                onClick={() => getAccounts(course.product_id, type)}
              >
                Оплатити
              </Button>
            ) : (
              <Button
                buttonBg='green'
                loading=""
                onClick={() => getAccounts(course.product_id, type)}
              >
                Оплатити
              </Button>
            )}

          {type !== 'trial_courses'
            && <Button buttonBg="transparent link" loading="" onClick={() => getDocuments(course.product_id)}>Детальний звіт</Button>}

        </div>
      </div>
    </>
  );
};

FinancesCard.propTypes = {
  course: PropTypes.object.isRequired,
  getDocuments: PropTypes.func.isRequired,
  getAccounts: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
};

export default FinancesCard;