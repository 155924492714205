import { courseProgram, programs } from '../../api/programs/index';
import actions from '../actions/index';

export function getProgramsInfo(data) {
  return (dispatch) => {
    dispatch(actions.programs.startFetching());
    programs(data)
      .then(res => {
        if(res.data.info.status) {
          dispatch(actions.programs.completedPrograms(res.data.data.completedPrograms));
          dispatch(actions.programs.studyPrograms(res.data.data.studyPrograms));
        }
      })
      .catch(error => console.log(error))
      .finally(() => dispatch(actions.programs.stopFetching()));
  };
}

export function getCourseProgramInfo(data) {
  return (dispatch) => {
    dispatch(actions.programs.startFetching());
    courseProgram(data)
      .then(res => {
        if(res.data.info.status) {
          const details = {
            direction_name: res.data.data.direction_name, 
            program_name: res.data.data.program_name
          };

          dispatch(actions.programs.courseProgramLessons(res.data.data.lessons));
          dispatch(actions.programs.courseProgramDetails(details));
        }
      })
      .catch(error => console.log(error))
      .finally(() => dispatch(actions.programs.stopFetching()));
  };
}