import React from 'react';
import PropTypes from 'prop-types';
import './field.scss';
import './textarea.scss';

const FieldTextarea = ({name, value, labelText, formClass, placeholder, counter, ...props}) => {
    
  const formFieldClass = formClass ? formClass : '';
  const classes = [formFieldClass].filter(Boolean).join(' ');

  return (
    <div className={`field textarea ${classes}`}>
      {labelText && <label htmlFor={name || ''} className='field__title'>{labelText}</label>}

      <div className="field__input textarea__wrapper">
        <textarea
          id={name || ''}
          name={name || ''}
          value={value || ''}
          placeholder={placeholder}
          {...props}
          ></textarea>
        {counter && <div className="textarea__counter">{counter.symbols}/{counter.allSymbols}</div> }
      </div>
    </div>
  );
};

FieldTextarea.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  labelText: PropTypes.string,
  placeholder: PropTypes.string,
  formClass: PropTypes.string,
  counter: PropTypes.object,
};

export default FieldTextarea;