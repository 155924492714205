import React from 'react';
import PropTypes from 'prop-types';

import CourseName from '../../../../CourseName/CourseName';
import Progress from '../../../../Progress/Progress';
import ProgramsLink from '../../ProgramsLink/ProgramsLink';

const ProgramsTableRow = ({ course }) => {
  return (
    <tr className='table-body__row'>
      <td className='table-body__cell'>
        <CourseName img={course.image} name={course.direction_name} level={course.program_name} isViolet={true} />
      </td>
      <td className='table-body__cell table-body__cell--center'>
        <ProgramsLink 
          count={course.event_lesson_count} 
          link='/course/program' 
          onClick={() => localStorage.setItem('programId', JSON.stringify(course.program_id))}  
        />
      </td>
      <td className='table-body__cell table-body__cell--center'>
        {course.study_lesson_count}
      </td>
      <td className='table-body__cell table-body__cell--center'>
        <Progress progress={course.lesson_persent} />
      </td>
    </tr> 
  );
};

ProgramsTableRow.propTypes = {
  course: PropTypes.object.isRequired,
};

export default ProgramsTableRow;