import React, { useEffect } from 'react';
import HeaderAuth from '../../components/HeaderAuth/HeaderAuth';
import RegisterConfirm from '../../components/Registration/RegisterConfirm/RegisterConfirm';

const RegisterConfirmPage = () =>  {
    useEffect(() => {
        document.body.removeAttribute('class');
        document.title = 'Підтвердження номера | NewBrain';
    }, []);

  return (
    <div>
      <HeaderAuth />
      <section className='container container--login'>
        <RegisterConfirm />
      </section>
    </div>
  );
};

export default RegisterConfirmPage;