import React from 'react';
import PropTypes from 'prop-types';
import sprite from '../../img/sprites.svg';
import './head.style.scss';

const Head = ({ textHead, goBack, blockBtn, subtitle }) => {
  return (
    <div className="head">
      {(goBack && !blockBtn) && 
      <button type='button' className="head__back" aria-label='go back' onClick={() => goBack()}>
        <svg className="icon"><use href={`${sprite}#arrow`}></use></svg>
        <span>Назад</span>
      </button>}
      <h2 className="head__title">
        {textHead}
        {subtitle && <span>{subtitle}</span>}
      </h2>
    </div>
  );
};

Head.propTypes = {
  textHead: PropTypes.string.isRequired,
  goBack: PropTypes.any.isRequired,
  blockBtn: PropTypes.bool,
  subtitle: PropTypes.string
};

export default Head;