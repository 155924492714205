import React from 'react';
import './Spinner.scss';

const Spinner = () => {
  return (
    <div className="loader">
      <span></span>
    </div>
  );
};

export default Spinner;
