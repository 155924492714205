import React from 'react';
import RegisterCourseForm from './RegisterCourseForm';
import TitleAuth from '../../../common/Titles/TitleAuth';
import '../../Login/auth.scss';

const RegisterCourse = () => {
  return (
    <div className="auth">
      <TitleAuth titleText="Оберіть курс"/>
      <RegisterCourseForm />
    </div>
  );
};

export default RegisterCourse;