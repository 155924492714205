import React, { useContext, useEffect } from 'react';
import { LayoutContext } from '../../contexts/layout.context';
import { useDispatch, useSelector } from 'react-redux';

import { authSelectors } from '../../store/selectors/auth';
import { getUserInfoThunk } from '../../store/thunks/profile';

import Head from '../../components/Head/Head';
import PhoneCard from '../../components/Contacts/PhoneCard/PhoneCard';
import SocialsCard from '../../components/Contacts/SocialsCard/SocialsCard';
import TelegramCard from '../../components/Contacts/TelegramCard/TelegramCard';

const Contacts = () => {
  const { setLayout } = useContext(LayoutContext);
  const dispatch = useDispatch();

  const auth = useSelector(authSelectors);
  const token = auth.token;

  useEffect(() => {
    document.title = 'Контакти | NewBrain';
    setLayout({ page: 'Контакти' });
  }, [setLayout]);

  useEffect(() => {
    if (token) {
      dispatch(getUserInfoThunk({token}));
    }
  }, [token]);

  return (
    <div className='contacts container'>
      <div className="contacts__head">
        <Head textHead='Контакти' goBack={''} />
      </div>
      <div className="contacts__content">
        <PhoneCard />
        <SocialsCard />
        <TelegramCard />
      </div>
    </div>
  );
};

export default Contacts;