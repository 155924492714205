import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';

export const LayoutContext = createContext({
  page: ''
});

export const LayoutProvider = ({ children }) => {
  const [layout, setLayout] = useState('');
  const value = { layout, setLayout };

  return (
    <LayoutContext.Provider value={value}>
      {children}
    </LayoutContext.Provider>
  );
};

LayoutProvider.propTypes = {
  children: PropTypes.object.isRequired,
};