import React from 'react';
import PropTypes from 'prop-types';

import { getDocumentData, setStatusBg } from '../data';

import sprite from '../../../../img/sprites.svg';

import './finances-modal-card.style.scss';

const FinancesModalCard = ({ document, getPdf }) => {
  const {common, signs, sumClasses} = getDocumentData(document);

  return (
    <div className='card-pay'>
      <div className="card-pay__row">
        <div className="card-pay-box">
          {document.check_id &&
          <button className='check-icon' onClick={() => window.open(document.checkbox_url, 'targer')}>
            <svg className="icon"><use href={`${sprite}#check_list`}></use></svg>
          </button>}
          {document.attendance_status && <span className={`circle-status ${setStatusBg(document.attendance_status)}`}></span>}
          <span className='card-pay__date'>{document.date}</span>
        </div>

        <span className={`card-pay__sum ${sumClasses}`}> 
          {signs} {document.total_price} грн
        </span>
      </div>
      <div className="card-pay__row">
        <span className='card-pay-text'>Заняття:</span>
        <span className='card-pay-text'>{common ? `+${document.qty}` : '-1'}</span>
      </div>
      {document.document_number && 
      <div className="card-pay__row">
        <span className='card-pay-text'>Рахунок-договір:</span>
        <span className='card-pay-text'>
          {document.show_document_url 
          ? <button className='btn-download' onClick={() => getPdf(document.invoice_id)}>
              <svg className="icon"><use href={`${sprite}#download`}></use></svg>
            </button>
          : ''}
          <button className='link' onClick={() => window.open(document.document_url, '_target')}>{document.document_number}</button>
        </span>
      </div>}
    </div>
  );
};

FinancesModalCard.propTypes = {
  document: PropTypes.object.isRequired,
  getPdf: PropTypes.func.isRequired
};

export default FinancesModalCard;