import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { profileSelectors } from '../../../../store/selectors/profile';
import { authSelectors } from '../../../../store/selectors/auth';
import { editUserInfoThunk } from '../../../../store/thunks/profile';
import { useNavigate } from 'react-router-dom';
import actions from '../../../../store/actions';

import FieldPassword from '../../../../common/Fields/FieldPassword';
import LoadingBtn from '../../../LoadingBtn/LoadingBtn';
import ErrorMessage from '../../../../common/Errors/ErrorMessage';

const PasswordForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  const profile = useSelector(profileSelectors);
  const auth = useSelector(authSelectors);
  const token = auth.token;
  const errors = profile.errors;
  const load = profile.load;
  const edit = profile.edit;

  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');

  useEffect(() => {
    if(edit) {
      navigate('/profile');
      dispatch(actions.profile.profileError({}));
      dispatch(actions.profile.editUserInfo(false));
    }
  }, [edit]);

  const handleEdit = (e) => {
    e.preventDefault();
    dispatch(editUserInfoThunk({
      value: password, 
      value_confirmation: passwordConfirmation,  
      field: 'password', 
      token
    }));
  };

  return (
    <form className='form profile-form'>
      <FieldPassword
        name="password"
        value={password} 
        onChange={(e) => setPassword(e.target.value.trim())} 
        labelText="Новий пароль"
        formClass="required"
        required
      />
      <FieldPassword
        name="password_confirmation"
        value={passwordConfirmation} 
        onChange={(e) => setPasswordConfirmation(e.target.value.trim())} 
        labelText="Повторіть новий пароль"
        formClass="form__field required"
        required
      />

      {errors?.value && <ErrorMessage errorText={errors.value} />}
      <div className="profile__btns">
        <LoadingBtn load={load} btnText='Змінити' onClick={handleEdit} />
      </div>
    </form>
  );
};

export default PasswordForm;