import React from 'react';
import PropTypes from 'prop-types';
import Button from '../../common/Buttons/Button';

const LoadingBtn = ({ load, btnText, ...props}) => {
  return (  
    <Button 
      type='submit' 
      buttonBg='violet' 
      loading={load ? 'loading' : ''} 
      disabled={load ? true : false} 
      {...props}
    >
      {btnText}
    </Button>
  );
};

LoadingBtn.propTypes = {
  load: PropTypes.bool.isRequired,
  btnText: PropTypes.string.isRequired,
};

export default LoadingBtn;