import React from 'react';
import PropTypes from 'prop-types';
import './Tag.style.scss';

const Tag = ({ item, name, type, active, ...props }) => {

  const activeClass = active && 'active';
  const classes = [activeClass].filter(Boolean).join(' ');

  return (
    <label className={`tag ${classes}`}>
      <input type={type} name={name} value={item} {...props} />
      <span>{item}</span>
    </label>
  );
};

Tag.propTypes = {
  item: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  active: PropTypes.bool.isRequired,
};

export default Tag;