import React from 'react';
import PropTypes from 'prop-types';

import { image } from './constants';

import './course-block.style.scss';

const CourseBlock = ({ 
  item, 
  btnText, 
  setOpenModal, 
  setCourse 
}) => {

  const handleOpen = (item) => {
    setOpenModal(true);
    setCourse(item);
  };

  return (
    <div className='course-block' onClick={() => handleOpen(item)}>
      {image[item.index] && <img src={image[item.index]} alt={item.title} width='1' height='1' />}
      <span>{item.title}</span>
      <div className='course-block__label'>{btnText}</div>
    </div>
  );
};

CourseBlock.propTypes = {
  item: PropTypes.object.isRequired,
  btnText: PropTypes.string.isRequired,
  setOpenModal: PropTypes.func.isRequired,
  setCourse: PropTypes.func.isRequired,
};

export default CourseBlock;