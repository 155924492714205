import React from 'react';
import ResetCodeForm from './ResetCodeForm';
import TitleAuth from '../../../common/Titles/TitleAuth';
import '../../Login/auth.scss';

const ResetCode = () => {
  return (
    <div className="auth">
      <TitleAuth titleText="Відновлення паролю" />
      <ResetCodeForm />
  </div>
  );
};

export default ResetCode;