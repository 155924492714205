import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

const ModalInfo = ({ notification, setOpenModal, openModal }) => {

  const [elTarget, setElTarget] = useState(null);
  const [clickCount, setClickCount] = useState(0);

  useEffect(() => { 
    if(clickCount <= 4 && clickCount > 0) {
      if(elTarget?.dataset.el === 'btn') {

        if(clickCount === 1) {
          elTarget.classList.add('move-left');
          elTarget.classList.add('shake');
          let timer = setTimeout(() => {
            elTarget.classList.remove('shake');
            clearTimeout(timer);
          }, 600);
        }

        if(clickCount === 2) {
          elTarget.classList.add('move-right');
          elTarget.classList.add('shake');

          let timer = setTimeout(() => {
            elTarget.classList.remove('shake');
            clearTimeout(timer);
          }, 600);
        }

        if(clickCount === 3) {
          elTarget.classList.remove('move-right');
          elTarget.classList.remove('move-left');
          elTarget.classList.add('shake');

          let timer = setTimeout(() => {
            elTarget.classList.remove('shake');
            clearTimeout(timer);
          }, 600);
        }

        if(clickCount === 4) {
          closeModal();
        }
      }

      if(elTarget?.dataset.el === 'overlay') {
        if(clickCount > 0 && clickCount < 4) {
          elTarget.classList.add('bg');
          let timer = setTimeout(() => {
            elTarget.classList.remove('bg');
            clearTimeout(timer);
          }, 400);
        } else {
          closeModal();
        }
      }
    }
  }, [clickCount, elTarget]);

  const getClickData = (target) => {
    setElTarget(target);
    setClickCount(prev => prev + 1);
  };

  const closeModal = () => {
    setOpenModal(false);
    document.body.classList.remove('open');
  };

  return (
    <div className={`modal-info ${openModal ? 'open' : ''}`}>
      <div className="overlay" data-el="overlay" onClick={(e) => getClickData(e.target)}></div>
      <div className="modal-info__container">
        <div className="modal-info__content modal-info__content--gap">
          <div className="modal-info__text" dangerouslySetInnerHTML={{__html: notification?.text}}></div>
          <div className='modal-info-btns'>
            <div className='modal-info-btns-wrapper modal-info-btns-wrapper--move' onClick={closeModal} dangerouslySetInnerHTML={{__html: notification?.button}}></div>
            <button className='modal-info__no link link--tt-sm move' data-el="btn" onClick={(e) => getClickData(e.target)}>Ні</button>
          </div>
        </div>
      </div>
    </div>
  );
};

ModalInfo.propTypes = {
  notification: PropTypes.object.isRequired,
  setOpenModal: PropTypes.func.isRequired,
  openModal: PropTypes.bool.isRequired,
};

export default ModalInfo;