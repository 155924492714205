import types from '../types/index';

const initialState = {
  completedPrograms: [],
  studyPrograms: [],
  lessons: [],
  programDetails: {},
  load: false,
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case types.programs.COMPLETED_PROGRAMS:
      return {
        ...state, completedPrograms: payload
      };
    case types.programs.STUDY_PROGRAMS:
      return {
        ...state, studyPrograms: payload
      };
    case types.programs.COURSE_PROGRAM_LESSONS:
      return {
        ...state, lessons: payload
      };
    case types.programs.COURSE_PROGRAM_DETAILS:
      return {
        ...state, programDetails: payload
      };
    case types.profile.START_FETCHING_PROFILE:
      return {
        ...state, load: true
      };
    case types.profile.STOP_FETCHING_PROFILE:
      return {
        ...state, load: false
      };
    default: {
      return state;
    }
  }
};

export default reducer;