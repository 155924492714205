import React from 'react';
import Calendar from 'react-calendar';
import PropTypes from 'prop-types';

import ScheduleLabels from './ScheduleLabels';

import 'react-calendar/dist/Calendar.css';
import './schedule-month.styles.scss';

const ScheduleMonth = ({
  dates,
  setShowMonth,
  handleClick,
}) => {

  const mark = dates.map(date => date.date);
  const currentLocale = 'uk-UA';
  const type = {day: '2-digit', month: '2-digit', year: 'numeric'}; 
  const now = new Date().toLocaleDateString();

  const setClasses = (date) => {
    const markDate = mark.find(x => {
      const dates = new Date(date).toLocaleDateString(currentLocale, type);
      const markDates = new Date(x).toLocaleDateString(currentLocale, type);
      return dates === markDates;
    });

    if(markDate) {
      if(new Date(markDate).toLocaleDateString() === now) {
        return 'today';
      }
      return 'event';
    }
  };

  return (
    <>
      <Calendar
        locale={currentLocale}
        navigationLabel={({ date }) => date.toLocaleDateString(currentLocale, {year: 'numeric', month: 'long'}).replace('р.','')}
        tileClassName={({ date }) => setClasses(date)}
        nextLabel=''
        next2Label=''
        prevLabel=''
        prev2Label=''
        onClickDay={(value, locale) => handleClick(value, locale)}
        onActiveStartDateChange={({activeStartDate}) => {
          return setShowMonth(new Date(activeStartDate).toLocaleDateString(currentLocale, {day: '2-digit', month: '2-digit', year: 'numeric'}));
        }}
      />

      <div className="schedule__legends container">
        <ScheduleLabels />
      </div>
    </>
  );
};

ScheduleMonth.propTypes = {
  dates: PropTypes.array.isRequired,
  setShowMonth: PropTypes.func.isRequired,
  handleClick: PropTypes.func.isRequired,
};

export default ScheduleMonth;