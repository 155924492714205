export const getDocumentData = (document) => {
  const common = document.type === 'common' || (document.type === 'debt-correction' && document.income_expense === 'income');
  const signs = common ? '+' : '-';
  const colorClass = common ? 'green' : 'red';
  const sumClasses = [colorClass].filter(Boolean).join(' ');

  return { common, signs, sumClasses };
};

export const setStatusBg = (status) => {
  if (status === 1) {
    return 'bg-green';
  }

  if (status === 2) {
    return 'bg-yellow';
  }

  if (status === 3) {
    return 'bg-red';
  }

  return '';
};