import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import actions from '../store/actions/index';

export const getToken = (token) => {
  const dispatch = useDispatch();
  const tokenStorage = JSON.parse(localStorage.getItem('token'));

  useEffect(() => {
    if(!token && tokenStorage) {
      dispatch(actions.auth.loginRequest(tokenStorage));
    }

    if(!tokenStorage && token) {
      dispatch(actions.auth.loginRequest(null));
      localStorage.removeItem('token');
      localStorage.removeItem('user');
    }
  }, [tokenStorage]);
};