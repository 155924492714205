import React from 'react';
import PropTypes from 'prop-types';
import ButtonIcon from '../../../common/ButtonIcon/ButtonIcon';
import { copyTextToClipboard } from '../../../utils/copy';

const FinanceScoreCard = ({ details, setMessage }) => {
  const copyData = (e) => {   
    setMessage(false);
    const btn = e.target.closest('button');
    const text = btn.previousElementSibling.innerText;
    // const copiedText = copyTextToClipboard(text);
    // if(copiedText) {
    //   setMessage(true);
    // }
    copyTextToClipboard(text).then(() => setMessage(true));
  };
  const tariffName = `${details.tariff_name} (${details.tariff_hours}год, ${details.tariff_price}грн за заняття, ${details.tariff_total_price}грн загалом)`;
  
  return ( 
    <>
      <dl className='card-pay'>

        <div className="card-pay__row card-pay__row--start">
          <dt className='card-pay-title'>Тариф:</dt>
          <dd className='card-pay-desc'>{tariffName}</dd>
        </div>

        <div className="card-pay__row card-pay__row--start">
          <dt className='card-pay-title'><b>Отримувач:</b></dt>
          <dd className='card-pay-desc'><b>{details.recipient}</b></dd>
          <ButtonIcon icon='copy' onClick={copyData} />
        </div>
        
        <div className="card-pay__row card-pay__row--start">
          <dt className='card-pay-title'><b>IBAN:</b></dt>
          <dd className='card-pay-desc'><b>{details.iban}</b></dd>
          <ButtonIcon icon='copy' onClick={copyData} />
        </div>

        <div className="card-pay__row card-pay__row--start">
          <dt className='card-pay-title'><b>ЄДРПОУ:</b></dt>
          <dd className='card-pay-desc'><b>{details.receiver_code}</b></dd>
          <ButtonIcon icon='copy' onClick={copyData} />
        </div>

        <div className="card-pay__row card-pay__row--start">
          <dt className='card-pay-title'><b>Призначення:</b></dt>
          <dd className='card-pay-desc'><b>{details.purpose_payment}</b></dd>
          <ButtonIcon icon='copy' onClick={copyData} />
        </div>

        <div className="card-pay__row card-pay__row--start">
          <dt className='card-pay-title'>Назва банку:</dt>
          <dd className='card-pay-desc'>{details.name_bank}</dd>
        </div>

        <div className="card-pay__row card-pay__row--start">
          <dt className='card-pay-title'>Свідотство про реєстрацію:</dt>
          <dd className='card-pay-desc'>{details.certificate_registration}</dd>
        </div>

        <div className="card-pay__row card-pay__row--start">
          <dt className='card-pay-title'>Адреса:</dt>
          <dd className='card-pay-desc'>{details.address}</dd>
        </div>
      </dl>
    </>
  );
};

FinanceScoreCard.propTypes = {
  details: PropTypes.object.isRequired,
  setMessage: PropTypes.func.isRequired,
};

export default FinanceScoreCard;