import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { financesSelectors } from '../../../store/selectors/finances';
import actions from '../../../store/actions';

import Button from '../../../common/Buttons/Button';

import './pay-btns.style.scss';

const FinancesPayBtns = ({ method, setOpenModal, loadingBtn, setLoadingBtn }) => {
  const dispatch = useDispatch();

  const finances = useSelector(financesSelectors);
  const invoice = finances.invoice;
  const link = finances.link;

  useEffect(() => {
    if(link) {
      window.location.href = link;
      setLoadingBtn(false);
      dispatch(actions.finances.getPaymentLink(''));
    }
  }, [link]);

  const setModalData = (method, appointment, name, url) => {
    setOpenModal({'open': true, method, appointment, name, url});
    document.body.classList.add('open');
  };

  return (
    <div className="pay-btns-block">
      <Button 
        buttonBg='green' 
        icon={method.image} 
        iconAlt={method.button_name} 
        disabled={loadingBtn ? true : false} 
        onClick={() => setModalData(method, invoice.appointment, invoice.document_number, invoice.document_url)}
      >
        <span>{method.button_name}</span>
      </Button>
      <span className='pay-btns-block__text'>{method.button_description}</span>
    </div>
  );
};

FinancesPayBtns.propTypes = {
  method: PropTypes.object.isRequired,
  setOpenModal: PropTypes.func.isRequired,
  loadingBtn: PropTypes.bool.isRequired,
  setLoadingBtn: PropTypes.func.isRequired,
};

export default FinancesPayBtns;