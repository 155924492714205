import { newPassword, resetConfirmAgain } from '../../api/reset';
import { resetCode, resetConfirm } from '../../api/reset';
import actions from '../actions';

export function getResetConfirmThunk(data) {
  return (dispatch) => {
    dispatch(actions.reset.startFetching());
    resetConfirm(data)
    .then(res => {
      if (res.data.info.status) {
        dispatch(actions.reset.setResetRequest(res.data.data.phone)); 
        localStorage.setItem('phone', JSON.stringify(res.data.data.phone));
      }
    })
    .catch(error => {
      if(error.response.status >= 500) {
        dispatch(actions.reset.setResetError('Наразі функціонал в розробці!'));
      } else {
        dispatch(actions.reset.setResetErrors(error.response.data.data));
        dispatch(actions.reset.setResetError(error.response.data.info.message));
      }
    })
    .finally(() => dispatch(actions.reset.stopFetching()));
  };
}

export function getResetConfirmAgainThunk(data) {
  return (dispatch) => {
    resetConfirmAgain(data)
    .then(res => {
      if (res.data.info.status) {
        dispatch(actions.reset.setResetRequest(res.data.data.phone));
        localStorage.setItem('phone', JSON.stringify(res.data.data.phone));
      }
    })
    .catch(error => {
      if(error.response.status >= 500) {
        dispatch(actions.reset.setResetError('Наразі функціонал в розробці!'));
      } else {
        dispatch(actions.reset.setResetErrors(error.response.data.data));
        dispatch(actions.reset.setResetError(error.response.data.info.message));
      }
    });
  };
}

export function getResetCodeThunk(data) {
  return (dispatch) => {
    dispatch(actions.reset.startFetching());
    resetCode(data)
    .then(res => {
      if (res.data.info.status) {
        dispatch(actions.reset.setCodeSuccessrRequest(true));
        localStorage.setItem('phone', JSON.stringify(res.data.data.phone));
      }
    })
    .catch(error => {
      if(error.response.status >= 500) {
        dispatch(actions.reset.setResetError('Наразі функціонал в розробці!'));
      } else {
        dispatch(actions.reset.setResetErrors(error.response.data.data));
        dispatch(actions.reset.setResetError(error.response.data.info.message));
      }
    })
    .finally(() => dispatch(actions.reset.stopFetching()));
  };
}

export function getNewPasswordThunk(data) {
  return (dispatch) => {
    dispatch(actions.reset.startFetching());
    newPassword(data)
    .then(res => {
      if (res.data.info.status) {
        dispatch(actions.reset.setNewPasswordChangesuccess(true));
        dispatch(actions.reset.setResetRequest(''));
        dispatch(actions.auth.loginRequest(res.data.data.token));
        dispatch(actions.auth.getUserStatus(res.data.data.status));
        dispatch(actions.profile.userInfo(res.data.data));
        localStorage.setItem('token', JSON.stringify(res.data.data.token));
        localStorage.setItem('user', JSON.stringify(res.data.data));
        localStorage.removeItem('phone'); 
      }
    })
    .catch(error => {
      if(error.response.status >= 500) {
        dispatch(actions.reset.setResetError('Наразі функціонал в розробці!'));
      } else {
        dispatch(actions.reset.setResetErrors(error.response.data.data));
        dispatch(actions.reset.setResetError(error.response.data.info.message));
      }
    })
    .finally(() => dispatch(actions.reset.stopFetching()));
  };
}