import types from '../types/index';

const userInfo = (payload) => ({
  type: types.profile.SET_USER_INFO,
  payload,
});

const editUserInfo = (payload) => ({
  type: types.profile.SET_USER_EDIT_INFO,
  payload,
});

const editUserPhone = (payload) => ({
  type: types.profile.SET_USER_EDIT_PHONE,
  payload,
});

const checkChangedData = (payload) => ({
  type: types.profile.CHECK_CHANGED_DATA,
  payload,
});

const changedDataVerified = (payload) => ({
  type: types.profile.CHANGES_VERIFIED,
  payload,
});

const userConfirmInfo = (payload) => ({
  type: types.profile.SET_USER_CONFIRM_INFO,
  payload,
});

const setTimesToLesson = (payload) => ({
  type: types.profile.SET_USER_TIME_TO_LESSON,
  payload,
});

const telegram = (payload) => ({
  type: types.profile.SET_NOTIFICATION_TELEGRAM,
  payload,
});

const birthDate = (payload) => ({
  type: types.profile.SET_NOTIFICATION_BIRTH_DATE,
  payload,
});

const contacts = (payload) => ({
  type: types.profile.SET_USER_CONTACTS,
  payload,
});

const profileError = (payload) => ({
  type: types.profile.SET_PROFILE_ERROR,
  payload,
});

const startFetching = () => ({
  type: types.profile.START_FETCHING_PROFILE
});

const stopFetching = () => ({
  type: types.profile.STOP_FETCHING_PROFILE,
});

export default {
  userInfo,
  editUserInfo,
  editUserPhone,
  checkChangedData,
  changedDataVerified,
  userConfirmInfo,
  setTimesToLesson,
  telegram,
  birthDate,
  contacts,
  profileError,
  startFetching,
  stopFetching,
};