import React from 'react';

import { useSelector } from 'react-redux';
import { profileSelectors } from '../../../store/selectors/profile';
import sprite from '../../../img/sprites.svg';


import { cleanPhone } from '../../../utils/phoneReplace';

import '../../Contacts/contacts-card.style.scss';


const PhoneCard = () => {
  const profile = useSelector(profileSelectors);
  const contacts = profile.contacts;
  const keys = ['phone_1', 'phone_2', 'phone_3'];
  const phones = contacts.filter(contact => keys.includes(contact.key));

  return (
    <div className='contacts-card'>
      <h3 className="contacts-card-title">Телефони</h3>

      <div className="contacts-card-content">

        {contacts.map(item => {
          if (item.key === 'telegram_chat') {
            return <a href={`${item.value}`} key={item.key} className="contacts-card-icon" target='_blank' rel="noreferrer noopener">
              <svg className="icon"><use href={`${sprite}#telegram`}></use></svg>
            </a>;
          }
        })}

        {phones.length > 0 && <div className="contacts-card-content__col">
          {phones.map(item => <a href={`tel:${cleanPhone(item.value)}`} key={item.key} className="contacts-card-phone">{item.value}</a>)}
        </div>}
      </div>
    </div>
  );
};

export default PhoneCard;