import React from 'react';
import { legends } from './constants';

import './schedule-legends.scss';

const ScheduleLabels = () => {
  return (
    <ul className='legends'>
      {legends.map(el => <li key={el.label} className={`legends__item ${el.class ? ` legends__item--${el.class}` : ''}`}>{el.label}</li>)}
    </ul>
  );
};

export default ScheduleLabels;