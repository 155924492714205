import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { authSelectors } from '../../../../store/selectors/auth';
import { editUserInfoThunk } from '../../../../store/thunks/profile';

import Switcher from '../../../../common/Switcher/Switcher';
import { profileSelectors } from '../../../../store/selectors/profile';

const SettingSwitcher = ({ active, setActive }) => {
  const dispatch = useDispatch();

  const auth = useSelector(authSelectors);
  const profile = useSelector(profileSelectors);
  const user = profile.user;
  const token = auth.token;

  useEffect(() => {
    if(Object.keys(user).length > 0) {
      setActive(user.receive_notifications ? 'on' : 'off');
    }
  }, [user]);

  const handleSwitcher = (checked) => {
    setActive(checked);
    if(checked && token) {
      dispatch(editUserInfoThunk({value: checked === 'on' ? true : false, field: 'receive_notifications', token}));
    }
  };

  return (
    <Switcher 
      onChange={(e) => handleSwitcher(e.target.checked ? 'on' : 'off')}
      active={active}
    />
  );
};

SettingSwitcher.propTypes = {
  active: PropTypes.string.isRequired,
  setActive: PropTypes.func.isRequired
};

export default SettingSwitcher;