import React from 'react';
import RegisterForm from './RegisterForm';
import TitleAuth from '../../../common/Titles/TitleAuth';
import '../../Login/auth.scss';

const Register = () => {
  return (
    <div className="auth">
      <TitleAuth titleText="Реєстрація"/>
      <RegisterForm /> 
    </div>
  );
};

export default Register;
