import React, { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LayoutContext } from '../../../contexts/layout.context';
import { profileSelectors } from '../../../store/selectors/profile';

import Head from '../../../components/Head/Head';
import Spinner from '../../../common/Spinner/Spinner';
import Alert from '../../../common/Alert/Alert';
import ProfileCards from '../../../components/Profile/ProfileCards/ProfileCards';
import { getProfileThunk } from '../../../store/thunks/profile';
import { authSelectors } from '../../../store/selectors/auth';

const Profile = () => {
  const { setLayout } = useContext(LayoutContext);
  const dispatch = useDispatch();

  const profile = useSelector(profileSelectors);
  const auth = useSelector(authSelectors);
  const token = auth.token;
  const user = profile.user;
  const confirmInfo = profile.confirmInfo;
  const load = profile.load;
  const edit = profile.edit;

  useEffect(() => {
    document.title = 'Профіль | NewBrain';
    setLayout({ page: 'Профіль' });
  }, [setLayout]);

  useEffect(() => {
    if(token) {
      dispatch(getProfileThunk({token}));
    }
  }, [token]);
  
  return (
    <div className="profile container">
      {edit && <Alert text='Пароль успішно змінено' />}
      
      <Head textHead='Профіль' goBack={''} />
      
      <div className="profile__content">
        {load ? <Spinner /> : <div className="profile-block cards">
          <ProfileCards user={user} confirmInfo={confirmInfo} />
        </div>}
      </div>
    </div>
  );
};

export default Profile;