import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import './select.style.scss';

const SelectEl = ({ options, setSelected, selectedOption, disabled, placeholder, ...props }) => {
  return (
    <Select 
      className='select' 
      classNamePrefix='select' 
      options={options} 
      isSearchable={false} 
      placeholder={placeholder}
      value={selectedOption}
      onChange={(val) => setSelected(val.value)}
      isDisabled={disabled}
      {...props} 
    />
  );
};

SelectEl.propTypes = {
  options: PropTypes.array.isRequired,
  setSelected: PropTypes.func.isRequired,
  selectedOption: PropTypes.object,
  disabled: PropTypes.bool.isRequired,
  placeholder: PropTypes.string.isRequired,
};

export default SelectEl;