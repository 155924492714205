import React from 'react';
import PropTypes from 'prop-types';

import { cleanPhone } from '../../../utils/phoneReplace';
import { useSelector } from 'react-redux';
import { profileSelectors } from '../../../store/selectors/profile';

const TelegramActivate = ({ setUpdate }) => {
  const profile = useSelector(profileSelectors);
  const user = profile.user;
  const contacts = profile.contacts;

  return (
    <>
      {contacts.map(item => item.key === 'telegram_bot'
        ? <a
          key={item.key}
          href={`${item.value}?start=_${cleanPhone(user?.phone)}`}
          className='contacts-card-btn'
          target='_blank'
          rel="noreferrer"
          onClick={() => setUpdate(true)}
        >
          Активувати
        </a>
        : '')}
    </>
  );
};

TelegramActivate.propTypes = {
  setUpdate: PropTypes.func.isRequired,
};

export default TelegramActivate;