import { response } from '../../api/reviews';
import actions from '../actions/index';

export function reviewsThunk(data) {
  return (dispatch) => {
    dispatch(actions.reviews.startFetching());
    response(data)
      .then(res => {
        if (res.data.info.status) {
          dispatch(actions.reviews.reviewsSuccess(res.data.data));
        }
      })
      .catch(error => {
        dispatch(actions.reviews.setReviewsError(error.response.data.data));
      })
      .finally(() => dispatch(actions.reviews.stopFetching()));
  };
}