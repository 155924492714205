import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { LayoutContext } from '../../../contexts/layout.context';
import actions from '../../../store/actions';

import Head from '../../../components/Head/Head';
import EmailForm from '../../../components/Profile/Edit/EmailForm/EmailForm';

const EditEmail = () => {
  const { setLayout } = useContext(LayoutContext);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    document.title = 'Зміна | NewBrain';
    setLayout({ page: 'Зміна' });
  }, [setLayout]);

  const goBack = () => {
    navigate('/profile');
    dispatch(actions.profile.profileError({}));
  };

  return (
    <div className="profile container">
      <Head textHead='Зміна електронної пошти' goBack={goBack}  />
      <div className="profile__content">
        <EmailForm />
      </div>
    </div>
  );
};

export default EditEmail;