import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { profileSelectors } from '../../../../store/selectors/profile';
import { authSelectors } from '../../../../store/selectors/auth';
import { editUserInfoThunk } from '../../../../store/thunks/profile';
import actions from '../../../../store/actions';
import { useNavigate } from 'react-router-dom';

import Field from '../../../../common/Fields/Field';
import LoadingBtn from '../../../LoadingBtn/LoadingBtn';
import ErrorMessage from '../../../../common/Errors/ErrorMessage';

const EmailForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const profile = useSelector(profileSelectors);
  const auth = useSelector(authSelectors);
  const token = auth.token;
  const user = profile.user;
  const errors = profile.errors;
  const load = profile.load;
  const edit = profile.edit;
  const confirmInfo = profile.confirmInfo;

  const [email, setEmail] = useState('');
  const [fieldConfirmed, setFieldConfirmed] = useState('');

  useEffect(() => {
    if(edit) {
      navigate('/profile');
      dispatch(actions.profile.editUserInfo(false));
      dispatch(actions.profile.profileError({}));
    }
  }, [edit]);

  useEffect(() => {
    if(Object.keys(user).length > 0 && user.email) {
      setEmail(user.email);
    }
  }, [user]);

  useEffect(() => {
    if(confirmInfo.length > 0) {
      setFieldConfirmed(confirmInfo.find(field => field.field === 'email'));
    }
  }, [confirmInfo]);

  useEffect(() => {
    if(fieldConfirmed) {
      setEmail(fieldConfirmed.value_to);
    }
  }, [fieldConfirmed]);

  const handleEdit = (e) => {
    e.preventDefault();
    dispatch(editUserInfoThunk({value: email, field: 'email', token}));
  };

  return (
    <form className='form profile-form'>
      <Field 
        name="email" 
        type="email"
        value={email} 
        labelText="Електронна пошта" 
        onChange={(e) => setEmail(e.target.value.trim())} 
        formClass="required"
        required
      />
      {errors?.value &&  errors.value.map(err => <ErrorMessage key={err} errorText={err} />)}

      <div className="profile__btns">
        <LoadingBtn load={load} btnText='Змінити' onClick={handleEdit} />
      </div>
    </form>
  );
};

export default EmailForm;