import React, { useEffect } from 'react';
import PhoneInput from 'react-phone-input-2';
import PropTypes from 'prop-types';

const FieldPhone = ({name, type, valuePhone, labelText, formClass, labelMessage, ...props}) => {

  const formFieldClass = formClass ? formClass : '';
  const classes = [formFieldClass].filter(Boolean).join(' ');

  useEffect(() => {
    document.querySelector('.form-control').classList.remove('form-control');
  }, []);

  return (
    <div className={`field ${classes}`}>
      <label htmlFor={name || ''} className='field__title'>{labelText} {labelMessage ? <span>{labelMessage}</span> : ''}</label>

      <PhoneInput
        className='field__input'
        type={type || 'text'}
        id={name || ''}
        name={name || ''}
        value={valuePhone || ''}
        country={'ua'}
        alwaysDefaultMask={true}
        masks={'+38 (...) ..-..-..'}
        specialLabel=''
        placeholder={valuePhone || ''}
        {...props}
      />
    </div>
  );
};

FieldPhone.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  valuePhone: PropTypes.string,
  labelText: PropTypes.string.isRequired,
  formClass: PropTypes.string.isRequired,
  labelMessage: PropTypes.string.isRequired,
};

export default FieldPhone;