export const tabs = [
	{
    id: 1,
		title: 'Пропозиція',
		type: 'offer',
	},
	{
    id: 2,
		title: 'Скарга',
		type: 'complaint',
	}
];