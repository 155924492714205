import React, { useEffect } from 'react';
import NewPassword from '../../components/ResetPassword/NewPassword/NewPassword';
import HeaderAuth from '../../components/HeaderAuth/HeaderAuth';

const ResetPasswordPage = () => {
  useEffect(() => {
    document.body.removeAttribute('class');
    document.title = 'Зміна пароля | NewBrain';
  }, []);

  return (
    <div>
      <HeaderAuth />
      <section className="container container--login">
        <NewPassword />
      </section>
    </div>
  );
};

export default ResetPasswordPage;