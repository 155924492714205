import React from 'react';
import PropTypes from 'prop-types';

import Progress from '../../../Progress/Progress';
import ProgramsLink from '../ProgramsLink/ProgramsLink';
import CourseName from '../../../CourseName/CourseName';

import './card-program.style.scss';

const ProgramsCard = ({ course }) => {
  return (
    <div className="card-program">
      <CourseName img={course.image} name={course.direction_name} level={course.program_name} isViolet={true} />

      <div className="card-program-block">
        <div className="card-program-block__title">Пройдено</div>
        <ProgramsLink 
          count={course.event_lesson_count} 
          link='/course/program' 
          onClick={() => localStorage.setItem('programId', JSON.stringify(course.program_id))} 
        />
      </div>

      <div className="card-program-block">
        <div className="card-program-block__title">Всього</div>
        <div className="card-program-block__desc">{course.event_lesson_count}/{course.study_lesson_count}</div>
      </div>

      <Progress progress={course.lesson_persent} />
    </div>
  );
};

ProgramsCard.propTypes = {
  course: PropTypes.object.isRequired,
};

export default ProgramsCard;