import React from 'react';
import FinanceAccountsTableRow from './FinanceAccountsTableRow';
import PropTypes from 'prop-types';

const FinanceAccountsTable = ({ accounts, setOpenModal, getPdf }) => {
  return (
    <table className='table-block'>
      <thead className='table-head'>
        <tr className='table-head__row'>
          <td className='table-head__cell table-head__cell--center'>Дата</td>
          <td className='table-head__cell table-head__cell--center'>Рахунок-договір</td>
          <td className='table-head__cell table-head__cell--center'>Чек</td>
          <td className='table-head__cell table-head__cell--center'>Назва курсу</td>
					<td className='table-head__cell table-head__cell--center'>Заняття</td>
					<td className='table-head__cell table-head__cell--center'>Ціна</td>
          <td className='table-head__cell table-head__cell--center'>Сума</td>
          <td className='table-head__cell table-head__cell--center'>Статус</td>
          <td className='table-head__cell table-head__cell--center'>Посилання</td>
          <td className='table-head__cell table-head__cell--center'>Дії</td>
        </tr>
      </thead>
      <tbody className='table-body'>
        {accounts.map(count => <FinanceAccountsTableRow 
          key={count.id} 
          count={count} 
          setOpenModal={setOpenModal} 
          getPdf={getPdf}
        />)}
      </tbody>
    </table>
  );
};

FinanceAccountsTable.propTypes = {
  accounts: PropTypes.array.isRequired,
  setOpenModal: PropTypes.func.isRequired,
  getPdf: PropTypes.func.isRequired
};

export default FinanceAccountsTable;