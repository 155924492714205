
import { getSchedule, getEventDay } from '../../api/schedule';
import actions from '../actions/index';

export function getScheduleType(data) {
  return (dispatch) => {
    dispatch(actions.schedule.startFetching());
    getSchedule(data)
      .then(res => {
        if (res.data.info.status) {
          dispatch(actions.schedule.getScheduleType(res.data.data));
        }
      })
      .catch(error => {
        dispatch(actions.schedule.getScheduleTypeError(error.response.data.info.message));
      })
      .finally(() => dispatch(actions.schedule.stopFetching()));
  };
}

export function getDay(data) {
  return (dispatch) => {
    dispatch(actions.schedule.startFetching());
    getEventDay(data)
      .then(res => {
        if (res.data.info.status) {
          dispatch(actions.schedule.getDayEvent(res.data.data));
        }
      })
      .catch(error => {
        dispatch(actions.schedule.getDayEventError(error.response.data.info.message));
      })
      .finally(() => dispatch(actions.schedule.stopFetching()));
  };
}