import React from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';

import './info.style.scss';

const Info = ({ data, condition }) => {
  const location = useLocation();

  if(location.pathname === '/profile') {
    if(condition === 'confirmed') {
      const {fields, from, to} = data;
      return <div className='info'>Зміна {fields} {from ? 'з' : ''} <b>{from}</b> на <b>{to}</b></div>;      
    }

    if(condition === 'moderation') {
      const { text } = data;
      return <div className='info info--lg'>{text}</div>;      
    }

  } else {
    return <></>;
  }
};

Info.propTypes = {
  data: PropTypes.object.isRequired,
  condition: PropTypes.string.isRequired,
};

export default Info;