import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import actions from '../../../store/actions';
import { registerSelectors } from '../../../store/selectors/register';
import { getRegisterNotMeFeedbackThunk } from '../../../store/thunks/register';

import { resetErrors } from '../data';

import Button from '../../../common/Buttons/Button';
import ErrorField from '../../../common/Errors/ErrorField';
import ErrorMessage from '../../../common/Errors/ErrorMessage';
import FieldTextarea from '../../../common/Fields/FieldTextarea';

const RegisterFeedbackForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [commentValue, setCommentValue] = useState('');
  const [phone] = useState(JSON.parse(localStorage.getItem('phone')));

  const register = useSelector(registerSelectors);
  const error = register.error;
  const errors = register.errors;
  const feedback = register.feedback;
  const load = register.load;

  useEffect(() => {
    if(feedback) {
      navigate('/registration/wait');
      dispatch(actions.register.registerNotMeRequest(false));
    }
  }, [feedback]);

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = {
      phone, comment: commentValue
    };
    resetErrors(dispatch, actions.register);
    dispatch(getRegisterNotMeFeedbackThunk(data));
  };

  // const setSimilarPage = () => {
  //   dispatch(actions.register.registerNotMeRequest(false));
  //   navigate('/registration/similar');
  // };

  return (
    <div className="auth__form form">
      <>
        <FieldTextarea 
          name="comment"
          value={commentValue}
          labelText="Напишіть повідомлення" 
          onChange={(e) => setCommentValue(e.target.value)} 
          formClass=""
        />
        {errors?.comment && <ErrorField errorText={errors.comment} />}
        {errors?.phone && <ErrorField errorText={errors.phone} />}
      </>

      {(error && !errors) && <ErrorMessage errorText={error} />}

      <div className="auth__btns">
        {load
          ? <Button buttonBg="violet" onClick={handleSubmit} loading="loading" disabled>Надіслати</Button> 
          : <Button buttonBg="violet" onClick={handleSubmit} loading="">Надіслати</Button> 
        }
        {/* <div className="auth__text">
          <Button className="link" onClick={setSimilarPage} loading="">Назад</Button>
        </div> */}
      </div>

    </div>
  );
};

export default RegisterFeedbackForm;