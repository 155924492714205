import types from '../types/index';

const setGeneralCourses = (payload) => ({
  type: types.general.GENERAL_COURSES,
  payload,
});

const setChoosenDirectionSuccess = (payload) => ({
  type: types.general.SET_CHOOSEN_DIRECTION_SUCCES,
  payload,
});

const setMessage = (payload) => ({
  type: types.general.SET_MESSAGE,
  payload,
});

const startFetching = () => ({
  type: types.general.START_FETCHING_GENERAL
});

const stopFetching = () => ({
  type: types.general.STOP_FETCHING_GENERAL,
});
const setGeneralError = (payload) => ({
  type: types.general.SET_ERROR_GENERAL,
  payload,
});



export default {
  setGeneralCourses,
  setMessage,
  setChoosenDirectionSuccess,
  startFetching,
  stopFetching,
  setGeneralError,
};