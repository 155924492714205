import React, { useContext, useEffect, useState } from 'react';
import { LayoutContext } from '../../contexts/layout.context';
import { useDispatch, useSelector } from 'react-redux';
import { authSelectors } from '../../store/selectors/auth';
import { getFinancesThunk } from '../../store/thunks/finances';
import { getUserInfoThunk } from '../../store/thunks/profile';
import { financesSelectors } from '../../store/selectors/finances';

import Spinner from '../../common/Spinner/Spinner';
import Plug from '../../components/plug/plug.component';
import FinancesBlock from '../../components/Finances/FinancesBlock/FinancesBlock';
import FinancesModal from '../../components/Finances/FinancesModal/FinancesModal';

const Finance = () => {
  const dispatch = useDispatch();

  const { setLayout } = useContext(LayoutContext);

  const [courses, setCourses] = useState([]);
  const [coursesTrial, setCoursesTrial] = useState([]);
  const [coursesActive, setCoursesActive] = useState([]);
  const [coursesNotActive, setCoursesNotActive] = useState([]);
  const [modalOpen, setModalOpen] = useState('');

  const auth = useSelector(authSelectors);
  const token = auth.token;

  const finances = useSelector(financesSelectors);
  const coursesAll = finances.courses;
  const trialCoursesAll = finances.trialCourses;
  const financesDocuments = finances.documents;
  const load = finances.load;
  const course = finances.course;

  useEffect(() => {
    document.title = 'Фінанси | NewBrain';
    setLayout({ page: 'Фінанси' });
  }, [setLayout]);

  useEffect(() => {
    if (trialCoursesAll.length > 0) {
      setCoursesTrial([]);
      setCoursesTrial(trialCoursesAll);
    } else {
      setCoursesTrial([]);
    }
  }, [trialCoursesAll]);

  useEffect(() => {
    if (coursesAll.length > 0) {
      setCourses([]);
      setCourses(coursesAll);
    } else {
      setCourses([]);
    }
  }, [coursesAll]);

  useEffect(() => {
    if(courses.length > 0) {
      setCoursesActive([]);
      setCoursesNotActive([]);
      setCoursesActive(courses.filter(course => course.status === 1 || course.status === 3));
      setCoursesNotActive(courses.filter(course => course.status === 0 || course.status === 2 || course.status === 4));
    } else {
      setCoursesActive([]);
      setCoursesNotActive([]);
    }
  }, [courses]);

  useEffect(() => {
    if (token) {
      dispatch(getFinancesThunk({ token }));
      dispatch(getUserInfoThunk({token}));
    }
  }, [token]);

  return (
    <>
    {load ? <Spinner /> : 
      <>
        <div className="finances page container">
            {(courses.length === 0 && coursesTrial.length === 0)  
            ? <Plug plug='Дані про фінанси відсутні!' icon='file-closed' /> 
            : <div className='page-blocks'>
              {coursesTrial.length > 0 && <FinancesBlock setModalOpen={setModalOpen} courses={coursesTrial} token={token} title='Пробні' type="trial_courses" />}

              {coursesActive.length > 0 && <FinancesBlock 
                setModalOpen={setModalOpen}
                title='В навчанні'
                courses={coursesActive} 
                token={token} 
                type="course" 
              />}

              {coursesNotActive.length > 0 && <FinancesBlock 
                setModalOpen={setModalOpen}
                title='Неактивні'
                courses={coursesNotActive} 
                token={token} 
                type="course" 
              />}

            </div>}
        </div>

        <FinancesModal 
          course={course}
          documents={financesDocuments}
          setModalOpen={setModalOpen}
          toggleClass={modalOpen}
        />
      </>}
    </>
  );
};

export default Finance;