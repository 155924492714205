import React from 'react';
import PropTypes from 'prop-types';

const FinancePayCard = ({ setLink, invoice }) => {
  return (
    <div className='card-pay'>

      <div className="card-pay__row">
        <span className='card-pay__date'>{invoice.created_at}</span>
      </div>

      <div className="card-pay__row">
        <div className="course-name">
          <img src={invoice.image} alt={invoice.nomenclature_name} width="1" height="1" decoding='async' />
          {invoice.nomenclature_name}
        </div>
      </div>

      <div className="card-pay__row">
        <span className='card-pay-text'>Рахунок-договір:</span>
        <span className='card-pay-text'>
          <button className='link' onClick={() => setLink(invoice.document_url)}>{invoice.document_number}</button>
        </span>
      </div>

    </div>
  );
};

FinancePayCard.propTypes = {
  setLink: PropTypes.func.isRequired,
  invoice: PropTypes.object.isRequired,
};

export default FinancePayCard;