export const toggle = (block) => {
	const content = block.querySelector('[data-content="toggle"]');

	if (block.classList.contains('open')) {
		content.removeAttribute('style');
		block.classList.remove('open');
	}else {
		content.style.height = content.scrollHeight + 'px';
		block.classList.add('open');
	}
};

export const openToggle = (block) => {
	const content = block.querySelector('[data-content="toggle"]');
	if (block.classList.contains('open')) {
		content.style.height = content.scrollHeight + 'px';
	}
};