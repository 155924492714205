import React, { useContext, useEffect, useState } from 'react';
import { LayoutContext } from '../../contexts/layout.context';

import Head from '../../components/Head/Head';
import { tabs } from './constats';
import TabsRound from '../../components/Tabs/TabsRound/TabsRound';
import ReviewsForm from '../../components/Reviews/ReviewsForm/ReviewsForm';
import ReviewsModal from '../../components/Reviews/ReviewsModal/ReviewsModal';

const Reviews = () => {
  const { setLayout } = useContext(LayoutContext);

  const [type, setType] = useState('offer');

  useEffect(() => {
    document.title = 'Відгук | NewBrain';
    setLayout({ page: 'Відгук' });
  }, [setLayout]);

  return (
    <div className='reviews container'>
      <Head textHead='Залишити відгук' goBack={''} />

      <div className="reviews__content">
        <TabsRound items={tabs} setType={setType} />
        <ReviewsForm otherClass="" type={type} />
      </div>  

      <ReviewsModal type={type} />    
    </div>
  );
};

export default Reviews;