import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { profileSelectors } from '../../../store/selectors/profile';
import { authSelectors } from '../../../store/selectors/auth';
import { 
  changedDataVerifiedThunk, 
  checkChangedDataThunk, 
  getProfileThunk 
} from '../../../store/thunks/profile';
import actions from '../../../store/actions';

import { fields, fieldsRejected } from '../constants';

import Button from '../../../common/Buttons/Button';

const ModalEditProfile = () => {
  const dispatch = useDispatch();

  const [openModal, setOpenModal] = useState(false);

  const profile = useSelector(profileSelectors);
  const auth = useSelector(authSelectors);
  const token = auth.token;
  const user = profile.user;
  const changes = profile.changes;
  const confirmed = profile.confirmed;
  const allowLink = user.available_link_confirmation_informations;

  useEffect(() => {
    if(allowLink && token) {
      dispatch(checkChangedDataThunk({token}));
    }
  }, [allowLink]);

  useEffect(() => {
    if(changes.length > 0) {
      setOpenModal(true);
      document.body.classList.add('open');
    }
  }, [changes]);

  useEffect(() => {
    if(confirmed) {
      setOpenModal(false);
      document.body.classList.remove('open');
      dispatch(getProfileThunk({token}));
      dispatch(actions.profile.checkChangedData([]));
      dispatch(actions.profile.changedDataVerified(false));
    }
  }, [confirmed]);

  const setShowedModal = () => {
    dispatch(changedDataVerifiedThunk({token}));
  };
  
  return (
    <div className={`modal-info ${openModal ? 'open' : ''}`}>
      <div className="overlay" onClick={setShowedModal}></div>
      <div className="modal-info__container">
        <div className="modal-info__content modal-info__content--gap">
          {changes.map(change => {
            return <div key={change.id}>
              {change.status === 1 
              ? <div className="modal-info__text">
                  {fields[change.field]} {change.value_from ? 'з' : ''} <b>{change.value_from}</b> на <b>{change.value_to}</b> успішно змінено!
                </div> 
              : <>
                <div className="modal-info__text">
                  Зміна {fieldsRejected[change.field]} з <b>{change.value_from}</b> на <b>{change.value_to}</b> відхилено!
                </div> 
                  {change.comment && <div className="modal-info-block">
                  <div className="modal-info-block__title">Коментар:</div>
                  <div className="modal-info-block__text">{change.comment}</div>
                </div>}
              </>}
          </div>; 
          })}
          <Button buttonBg="green" loading='' onClick={setShowedModal}>Зрозуміло</Button>
        </div>
      </div>
    </div>
  );
};

export default ModalEditProfile;