import React, { useEffect, useState } from 'react';
import HeaderAuth from '../../components/HeaderAuth/HeaderAuth';
import RegisterWait from '../../components/Registration/RegisterWait/RegisterWait';
import { registerSelectors } from '../../store/selectors/register';
import { useSelector } from 'react-redux';

const RegisterWaitPage = () =>  {
  useEffect(() => {
    document.body.removeAttribute('class');
    document.title = 'Дякуємо за реєстрацію! | NewBrain';
  }, []);

  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [textBtn, seTextBtn] = useState('');
  const [btnLink, setBtnLink] = useState('');
  const [type, setType] = useState('');

  const register = useSelector(registerSelectors);
  const feedback = register.feedback;
  const registered = register.registered;

  useEffect(() => {
    if(!registered || !feedback) {
      setData('Дякуємо за реєстрацію!', '', '', '/', 'registration');
    }
  }, []);

  useEffect(() => {
    if(feedback) {
      setData('Дякуємо за Ваш відгук!', 'Ми зателефонуємо Вам згодом!', 'Увійти', '/login', '');
    }
  }, [feedback]);

  useEffect(() => {
    if(registered) {
      setData('Номер телефону вже існує!', 'Ми зателефонуємо Вам згодом!', 'Увійти', '/login', '');
    }
  }, [registered]);

  const setData = (text, desc, btn, link, type) => {
    setTitle(text);
    setDescription(desc);
    seTextBtn(btn);
    setBtnLink(link);
    setType(type);
  };

  return (
    <div>
      <HeaderAuth />
      <section className='container container--login'>
        <RegisterWait title={title} description={description} textBtn={textBtn} btnLink={btnLink} type={type} />
      </section>
    </div>
  );
};

export default RegisterWaitPage;