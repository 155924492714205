import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';

import actions from '../../../store/actions';
import { registerSelectors } from '../../../store/selectors/register';
import { getRegisterCodeThunk } from '../../../store/thunks/register';

import { resetErrors } from '../data';
import { phoneReplaceFunc } from '../../../utils/phoneReplace';

import SendCodeBtn from '../../SendCodeBtn/SendCodeBtn';
import ErrorField from '../../../common/Errors/ErrorField';
import FieldTimer from '../../../common/Fields/FieldTimer';
import ErrorMessage from '../../../common/Errors/ErrorMessage'; 

const RegisterCodeForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [codeValue, setCodeValue] = useState('');
  const [phone] = useState(JSON.parse(localStorage.getItem('phone')));
  const [phoneReplace, setPhoneReplace] = useState(phone);
  const [message, setMessage] = useState(false);

  const register = useSelector(registerSelectors);
  const successCode = register.successCode;
  const successCodeSimilar = register.successCodeSimilar;
  const lead = register.lead;
  const error = register.error;
  const errors = register.errors;
  const load = register.load;

  useEffect(() => {
    if(successCode) {
      navigate('/registration/course');
      resetErrors(dispatch, actions.register);
      dispatch(actions.register.registerCodeNotSimilarRequest(false));
    }
  }, [successCode]);

  useEffect(() => {
    if(successCodeSimilar) {
      navigate('/registration/similar');
      resetErrors(dispatch, actions.register);
      dispatch(actions.register.registerCodeSimilarRequest(false));
    }
  }, [successCodeSimilar]);

  useEffect(() => {
    if(lead) {
      navigate('/registration/communication');
      resetErrors(dispatch, actions.register);
      dispatch(actions.register.registerLead(false));
    }
  }, [lead]);

  useEffect(() => {
    if(phone) {
      const phoneStr = phoneReplaceFunc(phone);
      setPhoneReplace(phoneStr);
    }
  }, [phone]);

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = {
      code: codeValue,
      phone
    };
    resetErrors(dispatch, actions.register);
    dispatch(getRegisterCodeThunk(data));
  };

  return (
    <form className="auth__form form" onSubmit={handleSubmit}>

      <>
        <FieldTimer 
          name="code" 
          type="text"
          value={codeValue} 
          labelText="Код" 
          onChange={(e) => setCodeValue(e.target.value.trim())} 
          formClass="required"
          setMessage={setMessage}
          required
        />
        {error && <ErrorField errorText={error} />}
      </>

      <div className="form__message">
        Введіть код, отриманий на ваш номер телефону <span>{phoneReplace}</span>
      </div>

      {message && <div className="form__message form__message--green">
        Якщо у Вас НЕукраїнський номер телефону, і Ви не отримали SMS з кодом, можливо у Вашу країну неможливо відправити повідомлення, зверніться до нашого Адміністратора
      </div>}

      {(errors?.code && !error) && <ErrorMessage errorText={errors.code} />}
      {(errors?.phone && !error) && <ErrorMessage errorText={errors.phone} />}

      <div className="auth__btns">
        <SendCodeBtn load={load} value={codeValue}/>
      </div>

    </form>
  );
};

export default RegisterCodeForm;