import React from 'react';
import TitleAuth from '../../../common/Titles/TitleAuth';
import RegisterFeedbackForm from './RegisterFeedbackForm';
import '../../Login/auth.scss';

const RegisterFeedback = () => {
  return (
    <div className="auth">
			<TitleAuth titleText="Знайдено схожий профіль"/>
      <h3 className="auth__subtitle">Ми зателефонуємо Вам згодом!</h3>
			<RegisterFeedbackForm />
    </div>
  );
};

export default RegisterFeedback;