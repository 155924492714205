import React from 'react';
import PropTypes from 'prop-types';

const FinancePayTable = ({ setLink, invoice }) => {
  return (
    <table className='table-block'>
      <thead className='table-head'>
        <tr className="table-head__row">
          <td className="table-head__cell table-head__cell--center">Дата</td>
          <td className="table-head__cell table-head__cell--center">Назва курсу</td>
          <td className="table-head__cell table-head__cell--center">Рахунок-договір</td>
        </tr>
      </thead>

      <tbody className="table-body">
        <tr className="table-body__row">
          <td className="table-body__cell table-body__cell--center">{invoice.created_at}</td>
          <td className="table-body__cell table-body__cell--center">
            {/* <CourseName img={invoice.image} name={invoice.nomenclature_name} /> */}
            <div className="course-name">
            <img src={invoice.image} alt={invoice.nomenclature_name} width="1" height="1" decoding='async' />
              {invoice.nomenclature_name}
            </div>
          </td>
          <td className="table-body__cell table-body__cell--center">
            <button className='table__link link' onClick={() => setLink(invoice.document_url)}>{invoice.document_number}</button>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

FinancePayTable.propTypes = {
  setLink: PropTypes.func.isRequired,
  invoice: PropTypes.object.isRequired,
};

export default FinancePayTable;