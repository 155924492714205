import { 
  createCopyPayment,
  createCount, 
  financesAccounts, 
  financesLeadAccounts, 
  getFinances, 
  getFinancesContract, 
  getFinancesDocuments, 
  getFinancesPdf, 
  getPay, 
  payActualCount, 
  payActualTariff,
  payDetails
} from '../../api/finances/index';
import actions from '../actions/index';

export function getFinancesThunk(data) {
  return (dispatch) => {
    dispatch(actions.finances.startFetching());
    getFinances(data)
      .then(res => {
        if (res.data.info.status) {
          dispatch(actions.finances.getFinancesUsersCourses(res.data.data.courses));
          dispatch(actions.finances.getFinancesUsersTrialCourses(res.data.data.trial_courses));
        }
      })
      .catch(error => {
        dispatch(actions.finances.getFinancesError(error.response.data.info.message));
      })
      .finally(() => dispatch(actions.finances.stopFetching()));
  };
}

export function getFinancesDocumentsThunk(data) {
  return (dispatch) => {
    dispatch(actions.finances.startFetching());
    getFinancesDocuments(data)
      .then(res => {
        if (res.data.info.status) {
          dispatch(actions.finances.getFinancesUsersDocuments(res.data.data.documents));
          dispatch(actions.finances.getFinancesDocumentsName(res.data.data.nomenclature_name));
        } else {
          dispatch(actions.finances.getFinancesError(res.data.info.message));
        }
      })
      .catch(error => {
        dispatch(actions.finances.getFinancesError(error.response.data.info.message));
      })
      .finally(() => dispatch(actions.finances.stopFetching()));
  };
}

export function getFinancesAccountsThunk(data) {
  return (dispatch) => {
    dispatch(actions.finances.startFetching());
    financesAccounts(data)
      .then(res => {
        if(res.data.info.status) {
          dispatch(actions.finances.getFinancesAccounts(res.data.data.invoices));
          dispatch(actions.finances.getFinancesStudentButton(res.data.data.student_product));
        }
      })
      .catch(error => {
        dispatch(actions.finances.getFinancesError(error.response.data.info.message));
      })
      .finally(() => dispatch(actions.finances.stopFetching()));
  };
}

export function getFinancesLeadAccountsThunk(data) {
  return (dispatch) => {
    dispatch(actions.finances.startFetching());
    financesLeadAccounts(data)
      .then(res => {
        if(res.data.info.status) {
          dispatch(actions.finances.getFinancesAccounts(res.data.data.invoices));
          dispatch(actions.finances.getFinancesStudentButton(res.data.data.student_product));
        }
      })
      .catch(error => {
        dispatch(actions.finances.getFinancesError(error.response.data.info.message));
      })
      .finally(() => dispatch(actions.finances.stopFetching()));
  };
}

export function createCountThunk(data) {
  return (dispatch) => {
    dispatch(actions.finances.startFetching());
    createCount(data)
    .then(res => {
      if(res.data.info.status) {
        if(!res.data.data.message) {
          dispatch(actions.finances.getFinancesAccounts(res.data.data.invoices));
        }
      }
    })
    .catch(error => {
      if(error.response.data.info.message.message) {
        dispatch(actions.finances.getFinancesMessage(error.response.data.info.message.message));
      } else {
        dispatch(actions.finances.getFinancesError(error.response.data.info.message));
      }
    })
    .finally(() => dispatch(actions.finances.stopFetching()));
  };
}

export function getPayActualCountThunk(data) {
  return (dispatch) => {
    dispatch(actions.finances.startFetching());
    payActualCount(data)
    .then(res => {
      if(res.data.info.status) {
        dispatch(actions.finances.getPayActulInvoice(res.data.data?.invoice));
        dispatch(actions.finances.getPayActulTariffs(res.data.data?.tariffs));
        dispatch(actions.finances.getPaymentsMethods(res.data.data?.invoice.payment_methods));
        dispatch(actions.finances.getFinancesMessage(res.data.info.message));
      }
    })
    .catch(error => {
      if(error.response.status === 404) {
        dispatch(actions.finances.getFinancesErrorNotFound(true));
      } else {
        dispatch(actions.finances.getFinancesError(error.response.data.info.message));
      }
    })
    .finally(() => dispatch(actions.finances.stopFetching()));
  };
}

export function getPayActualCountTariffThunk(data) {
  return (dispatch) => {
    payActualTariff(data)
    .then(res => {
      if(res.data.info.status) {
        dispatch(actions.finances.getPayActulInvoice(res.data.data[0]));
      }
    })
    .catch(error => {
      dispatch(actions.finances.getFinancesError(error.response.data.info.message));
    });
  };
}

export function getPayPdfThunk(data) {
  return (dispatch) => {
    dispatch(actions.finances.startFetching());
    getFinancesPdf(data)
    .then(res => {
      if(res.data.info.status) {
        dispatch(actions.finances.getPayPdf(res.data.data.url));
      }
    })
    .catch(error => {
      dispatch(actions.finances.getFinancesError(error.response.data.info.message));
    })
    .finally(() => dispatch(actions.finances.stopFetching()));
  };
}

export function getPayContractThunk(data) {
  return (dispatch) => {
    dispatch(actions.finances.startFetching());
    getFinancesContract(data)
    .then(res => {
      if(res.data.info.status) {
        dispatch(actions.finances.getPayPdf(res.data.data.url));
      }
    })
    .catch(error => {
      dispatch(actions.finances.getFinancesError(error.response.data.info.message));
    })
    .finally(() => dispatch(actions.finances.stopFetching()));
  };
}

export function getPayDetailsThunk(data) {
  return (dispatch) => {
    dispatch(actions.finances.startFetching());
    payDetails(data)
    .then(res => {
      if(res.data.info.status) {
        dispatch(actions.finances.getPayDetails(res.data.data[0]));
      }
    })
    .catch(error => {
      dispatch(actions.finances.getFinancesError(error.response.data.info.message));
    })
    .finally(() => dispatch(actions.finances.stopFetching()));
  };
}

export function getPayThunk(data) {
  return (dispatch) => {
    dispatch(actions.finances.startFetchingBtn());
    getPay(data)
      .then(res => {
        if(res.data.info.status) {
         dispatch(actions.finances.getPaymentLink(res.data.data.url));
        }
      })
      .catch(error => dispatch(actions.finances.getFinancesError(error.response.data.info.message)))
      .finally(() => dispatch(actions.finances.stopFetching()));
  };
}

export function createCopyPaymentThunk(data) {
  return (dispatch) => {
    createCopyPayment(data)
    .then(res => {
        if(res.data.info.status) {
          dispatch(actions.finances.createCopyPayment(res.data.data));
        }
    })
    .catch(error => dispatch(actions.finances.getFinancesError(error.response.data.info.message)));
  };
}