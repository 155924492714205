import types from '../types/index';

const initialState = {
  scheduleType: [],
  error: '',
  day: [],
  load: false,
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case types.schedule.GET_SCHEDULE_TYPE:
      return {
        ...state, scheduleType: payload
      };
    case types.schedule.GET_SCHEDULE_TYPE_ERROR:
      return {
        ...state, error: payload
      };
    case types.schedule.GET_DAY_EVENT:
      return {
        ...state, day: payload
      };
    case types.schedule.GET_DAY_EVENT_ERROR:
      return {
        ...state, error: payload
      };
    case types.schedule.START_FETCHING_SCHEDULE:
      return {
        ...state, load: true
      };
    case types.schedule.STOP_FETCHING_SCHEDULE:
      return {
        ...state, load: false
      };
    default: {
      return state;
    }
  }
};

export default reducer;