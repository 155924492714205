import api from '../index';

export function resetConfirm(data) {
	return api.post('/auth/reset/password/send/phone', data);
}

export function resetConfirmAgain(data) {
	return api.post('/auth/reset/password/send/phone-again', data);
}

export function resetCode(data) {
	return api.post('/auth/reset/password/send/code', data);
}

export function newPassword(data) {
	return api.post('/auth/reset/password/send/password', data);
}