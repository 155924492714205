import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { cleanPhone } from '../../../utils/phoneReplace';

import { profileSelectors } from '../../../store/selectors/profile';

import { getUserInfoThunk } from '../../../store/thunks/profile';
import { authSelectors } from '../../../store/selectors/auth';

import TelegramActivate from './TelegramActivate';

import sprite from '../../../img/sprites.svg';

import '../../Contacts/contacts-card.style.scss';

const TelegramCard = () => {
  const dispatch = useDispatch();

  const [update, setUpdate] = useState(false);

  const profile = useSelector(profileSelectors);
  const auth = useSelector(authSelectors);
  const user = profile.user;
  const token = auth.token;
  const contacts = profile.contacts;

  const handleTelegram = () => {
    if (update && token) {
      dispatch(getUserInfoThunk({ token }));
      setUpdate(false);
    }
  };

  return (
    <div className='contacts-card'>
      <h3 className="contacts-card-title">Телеграм - бот</h3>
      <div className="contacts-card-content">
        {user?.chat_id ? (
          <>
            {contacts.map(item => {
              if (item.key === 'telegram_bot') {
                return (
                  <a
                    href={`${item.value}?start=_${cleanPhone(user?.phone)}`}
                    key={item.key}
                    className="contacts-card-icon"
                    target='_blank'
                    rel="noreferrer noopener">
                    <svg className="icon"><use href={`${sprite}#telegram`}></use></svg>
                  </a>
                );
              }
              return null;
            })}
            <div className='contacts-card-message'>
              <svg className='icon'><use href={`${sprite}#check_double`}></use></svg>
              Активовано
            </div>
          </>
        ) : (
          update ? (
            <button className='contacts-card-btn' onClick={handleTelegram}>Оновити</button>
          ) : (
            <TelegramActivate setUpdate={setUpdate} />
          )
        )}
      </div>
    </div>
  );
};

export default TelegramCard;