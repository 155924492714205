import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import actions from '../../store/actions';

import EventModalCard from './EventModalCard/EventModalCard';
import EventModalTable from './EventModalTable/EventModalTable';
import Head from '../Head/Head';

const EventModal = ({
  clickedDate,
  setModalOpen,
  events,
  toggleClass,
  setEvents,
}) => {
  const dispatch = useDispatch();

  const goBack = () => {
    setEvents([]);
    setModalOpen('');
    dispatch(actions.schedule.getDayEvent([]));
  };
  
  return (
    <div className={`modal ${toggleClass}`}>
      <div className="container">
        <Head textHead={`Події ${new Date(clickedDate).toLocaleDateString()}`} goBack={goBack} />
      </div>

      <div className="modal__content container">
        <div className="modal__cards cards">
          {events.map(event => <EventModalCard key={event.id} card={event} />)}
        </div>
        <div className="modal__table table">
          <div className="modal__table-wrapper">
            <EventModalTable events={events} />
          </div>
        </div>
      </div>
    </div>
  );
};

EventModal.propTypes = {
  toggleClass: PropTypes.string.isRequired,
  clickedDate: PropTypes.string.isRequired,
  events: PropTypes.array.isRequired,
  setModalOpen: PropTypes.func.isRequired,
  setEvents: PropTypes.func.isRequired,
};

export default EventModal;