export default {
    REGISTER_REQUEST: 'REGISTER_REQUEST',
    REGISTER_SUCCESS: 'REGISTER_SUCCESS',
    REGISTER_USER: 'REGISTER_USER',

    START_FETCHING_REGISTER: 'START_FETCHING_REGISTER',
    STOP_FETCHING_REGISTER: 'STOP_FETCHING_REGISTER',

    SET_ERRORS_REGISTER: 'SET_ERRORS_REGISTER',
    SET_ERROR_REGISTER: 'SET_ERROR_REGISTER',

    REGISTER_REQUEST_CONFIRM: 'REGISTER_REQUEST_CONFIRM',

    SET_REGISTERED: 'SET_REGISTERED',

    REGISTER_REQUEST_CODE_SIMILAR: 'REGISTER_REQUEST_CODE_SIMILAR',
    REGISTER_REQUEST_CODE_NOT_SIMILAR: 'REGISTER_REQUEST_CODE_NOT_SIMILAR',
    REGISTER_COURSES: 'REGISTER_COURSES',
    REGISTER_CATEGORIES: 'REGISTER_CATEGORIES',
    REGISTER_LEAD: 'REGISTER_LEAD',

    REGISTER_REQUEST_COURSES: 'REGISTER_REQUEST_COURSES',
    
    REGISTER_REQUEST_COMMUNICATION: 'REGISTER_REQUEST_COMMUNICATION',

    REGISTER_REQUEST_NOT_ME: 'REGISTER_REQUEST_NOT_ME',
    REGISTER_REQUEST_NOT_ME_FEEDBACK: 'REGISTER_REQUEST_NOT_ME_FEEDBACK', 
};