import React from 'react';
import ResetConfirmForm from './ResetConfirmForm';
import TitleAuth from '../../../common/Titles/TitleAuth';
import '../../Login/login.scss';

const ResetConfirm = () => {
  return (
    <div className="auth">
      <TitleAuth titleText="Відновлення паролю" />
      <ResetConfirmForm />
    </div>
  );
};

export default ResetConfirm;