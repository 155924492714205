import React, { useContext, useEffect } from 'react';
import { LayoutContext } from '../../../contexts/layout.context';

import Head from '../../../components/Head/Head';
import PhoneConfirm from '../../../components/Profile/Edit/PhoneForm/PhoneConfirm';

const ConfirmEditPhone = () => {
  const { setLayout } = useContext(LayoutContext);

  useEffect(() => {
    document.title = 'Зміна | NewBrain';
    setLayout({ page: 'Зміна' });
  }, [setLayout]);
  
  return (
    <div className="profile container">
      <Head textHead='Підтвердження номера телефону' goBack={''} />
      
      <div className="profile__content">
        <PhoneConfirm />
      </div>
    </div>
  );
};

export default ConfirmEditPhone;