import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { profileSelectors } from '../../../../store/selectors/profile';

import Field from '../../../../common/Fields/Field';
import LoadingBtn from '../../../LoadingBtn/LoadingBtn';
import ErrorMessage from '../../../../common/Errors/ErrorMessage';
import { editUserInfoThunk } from '../../../../store/thunks/profile';
import { authSelectors } from '../../../../store/selectors/auth';
import actions from '../../../../store/actions';
import { useNavigate } from 'react-router-dom';

const LastNameForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const profile = useSelector(profileSelectors);
  const auth = useSelector(authSelectors);
  const token = auth.token;
  const user = profile.user;
  const errors = profile.errors;
  const load = profile.load;
  const edit = profile.edit;
  const confirmInfo = profile.confirmInfo;

  const [lastName, setLastName] = useState('');
  const [fieldConfirmed, setFieldConfirmed] = useState('');

  useEffect(() => {
    if(edit) {
      navigate('/profile');
      dispatch(actions.profile.editUserInfo(false));
      dispatch(actions.profile.profileError({}));
    }
  }, [edit]);

  useEffect(() => {
    if(Object.keys(user).length) {
      setLastName(user.last_name);
    }
  }, [user]);

  useEffect(() => {
    if(confirmInfo.length > 0) {
      setFieldConfirmed(confirmInfo.find(field => field.field === 'last_name'));
    }
  }, [confirmInfo]);

  useEffect(() => {
    if(fieldConfirmed) {
      setLastName(fieldConfirmed.value_to);
    }
  }, [fieldConfirmed]);

  const handleEdit= (e) => {
    e.preventDefault();
    dispatch(editUserInfoThunk({value: lastName, field: 'last_name', token}));
  };

  return (
    <form className='form profile-form'>
      <Field 
        name="last_name" 
        type="text"
        value={lastName} 
        labelText="Прізвище" 
        onChange={(e) => setLastName(e.target.value.trim())} 
        formClass="form__field required"
        required
      />
      {errors?.value &&  errors.value.map(err => <ErrorMessage key={err} errorText={err} />)}

      <div className="profile__btns">
        <LoadingBtn load={load} btnText='Змінити' onClick={handleEdit} />
      </div>
    </form>
  );
};

export default LastNameForm;